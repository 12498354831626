import { IsSort, Table, TableBody, TableCellHead, TableHead } from '~/ui/Table';
import { useTranslation } from 'react-i18next';
import PersonFood from './PersonFood/PersonFood';

interface FoodPersonItem {
  id: number;
  name: string;
  patronymic: string;
  email: string;
  phone: string;
  position: string;
  active_autosend: boolean;
}

interface Props {
  foodPersons: FoodPersonItem[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  foodMenuId: number;
}

export const PersonsFoodTable = ({
  foodPersons,
  sort,
  setSort,
  foodMenuId,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead sortable={false} field="name">
          {t('employee')}
        </TableCellHead>
        <TableCellHead sortable={false} field="position">
          {t('job_title')}
        </TableCellHead>
        <TableCellHead sortable={false} field="email">
          {t('mail')}
        </TableCellHead>
        <TableCellHead sortable={false} field="phone">
          {t('phone_number')}
        </TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('autosend')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {foodPersons.map(
          ({
            id,
            name,
            patronymic,
            email,
            phone,
            position,
            active_autosend,
          }: FoodPersonItem) => (
            <PersonFood
              id={id}
              name={name}
              patronymic={patronymic}
              email={email}
              phone={phone}
              position={position}
              foodMenuId={foodMenuId}
              isAutosendOn={active_autosend}
            />
          )
        )}
      </TableBody>
    </Table>
  );
};
