import { useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './uploadFile.module.scss';
import UploadIcon from '~/assets/svg/newSvg/actions/upload.svg?react';
import { useTranslation } from 'react-i18next';

interface UploadFileProps {
  onChange: (file: File) => void;
  className?: string;
  selectedFile: File | null;
  removeFile: () => void;
}

const UploadFile = ({
  selectedFile,
  onChange,
  className,
  removeFile,
}: UploadFileProps) => {
  const filePicker = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) return;
    onChange(e.target.files[0]);
  };

  useEffect(() => {
    if (selectedFile) return;

    if (filePicker.current === null) return;
    filePicker.current.value = '';
  }, [selectedFile]);

  return (
    <>
      <div className={styles.upload}>
        <input
          type="file"
          onClick={removeFile}
          onChange={handleChange}
          accept=".csv, .xls, .xlsx"
          className={cn(styles.uploadInput, className)}
          ref={filePicker}
        />
        <div className={styles.uploadBtn}>
          <UploadIcon /> {t('upload')} XLS
        </div>
      </div>
    </>
  );
};

export default UploadFile;
