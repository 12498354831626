import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import NoImageIcon from '~/assets/svg/newSvg/empty-image.svg?react';
import TableStyles from '~/ui/Table/Table.module.scss';
import table from '~/theme/ui/table.module.scss';
import styles from './AssetsTable.module.scss';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';
import { IAsset } from '../../Assets';

interface AssetsTableProps {
  assets: IAsset[];
  isFiltered: boolean;
}

export const AssetsTable = ({ assets, isFiltered }: AssetsTableProps) => {
  const { t } = useTranslation();
  if (!assets.length && isFiltered) {
    return <span>{t('the_search_has_not_given_any_results')}</span>;
  }

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <thead className={TableStyles.thead}>
          <tr>
            <TableCellHead sortable={false} field="image">
              {t('logo')}
            </TableCellHead>
            <TableCellHead sortable={false} field="name">
              {t('name')}
            </TableCellHead>
            <TableCellHead sortable={false} field="description">
              {t('description')}
            </TableCellHead>
            <TableCellHead sortable={false} field="i_n_n">
              {t('tin')}
            </TableCellHead>
            <TableCellHead sortable={false} field="actions">
              {t('action')}
            </TableCellHead>
          </tr>
        </thead>
        <TableBody>
          {assets.map(({ id, name, description, inn, image_path }) => (
            <TableRow key={id}>
              <TableCell className={styles.logoCell}>
                {image_path ? (
                  <img
                    className={styles.logo}
                    src={image_path}
                    alt="Image was not loaded"
                  />
                ) : (
                  <NoImageIcon />
                )}
              </TableCell>
              <TableCell>{name}</TableCell>
              <TableCell className={styles.descriptionCell}>
                <TooltipText text={description} />
              </TableCell>
              <TableCell>{inn}</TableCell>

              <TableCell>
                <div className={table.actions}>
                  <IconButtonLink
                    to={`/assets/${id}/edit`}
                    className={table.button}
                  >
                    <Edit />
                  </IconButtonLink>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AssetsTable;
