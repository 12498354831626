import styles from './DoughnutChart.module.scss';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IOrderStats } from '../OrdersStats/OrdersStats';
import { pluralize } from '~/utils/helpers';
import i18next, { t } from 'i18next';

ChartJS.register(ArcElement, Tooltip);

interface IDoughnutChartProps {
  orderStats: IOrderStats;
}

const pluralizeOrder = (n: number) =>
  pluralize(n, [
    i18next.t('order_1'),
    i18next.t('order_2'),
    i18next.t('order_3'),
  ]);

const DoughnutChart = ({ orderStats }: IDoughnutChartProps) => {
  let data = [
    {
      label: t('status_completed'),
      value: orderStats?.statuses?.success || 0,
      color: '#7367F0',
      cutout: '80%',
    },
    {
      label: t('status_created'),
      value: orderStats?.statuses?.created || 0,
      color: 'rgba(115, 103, 240, 0.4)',
      cutout: '80%',
    },
    {
      label: t('status_closed_2'),
      value: orderStats?.statuses?.canceled || 0,
      color: 'rgba(75, 70, 92, 0.5)',
      cutout: '80%',
    },
  ];

  const options: any = {
    plugins: {
      responsive: true,
    },
    cutout: data.map(item => item.cutout),
  };

  const finalData = {
    labels: data.map(item => item.label),
    datasets: [
      {
        data: data.map(item => Math.round(item.value)),
        backgroundColor: data.map(item => item.color),
        borderColor: data.map(item => item.color),
        borderWidth: 0,
        borderRadius: 18,
        spacing: 5,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };

  return (
    <div className={styles.doughnutChartWrapper}>
      <Doughnut style={{ zIndex: 10 }} data={finalData} options={options} />
      <div className={styles.centerText}>
        <span className={styles.centerTextAmount}>{orderStats.all_count}</span>
        <span className={styles.centerTextDescription}>
          {pluralizeOrder(orderStats.all_count)}
        </span>
      </div>
    </div>
  );
};

export default DoughnutChart;
