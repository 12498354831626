import styles from './DashBoardPageActions.module.scss';
import { Button } from '~/ui';
import CalendarIcon from './images/calendar.svg?react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const END_DATES = {
  START_TODAY: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  START_WEEK: dayjs()
    .subtract(1, 'week')
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
  START_MONTH: dayjs()
    .subtract(1, 'month')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss'),
};

const { RangePicker } = DatePicker;

const DashBoardPageActions = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.dashboardPageActions}>
      <Button
        text={t('today')}
        className={cn(
          styles.dashboardButton,
          startDate === END_DATES.START_TODAY
            ? styles.dashboardButtonActive
            : ''
        )}
        onClick={() => {
          setStartDate(dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
          localStorage.setItem(
            'startDate',
            dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')
          );
          setEndDate(dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
          localStorage.setItem(
            'endDate',
            dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          );
        }}
      />
      <Button
        text={t('week')}
        className={cn(
          styles.dashboardButton,
          startDate === END_DATES.START_WEEK ? styles.dashboardButtonActive : ''
        )}
        onClick={() => {
          setStartDate(
            dayjs()
              .subtract(1, 'week')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          );
          localStorage.setItem(
            'startDate',
            dayjs()
              .subtract(1, 'week')
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          );

          setEndDate(dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
          localStorage.setItem(
            'endDate',
            dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          );
        }}
      />
      <Button
        text={t('month')}
        className={cn(
          styles.dashboardButton,
          startDate === END_DATES.START_MONTH
            ? styles.dashboardButtonActive
            : ''
        )}
        onClick={() => {
          setStartDate(
            dayjs()
              .subtract(1, 'month')
              .startOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          );
          localStorage.setItem(
            'startDate',
            dayjs()
              .subtract(1, 'month')
              .startOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          );
          setEndDate(dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'));
          localStorage.setItem(
            'endDate',
            dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          );
        }}
      />
      <div className={styles.dashboardPageDateWrapper}>
        <span className={styles.dashboardPageDates}>
          {dayjs(startDate).format('DD.MM.YYYY')}-
          {dayjs(endDate).format('DD.MM.YYYY')}
        </span>
        <RangePicker
          className={cn(styles.rangePicker)}
          suffixIcon={<CalendarIcon />}
          onChange={dates => {
            if (dates && dates[0] && dates[1]) {
              setStartDate(
                dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
              );
              localStorage.setItem(
                'startDate',
                dates[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
              );
              setEndDate(dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'));
              localStorage.setItem(
                'endDate',
                dates[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default DashBoardPageActions;
