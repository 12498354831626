import i18next from 'i18next';

export const inputColor = values => {
  let color = '';

  if (values === '1') {
    color = 'red';
  } else if (values === '2') {
    color = 'blue';
  } else if (values === '3') {
    color = 'orange';
  } else if (values === '4') {
    color = 'green';
  }

  return color;
};

export const inputText = values => {
  const t = i18next.t;
  let text = '';

  if (values === '1') {
    text = t('quality_bad');
  } else if (values === '2') {
    text = t('quality_okay');
  } else if (values === '3') {
    text = t('quality_good');
  } else if (values === '4') {
    text = t('quality_best');
  }

  return text;
};
