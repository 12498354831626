import { Field, FieldArray } from 'formik';
import styles from './TextFieldArray.module.scss';
import FieldGroup from '~/ui/FieldGroup';
import BasketIcon from '~/assets/svg/newSvg/basketRaw.svg?react';
import AddIcon from '~/assets/svg/newSvg/addRaw.svg?react';
import { pluralize } from '~/utils/helpers';
import s from './TextFieldArray.module.scss';
import i18next, { t } from 'i18next';

interface TextFieldArrayProps {
  name: string;
  label: string;
  maxCount: number;
  error?: string;
}

const pluralizeVariant = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('variant'),
    i18next.t('variant_2'),
    i18next.t('variant_3'),
  ]);

function TextFieldArray(props: TextFieldArrayProps) {
  const { name, label, maxCount, error } = props;
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        const fields = arrayHelpers.form.values[name] as string[];
        const count = fields?.length;
        return (
          <FieldGroup
            label={label}
            className={s.container}
            description={
              count === maxCount
                ? `${t('cant_add_more_variants')} ${pluralizeVariant(maxCount)}`
                : `${t('can_add_more_variants')} ${pluralizeVariant(
                    maxCount - count
                  )}`
            }
          >
            {fields?.length > 0 && (
              <div className={s.fields__list}>
                {fields?.map((field, index) => (
                  <div key={index} className={s.field}>
                    <Field
                      name={`${name}.${index}`}
                      value={field}
                      className={s.textField}
                      placeholder={t('answer')}
                    />
                    <span className={styles.error}>{error}</span>
                    <button
                      className={s.deleteButton}
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <BasketIcon />
                    </button>
                  </div>
                ))}
              </div>
            )}
            {count < maxCount && (
              <button
                className={s.addButton}
                type="button"
                onClick={() => arrayHelpers.push('')}
              >
                <AddIcon />
                {t('add_variant')}
              </button>
            )}
          </FieldGroup>
        );
      }}
    />
  );
}

export default TextFieldArray;
