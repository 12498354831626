import { useEffect, useState } from 'react';
import { Duration } from 'dayjs/plugin/duration';
import dayjs from 'dayjs';

interface Props {
  expiryTime: string;
  enabled: boolean;
}

export function useTimer({ expiryTime, enabled }: Props) {
  const [duration, setDuration] = useState<Duration | null>(null);
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (duration && duration.asMilliseconds() > 0) {
    days = duration.days();
    hours = duration.hours();
    minutes = duration.minutes();
    seconds = duration.seconds();
  }

  useEffect(() => {
    if (!expiryTime || !enabled) {
      return;
    }
    const interval = setInterval(() => {
      const duration = dayjs.duration(
        dayjs.tz(expiryTime, 'Europe/Moscow').diff(dayjs())
      );

      setDuration(duration);
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTime, enabled]);

  return {
    days: `${String(days).padStart(2, '0')}`,
    hours: `${String(hours).padStart(2, '0')}`,
    minutes: `${String(minutes).padStart(2, '0')}`,
    seconds: `${String(seconds).padStart(2, '0')}`,
    duration,
  };
}
