import { FormikValues } from 'formik';
import BusinessTripHistoryTable from './BusinessTripHistoryTable/BusinessTripHistoryTable';
import { getAccommodations } from '~/utils/api/queries';
import { useEffect, useState } from 'react';
import { AccommodationItem } from '../AccommodationForm';

interface Props {
  formik: FormikValues;
  isEditing: boolean | undefined;
  accommodation: {
    id: number;
    name: string;
    companies_id: number;
    section: string;
  };
  params: {
    sort: string;
    perPage: number;
    field: string;
    query: string;
    page: number;
  };
}

export interface IRoom {
  id: number;
  name: string;
}

export const BusinessTripForm = ({ formik, isEditing, params }: Props) => {
  const [accommodations, setAccommodations] = useState<AccommodationItem[]>([]);

  const loadAccomodations = async () => {
    const { data } = await getAccommodations({
      sort: '',
      perPage: 100,
      field: '',
    });
    setAccommodations(data);
  };

  useEffect(() => {
    loadAccomodations();
  }, []);

  return (
    <BusinessTripHistoryTable
      isEditing={isEditing}
      accommodations={accommodations}
      history={formik.values.business_trips}
      accommodationParams={params}
      formik={formik}
    />
  );
};
