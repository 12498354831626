import { useDebounce } from '@uidotdev/usehooks';
import { useFormik } from 'formik';
import { DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { getAccommodations } from '~/utils/api/queries.ts';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { modules } from './modules.ts';
import { PaginatedResponse } from '~/utils/types/common.ts';
import { AccommodationItem } from '../AccommodationForm/AccommodationForm.tsx';
import { SocialBoard } from './SocialTable/SocialBoard.tsx';
import { Button, InputBar, QuerySelect, Select } from '~/ui';
import styles from './SocialForm.module.scss';
import { useTranslation } from 'react-i18next';

export interface AnnouncementValues {
  module: string;
  accommodation_id: number | null;
  name: string;
  start_date: Dayjs | null;
  end_date: Dayjs | null;
}

interface Props {
  storageKey: string;
}

export const SocialForm = ({ storageKey }: Props): JSX.Element => {
  const debouncedSearchTerm = useDebounce('', 500);
  const { t } = useTranslation();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const { storedInitialValues, create } = useLocalStorage<AnnouncementValues>({
    initialValues: {
      module: '1',
      accommodation_id: null,
      name: '',
      start_date: null,
      end_date: null,
    },
    key: storageKey && `${storageKey}-v1`,
    exclude: [],
  });

  const formik = useFormik<AnnouncementValues>({
    initialValues: storedInitialValues,

    onSubmit: () => {},
  });

  create(formik.values);

  const disabledDate = (current: Dayjs) => {
    if (!'2024-02-14') {
      return current && current < dayjs().startOf('day');
    }
    return current && current < dayjs('2024-02-14').startOf('day');
  };

  return (
    <div className={styles.formWrapper}>
      <form className={styles.form} autoComplete="off" onSubmit={() => {}}>
        <InputBar
          name="name"
          value={formik.values.name}
          disabled={false}
          label={t('name')}
          star={true}
          placeholder={t('name_validate')}
          onChange={formik.handleChange}
          errors={formik.errors.name}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />

        <div className={styles.formWrapperMiddle}>
          <Select
            label={t('anquette_type')}
            value={Number(formik.values.module)}
            onChange={() => {
              formik.setFieldValue('module', t('accommodations_multi'));
            }}
            options={modules}
            star={true}
            size="small"
            search={false}
          />
          <div className={styles.labelWrapper}>
            <span className={styles.labelText}>
              Дата начала опроса
              <span className={styles.labelStar}>*</span>
            </span>
            <Space direction="vertical" className="ant-space">
              <DatePicker
                className="datepicker"
                format="DD.MM.YYYY"
                placeholder={t('date')}
                disabledDate={disabledDate}
                value={
                  formik.values.start_date
                    ? dayjs(formik.values.start_date)
                    : null
                }
                onChange={date => {
                  formik.setFieldValue('start_date', date ? date : null);
                  formik.setFieldValue('end_date', null);
                }}
              />
            </Space>
          </div>
          <div className={styles.labelWrapper}>
            <span className={styles.labelText}>
              {t('survey_date_end')}
              <span className={styles.labelStar}>*</span>
            </span>
            <Space direction="vertical" className="ant-space">
              <DatePicker
                className="datepicker"
                format="DD.MM.YYYY"
                value={
                  formik.values.end_date ? dayjs(formik.values.end_date) : null
                }
                onChange={date => {
                  formik.setFieldValue('end_date', date ? date : null);
                }}
                placeholder={t('date')}
                disabledDate={disabledDate}
              />
            </Space>
          </div>
        </div>
        <QuerySelect
          onChange={value => {
            formik.setFieldValue('accommodation_id', value);
          }}
          value={formik.values.accommodation_id}
          queryFn={({
            pageParam = 1,
            meta,
          }): Promise<PaginatedResponse<AccommodationItem>> =>
            getAccommodations({
              sort: '',
              perPage: 100,
              field: '',
              query: meta?.searchQuery as any,
              page: pageParam,
            })
          }
          queryKey={['accommodations-announcement', params]}
          star={true}
          label={t('accommodation_facilities')}
        />
        <SocialBoard />
        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.formBtn}
          disabled={!((formik.isValid && formik.dirty) || formik.isSubmitting)}
        />
      </form>
    </div>
  );
};
