import { limitString } from '~/utils/limitString';
import { Popover } from 'antd';
import cn from 'classnames';
import styles from './RequestsBoard/RequestsTable/RequestsTable.module.scss';
import '~/assets/css/popoverFeedback.css';
import i18next from 'i18next';

enum TicketStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FINISHED = 'finished',
  EXPIRED = 'expired',
}

function renderDescription(description: string) {
  return (
    <Popover
      content={<div>{description}</div>}
      placement="top"
      trigger="hover"
      className="popover"
    >
      <div className={styles.description}>{limitString(description, 16)}</div>
    </Popover>
  );
}

function renderStatus(status: number) {
  const t = i18next.t;

  return (
    <div>
      {status === 1 ? (
        <span className={cn(styles.status, styles.statusPurple)}>
          {t('status_created')}
        </span>
      ) : null}
      {status === 2 ? (
        <span className={cn(styles.status, styles.statusOrange)}>
          {t('status_in_progress')}
        </span>
      ) : null}
      {status === 3 ? (
        <span className={cn(styles.status, styles.statusGreen)}>
          {t('status_done')}
        </span>
      ) : null}
      {status === 4 ? (
        <span className={cn(styles.status, styles.statusGray)}>
          {t('status_closed')}
        </span>
      ) : null}
      {status === 5 ? (
        <span className={cn(styles.status, styles.statusRed)}>
          {t('status_expired')}
        </span>
      ) : null}
    </div>
  );
}

function renderCriticism(criticism: number | null) {
  const t = i18next.t;
  return (
    <>
      {criticism === 1 ? (
        <span className={cn(styles.criticism, styles.criticismRed)}>
          {t('high')}
        </span>
      ) : null}
      {criticism === 2 ? (
        <span className={cn(styles.criticism, styles.criticismOrange)}>
          {t('medium')}
        </span>
      ) : null}
      {criticism === 3 ? (
        <span className={cn(styles.criticism, styles.criticismGreen)}>
          {t('low')}
        </span>
      ) : null}
    </>
  );
}

function renderCriticismIcon(criticism: number | null) {
  const t = i18next.t;
  return (
    <>
      {criticism === 1 ? (
        <span className={cn(styles.criticismIcon, styles.criticismRed)}>
          {t('high')}
        </span>
      ) : null}
      {criticism === 2 ? (
        <span className={cn(styles.criticismIcon, styles.criticismOrange)}>
          {t('medium')}
        </span>
      ) : null}
      {criticism === 3 ? (
        <span className={cn(styles.criticismIcon, styles.criticismGreen)}>
          {t('low')}
        </span>
      ) : null}
    </>
  );
}

function colorTime(value: number) {
  let color;
  if (value === 3) {
    color = 'green';
  } else if (value === 5) {
    color = 'red';
  } else {
    color = '';
  }
  return color;
}

export {
  renderDescription,
  renderStatus,
  renderCriticism,
  colorTime,
  TicketStatus,
  renderCriticismIcon,
};
