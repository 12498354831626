import { useCallback, useState } from 'react';
import { useInfiniteQuery, useQueries } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { PaginatedResponse } from '~/utils/types/common';
import { Select } from '../Select/Select';
import styles from './QuerySelect.module.scss';

type SelectProps<T> = {
  name?: string;
  queryFn: (props: {
    pageParam?: number;
    meta?: Record<string, unknown>;
  }) => Promise<PaginatedResponse<T>>;
  queryKey: unknown[];
  label?: string;
  star: boolean;
  disabled?: boolean;
  placeholder?: string;
  updateOn?: string | number | null;
  topOption?: {
    full_name?: string;
    name: string;
    id: number;
    section: string | null;
    wares_name?: string;
    code?: string;
  };
  className?: string;
  error?: string;
  getValueText?: (id: number) => string;
  getItems?: (id: number) => Promise<T>;
} & (
  | {
      value: number | null;
      onChange: (selectedValue: number) => void;
      multiple?: false;
    }
  | {
      value: number[] | null;
      onChange: (selectedValue: number[]) => void;
      multiple: true;
    }
);

export const QuerySelect = <
  T extends {
    section?: string | null;
    name: string;
    id: number;
    full_name?: string;
    wares_name?: string;
    code?: string;
  },
>({
  queryFn,
  queryKey,
  label,
  star,
  getItems,
  disabled = false,
  updateOn,
  placeholder,
  topOption,
  error,
  className,
  ...props
}: SelectProps<T>): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const { fetchNextPage, hasNextPage, isFetchingNextPage, data } =
    useInfiniteQuery({
      meta: { searchQuery: debouncedSearchTerm },
      queryKey: [...queryKey, debouncedSearchTerm, updateOn],
      queryFn: queryFn,
      getNextPageParam: lastPage => {
        if (!lastPage) return;

        if (lastPage.current_page === lastPage.last_page) {
          return;
        }

        return lastPage.current_page + 1;
      },
      keepPreviousData: true,
    });

  let selectedValues: number[] = [];

  if (!props.value) {
    selectedValues = [];
  } else if (props.multiple) {
    selectedValues = props.value;
  } else {
    selectedValues = [props.value];
  }

  const results = useQueries({
    queries: selectedValues.map(id => ({
      queryKey: [queryKey, 'item', id],
      ...(getItems && { queryFn: () => getItems(id) }),
    })),
  });

  const initialSelectedItems = results.map(el => el.data);
  const options = topOption
    ? [topOption, ...(data?.pages.flatMap(page => page?.data) ?? [])]
    : data?.pages.flatMap(page => page?.data) ?? [];
  const selectedItems = [...options, ...initialSelectedItems];

  function getValueText(id: number) {
    const item = selectedItems.find(item => item?.id === id);
    if (item?.wares_name) {
      return item.wares_name + '/' + item.code;
    }
    return item ? item.full_name || item.name || item.wares_name : '';
  }

  const onEndReached = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <Select
      options={options}
      searchValue={searchValue}
      onSearchValueChange={setSearchValue}
      onEndReached={onEndReached}
      label={label}
      star={star}
      getValueText={getValueText}
      disabled={disabled}
      placeholder={placeholder}
      className={styles.querySelect}
      style={className}
      selectedStyle={styles.querySelectList}
      error={error}
      {...props}
    />
  );
};
