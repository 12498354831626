import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

import styles from './RequestFilter.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export interface IFiltersProps {
  data: {
    isCategoryEnabled: boolean;
    categories: {
      breakfast: boolean;
      lunch: boolean;
      dinner: boolean;
    };
  };
  onChange: (data: any) => void;
}

const RequestFilter = ({ data, onChange }: IFiltersProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem('requestFilter', JSON.stringify(data));
  }, [data]);

  return (
    <ul className={styles.filters}>
      <li className={styles.filterItem}>
        <label className={styles.filterItemLabel}>
          <CustomCheckbox
            isChecked={data.isCategoryEnabled}
            onChange={() =>
              onChange({
                ...data,
                isCategoryEnabled: !data.isCategoryEnabled,
                categories: {
                  breakfast: !data.isCategoryEnabled,
                  lunch: !data.isCategoryEnabled,
                  dinner: !data.isCategoryEnabled,
                },
              })
            }
          />
          {t('by_category')}
        </label>
        <ul className={styles.criticismList}>
          <li className={styles.filterItem}>
            <label className={styles.filterItemLabel}>
              <CustomCheckbox
                isChecked={data.categories.breakfast}
                onChange={() =>
                  onChange({
                    ...data,
                    isCategoryEnabled:
                      !data.categories.breakfast &&
                      data.categories.lunch &&
                      data.categories.dinner
                        ? true
                        : false,
                    categories: {
                      ...data.categories,
                      breakfast: !data.categories.breakfast,
                    },
                  })
                }
              />
              {t('breakfast')}
            </label>
          </li>
          <li className={styles.filterItem}>
            <label className={styles.filterItemLabel}>
              <CustomCheckbox
                isChecked={data.categories.lunch}
                onChange={() =>
                  onChange({
                    ...data,
                    isCategoryEnabled:
                      data.categories.breakfast &&
                      !data.categories.lunch &&
                      data.categories.dinner
                        ? true
                        : false,
                    categories: {
                      ...data.categories,
                      lunch: !data.categories.lunch,
                    },
                  })
                }
              />
              {t('lunch')}
            </label>
          </li>
          <li className={styles.filterItem}>
            <label className={styles.filterItemLabel}>
              <CustomCheckbox
                isChecked={data.categories.dinner}
                onChange={() =>
                  onChange({
                    ...data,
                    isCategoryEnabled:
                      data.categories.breakfast &&
                      data.categories.lunch &&
                      !data.categories.dinner
                        ? true
                        : false,
                    categories: {
                      ...data.categories,
                      dinner: !data.categories.dinner,
                    },
                  })
                }
              />
              {t('dinner')}
            </label>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default RequestFilter;
