import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthenticationProvider } from './components/Authentication/AuthenticationProvider';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import App from './App';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezonePlugin);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      networkMode: 'always',
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <AuthenticationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <App />
        </AuthenticationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
