import Dashboard from '~/components/Dashboard/Dashboard';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import DashBoardPageActions from './DashBoardPageActions/DashBoardPageActions';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const DashboardPage = () => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setStartDate(
      localStorage.getItem('startDate') ||
        dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')
    );
    setEndDate(
      localStorage.getItem('endDate') ||
        dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
    );
  }, []);

  return (
    <Page
      heading={
        <Heading
          text={t('Dashboard')}
          actions={
            <DashBoardPageActions
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          }
        />
      }
    >
      <Dashboard startDate={startDate} endDate={endDate} />
    </Page>
  );
};

export default DashboardPage;
