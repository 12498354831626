import cn from 'classnames';
import s from './Badge.module.scss';

interface BadgeProps {
  children: React.ReactNode;
  variant: 'success' | 'error';
  className?: string;
}

export const Badge = (props: BadgeProps) => {
  const { children, variant } = props;

  return (
    <div className={cn(s.badge, s[`badge--${variant}`], props.className)}>
      {children}
    </div>
  );
};
