import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import {
  AccommodationForm,
  AccommodationItem,
  AccommodationValues,
} from '~/components/AccommodationForm';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import { Button } from '~/ui';
import * as Tabs from '@radix-ui/react-tabs';
import cn from 'classnames';
import styles from './EditAccommodation.module.scss';
import { useContext, useState } from 'react';
import { AccommodationCalendarBoard } from './AccommodationCalendarBoard/AccommodationCalendarBoard';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useTranslation } from 'react-i18next';

const EditAccommodation = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isHaveEnoughRole = checkIfUserSuperAdmin(user?.data.role_name);
  const { accommodationID } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('info');
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/accommodations/${accommodationID}/${value}`);
  };

  const client = useQueryClient();

  const {
    data: accommodation,
    isLoading,
    isFetching,
  } = useQuery<AccommodationItem>({
    queryFn: async () => {
      const response = await instance.get(
        `admin/accommodations/${accommodationID}`
      );

      return response.data;
    },
    queryKey: ['accommodation', accommodationID],
    onError: error => {
      toastError(error);
    },
  });

  const { mutateAsync: deleteAccommodation } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`admin/accommodations/${id}`);
      return response.data;
    },
    {
      onSuccess: (data: AccommodationItem) => {
        client.setQueryData(
          ['accommodations'],
          (oldAccommodations?: AccommodationItem[]) => {
            return oldAccommodations?.filter(el => el.id !== data.id);
          }
        );
        toastSuccess(t('accommodation_removed'));
        navigate('/accommodations');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: updateAccommodation } = useMutation(
    async ({
      name,
      section,
      description,
      address,
      floors,
      rooms,
      beds,
      uploadPhotosValue,
      assets,
    }: AccommodationValues) => {
      const deletedPhotoIds = accommodation?.photos
        ?.filter(
          item => !uploadPhotosValue.photos.find(photo => photo.id === item.id)
        )
        .map(photo => photo.id);
      const response = await instance.postForm(
        `admin/accommodations/${accommodation?.id}`,
        {
          name,
          section,
          description,
          address,
          floors,
          rooms,
          beds,
          image: uploadPhotosValue.files,
          deletedPhotoIds,
          asset_id: assets.id,
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['accommodation', accommodationID]);
        toastSuccess(t('accommodation_saved'));
        navigate('/accommodations');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={accommodation?.name}
          actions={
            isHaveEnoughRole && (
              <Button
                color="red"
                text={t('delete_accommodation')}
                onClick={() => deleteAccommodation(accommodationID as string)}
                disabled={false}
              />
            )
          }
        />
      }
    >
      <Tabs.Root
        className={styles.tabsRoot}
        value={activeTab}
        onValueChange={handleTabChange}
      >
        <Tabs.List className={styles.tabsList} aria-label="Manage your account">
          <Tabs.Trigger
            value="info"
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]: activeTab === 'info',
            })}
          >
            {t('general_information')}
          </Tabs.Trigger>
          <Tabs.Trigger
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]: activeTab === 'calendar',
            })}
            value="calendar"
          >
            {t('occupancy_calendar')}
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="info"
        >
          <AccommodationForm
            isAssetEditAvailable={isHaveEnoughRole}
            isPicturesDisabled={!isHaveEnoughRole}
            accommodation={accommodation}
            isLoading={isLoading || isFetching}
            onSubmit={values => updateAccommodation(values)}
          />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="calendar"
        >
          <AccommodationCalendarBoard accommodationID={accommodationID} />
        </Tabs.Content>
      </Tabs.Root>
    </Page>
  );
};

export default EditAccommodation;
