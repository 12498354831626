import { useState } from 'react';
import styles from './TransactionsDishesModalContent.module.scss';
import { instance } from '~/utils/api/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { Button, BUTTON_STYLES, Pagination, QuerySelect, Spinner } from '~/ui';
import { TransactionsDishesTable } from './TransactionsDishesTable/TransactionsDishesTable';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { getFoodPoints, getFoodWares } from '~/utils/api/queries';
import { PaginatedResponse } from '~/utils/types/common';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface ITransactionsDishesModalContentProps {
  onCloseClick: () => void;
}

interface SelectedPage {
  selected: number;
}

const TransactionsDishesModalContent = ({
  onCloseClick,
}: ITransactionsDishesModalContentProps) => {
  const [page, setPage] = useState<number>();
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [foodWares, setFoodWares] = useState<number[]>([]);
  const [foodPoints, setFoodPoints] = useState<number[]>([]);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [selectedItems, setSelectedItems] = useState<
    | {
        order_id: number;
        food_ware_ids: number[];
      }[]
    | never[]
  >([]);
  const { t } = useTranslation();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const client = useQueryClient();

  const handleCheck = (id: number) => {
    if (selectedItems.some(item => item.order_id === id)) {
      setSelectedItems(selectedItems.filter(item => item.order_id !== id));
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          order_id: id,
          food_ware_ids: foodWares,
        },
      ]);
    }
  };

  const handleCancelSelectedDishes = async () => {
    try {
      const response = await instance.delete('admin/food-order-wares', {
        data: {
          orders_data: selectedItems,
        },
      });
      toastSuccess(response.data.message);
      client.invalidateQueries(['transactions-dishes', 'transactions']);
    } catch (error) {
      toastError(error);
    }
  };

  const getFoodWareById = async (id: number): Promise<any> => {
    const response = await instance.get(`admin/ifcm-wares-all/${id}`);
    return response.data.data;
  };

  const getFoodPointById = async (id: number): Promise<any> => {
    const response = await instance.get(`food-admin/${id}`);
    return response.data;
  };

  const params = {
    sort: sort.direction,
    perPage: 5,
    field: sort.field,
    ...(date && { date: dayjs(date).format('YYYY-MM-DD') }),
    food_ware_ids: foodWares,
    food_ids: foodPoints,
    for_cancel: 1,
    page,
  };

  const {
    data: transactionsDishes,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/food-orders`, {
        params,
      });
      return response.data;
    },
    queryKey: ['transactions-dishes', params],
    keepPreviousData: false,
  });

  return (
    <div className={styles.transactionsDishesModalContent}>
      <div className={styles.header}>
        <span className={styles.title}>{t('cancel_dishes_title')}</span>
        <Button
          text={t('close')}
          className={styles.closeButton}
          buttonStyle={BUTTON_STYLES.EMPTY}
          onClick={onCloseClick}
        />
      </div>
      <div className={styles.transactionsOptions}>
        <QuerySelect
          onChange={value => setFoodWares(value)}
          value={foodWares}
          queryFn={({ pageParam = 1, meta }): Promise<PaginatedResponse<any>> =>
            getFoodWares({
              sort: '',
              perPage: 15,
              field: '',
              query: meta?.searchQuery as any,
              page: pageParam,
            })
          }
          getItems={getFoodWareById}
          className={styles.foodWaresSelect}
          queryKey={['food-wares', params]}
          star={true}
          label={t('dish_capital')}
          multiple={true}
        />
        <QuerySelect
          onChange={value => {
            setFoodPoints(value);
          }}
          value={foodPoints}
          queryFn={({ pageParam = 1, meta }): Promise<PaginatedResponse<any>> =>
            getFoodPoints({
              sort: '',
              perPage: 100,
              field: '',
              query: meta?.searchQuery as any,
              page: pageParam,
            })
          }
          queryKey={['food', params]}
          getItems={getFoodPointById}
          star={true}
          className={styles.foodWaresSelect}
          label={t('food_object')}
          multiple={true}
        />
        <DatePicker
          name="end_date"
          className="datepicker"
          format="DD.MM.YYYY"
          value={date}
          onChange={date => {
            setDate(date ? dayjs(date) : null);
          }}
          placeholder={t('date')}
          rootClassName={styles.datePicker}
        />
      </div>
      <div className={styles.tableWrapper}>
        {transactionsDishes?.data ? (
          <TransactionsDishesTable
            transactions={transactionsDishes?.data}
            sort={sort}
            setSort={setSort}
            selectedItems={selectedItems}
            handleClickCheckbox={handleCheck}
          />
        ) : isError ? (
          <ErrorMessage error={error} />
        ) : (
          <Spinner />
        )}
      </div>
      <div className={styles.pagination}>
        <TableAmountPage
          firstRow={transactionsDishes?.from}
          lastRow={transactionsDishes?.to}
          total={transactionsDishes?.total}
        />
        <Pagination
          pageCount={transactionsDishes?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
      <div className={styles.controls}>
        <Button
          className={styles.button}
          text={t('cancel_selected_dishes')}
          color="red"
          disabled={foodWares.length === 0 || selectedItems.length === 0}
          onClick={handleCancelSelectedDishes}
        />
      </div>
    </div>
  );
};

export default TransactionsDishesModalContent;
