import { useTranslation } from 'react-i18next';
import QuestionAccordion from '../QuestionAccordion/QuestionAccordion';

import styles from './FaqApp.module.scss';

const FaqApp = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles.faqApp}>
      <QuestionAccordion
        title={t('crash_title')}
        description={t('crash_description')}
        isAddedDescriptionShown
      />
      <QuestionAccordion
        title={t('not_working_title')}
        description={t('not_working_description')}
        isAddedDescriptionShown
      />
    </ul>
  );
};

export default FaqApp;
