import i18next from 'i18next';

function renderPrice(price: string) {
  return <div>{<span>{price} &#8381;</span>}</div>;
}

function renderMeasure(measure: string) {
  const convertedMeasure = measure.replace(/[А-Яа-я]/g, '');
  const t = i18next.t;

  return (
    <span>
      {convertedMeasure} {t('g')}
    </span>
  );
}

const columnsFoodType = [
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Время начала ',
    accessor: 'start_time',
  },
  {
    Header: 'Время конца',
    accessor: 'end_time',
  },
  {
    Header: 'Повтор',
    accessor: 'repeat',
    Cell: ({ value }: any) => (
      <>
        {value === 'Everyday' ? <span>Каждый день </span> : null}
        {value === 'Weekday' ? <span>В будние дни</span> : null}
        {value === 'Weekend' ? <span>В выходные</span> : null}
      </>
    ),
  },
];

export { columnsFoodType, renderPrice, renderMeasure };
