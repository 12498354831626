export interface ModuleItem {
  id: 1;
  name: string;
}

export const modules: ModuleItem[] = [
  { id: 1, name: 'Качество Размещения' },
  { id: 1, name: 'Качество Питания' },
  { id: 1, name: 'Качество Сервиса' },
];
