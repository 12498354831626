import styles from './radioInput.module.scss';

interface RadioInputProps {
  label: string;
  name: string;
  value: string;
  checkValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const RadioInput = ({
  label,
  name,
  value,
  checkValue,
  onChange,
  disabled,
}: RadioInputProps): JSX.Element => {
  return (
    <div className={styles['input-wrapper']}>
      <label className={styles['input-label']}>
        <input
          id={value}
          type="radio"
          name={name}
          value={value}
          checked={checkValue === value}
          onChange={onChange}
          className={styles['input']}
          disabled={disabled}
        />
        <span className={styles['label-text']}>{label}</span>
      </label>
    </div>
  );
};

export default RadioInput;
