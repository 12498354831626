import cn from 'classnames';

import styles from './Option.module.scss';

interface IOptionProps {
  name: string | JSX.Element;
  isSelected: boolean;
  onClick: () => void;
}

const Option = ({ name, isSelected, onClick }: IOptionProps) => {
  return (
    <li
      className={cn(
        styles.option,
        isSelected ? styles.optionSelected : undefined
      )}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={event => {
        event.stopPropagation();
        if (event.key === 'Enter' || event.key === ' ') {
          onClick();
        }
      }}
    >
      {name}
    </li>
  );
};

export default Option;
