import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { IconButton } from '~/ui';
import { ApiError, instance } from '~/utils/api/api';
import { downloadFile } from '~/utils/downloadFile';
import { useState } from 'react';
import { useToastError } from '~/utils/useToastError';
import DownloadIcon from '~/assets/svg/newSvg/actions/download.svg?react';
import styles from './DownloadStatsButton.module.scss';
import { useTranslation } from 'react-i18next';

const DownloadStatsButton = () => {
  const { questionnaireID } = useParams<{ questionnaireID: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const toastError = useToastError();
  const { t } = useTranslation();

  const downloadQuestionnarieStats = useMutation(
    ['downloadQuestionnarieStats'],
    async () => {
      const response = await instance.get(
        `admin/answers-export?questionnaire_id=${questionnaireID}`,
        {
          responseType: 'blob',
        }
      );
      return response.data;
    },
    {
      onSuccess: data => {
        downloadFile(
          data,
          'text/xlsx',
          `questionnarie-${questionnaireID}.xlsx`
        );
      },

      onError: error => {
        if (error instanceof ApiError && error?.code === 404) {
          toastError(t('questionnaire_stats_not_found'));
        } else {
          toastError(error);
        }
        setTimeout(() => setIsLoading(false), 300);
      },
    }
  );

  return (
    <IconButton
      onClick={() => downloadQuestionnarieStats.mutate()}
      isDisabled={isLoading}
      className={styles.downloadButton}
    >
      {t('download_xls')} <DownloadIcon />
    </IconButton>
  );
};

export default DownloadStatsButton;
