import React from 'react';
import styles from './UploadPhoto.module.scss';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface IUploadPhotoProps {
  onChange: (photo: FileList | null) => void;
  error: string;
}

const UploadPhoto: React.FC<IUploadPhotoProps> = ({ onChange, error }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.uploadPhoto, error && styles.uploadPhotoError)}>
      {/* <span className={styles.title}>Перетащите изображение сюда или</span> */}
      <div className={styles.button}>{`${t('choose')} ${t('file')}`}</div>
      <span className={styles.description}>
        {`${t('size')}: 1000*200 px, ${t('max')} 2 ${t('mb')}`}
      </span>
      <input
        className={styles.inputFile}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={event => onChange(event.target.files)}
      />
    </div>
  );
};

export default UploadPhoto;
