import CheckIcon from '~/assets/svg/newSvg/check.svg?react';
import styles from './PasswordValidPopup.module.scss';
import { useTranslation } from 'react-i18next';

export interface PasswordValidPopupProps {
  capsCheck: boolean;
  letterCheck: boolean;
  numberCheck: boolean;
  lengthCheck: boolean;
  russianLettersCheck: boolean;
}

export const PasswordValidPopup = ({
  capsCheck,
  letterCheck,
  numberCheck,
  lengthCheck,
  russianLettersCheck,
}: PasswordValidPopupProps) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.popover__list}>
      <li className={styles.popover__item}>
        <p style={{ color: `${lengthCheck ? '#4CC349' : '#F72D2D'}` }}>
          {lengthCheck ? (
            <>
              <CheckIcon width={20} height={20} /> {t('symbols')}
            </>
          ) : (
            t('user_password_validate_8')
          )}
        </p>
      </li>
      <li className={styles.popover__item}>
        <p style={{ color: `${capsCheck ? '#4CC349' : '#F72D2D'}` }}>
          {capsCheck ? (
            <>
              <CheckIcon width={20} height={20} /> {t('top_symbols')}
            </>
          ) : (
            t('user_password_validate_symbols')
          )}
        </p>
      </li>
      <li className={styles.popover__item}>
        <p style={{ color: `${letterCheck ? '#4CC349' : '#F72D2D'}` }}>
          {letterCheck ? (
            <>
              <CheckIcon width={20} height={20} /> {t('low_symbols')}
            </>
          ) : (
            t('user_password_validate_low_symbols')
          )}
        </p>
      </li>
      <li className={styles.popover__item}>
        <p style={{ color: `${numberCheck ? '#4CC349' : '#F72D2D'}` }}>
          {numberCheck ? (
            <>
              <CheckIcon width={20} height={20} /> {t('digits')}
            </>
          ) : (
            t('user_password_validate_digits')
          )}
        </p>
      </li>
      <li className={styles.popover__item}>
        <p style={{ color: `${russianLettersCheck ? '#4CC349' : '#F72D2D'}` }}>
          {russianLettersCheck ? (
            <>
              <CheckIcon width={20} height={20} /> {t('no_cyrillic')}
            </>
          ) : (
            t('user_password_validate_cyrillic')
          )}
        </p>
      </li>
    </ul>
  );
};
