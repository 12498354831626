import PictureSvg from '~/assets/svg/newSvg/picture.svg?react';
import styles from './ghostPicture.module.scss';

const GhostPicture = (): JSX.Element => {
  return (
    <div className={styles.pictureWrapper}>
      <PictureSvg />
    </div>
  );
};

export default GhostPicture;
