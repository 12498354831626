import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { IBlockCategory } from '../UsersBoard';
import { useTranslation } from 'react-i18next';

import styles from './ChangeUsersStatusModalContent.module.scss';
import { Button, BUTTON_STYLES } from '~/ui';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';

interface IChangeUsersStatusModalContentProps {
  isLoading: { block: boolean; unblock: boolean };
  selectedSections: IBlockCategory[];
  onCancelPress: () => void;
  handleToggleCheckbox: (section: IBlockCategory) => void;
  handleSubmitUnblock: () => void;
  handleSubmit: () => void;
}

const ChangeUsersStatusModalContent = ({
  isLoading,
  selectedSections,
  onCancelPress,
  handleToggleCheckbox,
  handleSubmitUnblock,
  handleSubmit,
}: IChangeUsersStatusModalContentProps) => {
  const { t } = useTranslation();

  const { data: blockCategories } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/block-list-categories');
      return response.data;
    },
    queryKey: ['block-categories'],
    keepPreviousData: true,
  });

  return (
    <div className={styles.changeUsersStatusModalContent}>
      <div className={styles.header}>
        <h4 className={styles.title}>{t('change_status_title')}</h4>
        <Button
          onClick={onCancelPress}
          buttonStyle={BUTTON_STYLES.EMPTY}
          text={t('close')}
          className={styles.closeButton}
        />
      </div>
      <ul className={styles.optionList}>
        {blockCategories?.map((category: IBlockCategory) => (
          <li className={styles.option}>
            <label className={styles.checkboxWrapper}>
              <CustomCheckbox
                isChecked={
                  !!selectedSections.find(
                    (selectedSection: IBlockCategory) =>
                      category.id === selectedSection.id
                  )
                }
                onChange={() => handleToggleCheckbox(category)}
              />{' '}
              {category.display_name}
            </label>
          </li>
        ))}
      </ul>
      <div className={styles.controls}>
        <Button
          onClick={handleSubmitUnblock}
          text={t('unblock')}
          isLoading={isLoading.unblock}
          disabled={isLoading.block || isLoading.unblock}
        />
        <Button
          onClick={handleSubmit}
          color="red"
          className={styles.submitButton}
          text={t('block')}
          isLoading={isLoading.block}
          disabled={isLoading.block || isLoading.unblock}
        />
      </div>
    </div>
  );
};

export default ChangeUsersStatusModalContent;
