import ArrowIcon from './images/arrow.svg?react';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';

import styles from './Question.module.scss';

interface IQuestionProps {
  icon: ReactNode;
  name: string;
  link: string;
}

const Question = ({ icon, name, link }: IQuestionProps) => {
  return (
    <li className={styles.question}>
      <Link to={link} className={styles.questionLink}>
        <div className={styles.questionLeft}>
          {icon}
          <span className={styles.name}>{name}</span>
        </div>
        <ArrowIcon />
      </Link>
    </li>
  );
};

export default Question;
