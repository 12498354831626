import { Board } from '~/components/Shared/Layout/Board/Board';
import AssetsTable from './AssetsTable/AssetsTable';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { ChangeEvent, useState } from 'react';
import styles from './AssetsBoard.module.scss';
import { InputBar, Spinner } from '~/ui';
import { Action } from '~/ui/Actions';
import { useNavigate } from 'react-router-dom';
import { IAsset } from '../Assets';
import { useTranslation } from 'react-i18next';

interface IAssetsBoardProps {
  assets: IAsset[];
  isAssetsLoading: boolean;
}

const AssetsBoard: React.FC<IAssetsBoardProps> = ({
  assets,
  isAssetsLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const filteredAssets = assets?.filter(asset => {
    const searchTermLowered = searchTerm.toLowerCase();
    if (
      asset?.name.toLowerCase().includes(searchTermLowered) ||
      asset?.inn.toLowerCase().includes(searchTermLowered)
    ) {
      return asset;
    }
  });

  return (
    <Board>
      <div className={styles.assetsTableOptions}>
        <InputBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          placeholder={t('search_by_tin_name')}
          classNameInput={styles.assetsTableInput}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.assetsTableInputWrapper}
        />
        <Action
          variant="success"
          onClick={() => {
            navigate('/assets/create');
          }}
        >
          <AddIcon /> {t('create_new_asset')}
        </Action>
      </div>
      {isAssetsLoading ? (
        <Spinner />
      ) : (
        <AssetsTable assets={filteredAssets} isFiltered={!!searchTerm} />
      )}
    </Board>
  );
};

export default AssetsBoard;
