import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { renderStatus } from '../../utils';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, IconButtonLink } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './AnnouncementsTable.module.scss';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useToastSuccess } from '~/utils/useToastSuccess';

export interface Announcement {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  status_id: number;
}

interface UsersTableProps {
  announcements: Announcement[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
}

export const AnnouncementsTable = ({
  announcements,
  sort,
  setSort,
  selectedItems,
  handleToggleAll,
  handleToggle,
}: UsersTableProps) => {
  const client = useQueryClient();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();

  const { mutate: deleteAnnouncement } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`admin/announcement/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        toastSuccess(t('announcement_deleted'));
        client.invalidateQueries(['announcement']);
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === announcements.length &&
                !!announcements.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="name">{t('head')}</TableCellHead>
          <TableCellHead field="start_date">{t('start_date')}</TableCellHead>
          <TableCellHead field="end_date">{t('end_date')}</TableCellHead>
          <TableCellHead field="status_id">{t('status')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {announcements.map(
            ({ id, name, start_date, end_date, status_id }: Announcement) => (
              <TableRow key={id}>
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(id)}
                    onChange={() => handleToggle(id)}
                  />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{dayjs(start_date).format('DD.MM.YYYY')}</TableCell>
                <TableCell>{dayjs(end_date).format('DD.MM.YYYY')}</TableCell>
                <TableCell>{renderStatus(status_id)}</TableCell>
                <TableCell>
                  <div className={styles.announcementsTableActions}>
                    <ModalConfirm
                      title={`${t('announcement_submit_delete')} «${name}»`}
                      onConfirm={() => deleteAnnouncement(id.toString())}
                    >
                      {open => (
                        <IconButton onClick={open}>
                          <Basket />
                        </IconButton>
                      )}
                    </ModalConfirm>
                    <IconButtonLink
                      to={`/announcements/${id}`}
                      className={styles.announcementsTableBtnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
