import ArrowIcon from '~/assets/svg/newSvg/arrow-select.svg?react';
import styles from './CustomSelect.module.scss';
import CloseIcon from '~/assets/svg/newSvg/close-purple.svg?react';
import React, { useState } from 'react';
import cn from 'classnames';
import Option from './Option/Option';
import { SearchInput } from './SearchInput/SearchInput';

export interface IOption {
  id: number | string;
  name: string | JSX.Element;
  display_name?: string;
}

interface IMultiSelectProps {
  placeholder: string;
  value: IOption[];
  disabled: boolean | undefined;
  onDeleteClick: (option: IOption) => void;
}

const MultiSelect = ({
  value,
  placeholder,
  disabled,
  onDeleteClick,
}: IMultiSelectProps) => {
  if (value?.length) {
    return (
      <ul className={styles.multiSelect}>
        {value.map(value => {
          return (
            <li key={value.id} className={styles.selectedOption}>
              {value.name}
              <button
                onClick={
                  disabled
                    ? undefined
                    : event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onDeleteClick(value);
                      }
                }
                type="button"
                className={styles.selectedOptionDelete}
              >
                <CloseIcon />
              </button>
            </li>
          );
        })}
      </ul>
    );
  }
  return <span className={styles.placeholder}>{placeholder}</span>;
};

interface ISelectProps {
  placeholder: string;
  value: IOption | IOption[] | undefined;
}

const SingleSelect = ({ placeholder, value }: ISelectProps) => {
  if (!Array.isArray(value) && value?.name) {
    return (
      <span className={styles.value}>{value?.display_name || value?.name}</span>
    );
  }
  return <span className={styles.placeholder}>{placeholder}</span>;
};

interface ICustomSelectProps {
  label?: string;
  placeholder: string;
  options: IOption[];
  value?: IOption | IOption[] | any;
  disabled?: boolean;
  multiselect?: boolean;
  isSearchEnabled?: boolean;
  required?: boolean;
  error?: string;
  className?: string;
  classNameLabel?: string;
  onChange: (option: IOption | IOption[]) => void;
  onTouch?: () => void;
}

const CustomSelect = ({
  label,
  placeholder = 'Select option',
  options,
  value,
  disabled,
  multiselect,
  isSearchEnabled,
  required,
  error,
  className,
  classNameLabel,
  onTouch,
  onChange,
}: ICustomSelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const handleToggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: IOption) => {
    if (multiselect && Array.isArray(value)) {
      const newValue = value.find((item: IOption) => item.id === option.id)
        ? value.filter((item: IOption) => item.id !== option.id)
        : [...value, option];
      onChange(newValue);
    } else {
      onChange(option);
      setIsOpen(false);
    }
  };

  const handleCloseDropdown = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={cn(
        styles.customSelect,
        multiselect ? styles.customSelectMultiselect : undefined,
        isOpen ? styles.customSelectOpen : undefined,
        disabled ? styles.customSelectDisabled : undefined,
        className
      )}
      onBlur={event => {
        handleCloseDropdown(event);
        if (onTouch) {
          onTouch();
        }
      }}
    >
      {label ? (
        <span className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </span>
      ) : (
        ''
      )}
      <button
        type="button"
        onClick={disabled ? undefined : handleToggleSelect}
        className={cn(
          styles.customSelectLabel,
          isOpen ? styles.customSelectLabelOpen : undefined,
          classNameLabel
        )}
      >
        {multiselect && Array.isArray(value) ? (
          <MultiSelect
            value={value}
            onDeleteClick={handleSelectOption}
            placeholder={placeholder}
            disabled={disabled}
          />
        ) : (
          <SingleSelect placeholder={placeholder} value={value} />
        )}
        <ArrowIcon
          className={cn(
            styles.arrowIcon,
            isOpen ? styles.arrowIconOpen : undefined
          )}
        />
      </button>
      {isOpen ? (
        <ul className={styles.options}>
          {isSearchEnabled && (
            <div className={styles.searchWrapper}>
              <SearchInput
                value={searchValue}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
                onClearPress={() => {
                  setSearchValue('');
                }}
              />
            </div>
          )}

          {options
            .filter(option => {
              if (typeof option.name === 'string' && searchValue) {
                return option.name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
              }
              return option;
            })
            .map(option => (
              <Option
                key={option.id}
                onClick={() => handleSelectOption(option)}
                name={option.display_name || option.name}
                isSelected={
                  Array.isArray(value)
                    ? !!value.find((item: IOption) => item.id === option.id)
                    : value?.id === option.id
                }
              />
            ))}
        </ul>
      ) : (
        ''
      )}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default React.memo(CustomSelect);
