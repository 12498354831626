import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Questionnaire } from '~/pages/Questionnaires/QuestionnairesBoard/QuestionnairesTable/QuestionnairesTable';
import { Button, InputBar, InputMaskBar } from '~/ui';
import { QuestionItem } from '~/components/QuestionForm';
import AnswerEstimate from './Answer/AnswerEstimate/AnswerEstimate';
import AnswerOpen from './Answer/AnswerOpen/AnswerOpen';
import AnswerClose, {
  AnswerCloseMultiple,
} from './Answer/AnswerClose/AnswerClose';

import styles from './CompleteQuestionnariePageForm.module.scss';
import { instance } from '~/utils/api/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { masksphone } from '~/constants/masks';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

const ANSWER_TYPES = {
  CLOSE: 'close',
  OPEN: 'open',
  ESTIMATE: 'estimate',
};

interface ICompleteQuestionnariePageFormProps {
  questionnarie: Questionnaire;
  userId: string | null;
}

interface IAnswer {
  questionnaire_id: number;
  user_id?: number | null;
  question_id: number;
  type: string;
  answer: any;
  comment?: string;
  multi_answer?: boolean;
  required?: boolean;
  variants?: { id: number; question_close_id: number; name: string }[];
}

const CompleteQuestionnariePageForm = ({
  questionnarie,
  userId,
}: ICompleteQuestionnariePageFormProps) => {
  const [name, setName] = useState<string>('');
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isContactMeOn, setIsContactMeOn] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const convertAnswers = () => {
    const answers = questionnarie.questions.map((question: QuestionItem) => {
      return {
        questionnaire_id: questionnarie.id,
        user_id: userId ? Number(userId) : null,
        question_id: question.id,
        type: question.type,
        answer:
          question.type === 'estimate'
            ? 4
            : question.type === ANSWER_TYPES.CLOSE
              ? []
              : '',
        comment: '',
        required: question.question_data.required,
        multi_answer: question.question_data.multi_answer,
      };
    });
    setAnswers(answers);
  };

  const updateAnswers = (
    value: string | number | number[],
    questionId: number
  ) => {
    const updatedAnswers = answers.map(answer => {
      if (answer.question_id === questionId) {
        return {
          ...answer,
          answer: value,
        };
      }
      return answer;
    });
    setAnswers(updatedAnswers);
  };

  const updateClosedAnswer = (value: string, questionId: number) => {
    const updatedAnswers = answers.map(item => {
      if (item.question_id === questionId) {
        if (item.answer.includes(Number(value))) {
          return {
            ...item,
            answer: item.answer.filter(
              (item: number) => Number(item) !== Number(value)
            ),
          };
        }
        return {
          ...item,
          answer: [...item.answer, Number(value)],
        };
      }
      return item;
    });
    setAnswers(updatedAnswers);
  };

  const updateComment = (value: string, questionId: number) => {
    const updatedAnswers = answers.map(answer => {
      if (answer.question_id === questionId) {
        return {
          ...answer,
          comment: value,
        };
      }
      return answer;
    });
    setAnswers(updatedAnswers);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);
    const requiredAnswers = answers.filter(
      answer => answer.required && answer.multi_answer && !answer.answer.length
    );

    if (requiredAnswers.length) {
      setError('Заполните все обязательные поля');
      setIsLoading(false);
      return;
    }

    try {
      await instance.post('answers', {
        answers,
        user_name: name,
        phone: phoneNumber,
        contact_me: isContactMeOn,
      });
      navigate('thank-you', { replace: true });
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!answers.length) {
      convertAnswers();
    }
  }, []);

  return (
    <form
      className={styles.completeQuestionnarieForm}
      onSubmit={event => handleSubmit(event)}
    >
      <div className={styles.offsetContainer}>
        <InputBar
          label={t('name_parental')}
          value={name}
          onChange={event => setName(event.target.value)}
          required
          star
          classNameInput={styles.inputBar}
          placeholder={t('name_parental_placeholder')}
        />
      </div>
      {questionnarie?.questions?.map((question: QuestionItem) => {
        if (question.type === ANSWER_TYPES.ESTIMATE) {
          return (
            <div key={question.id} className={styles.answerContainer}>
              <AnswerEstimate
                required={question.question_data.required}
                value={
                  answers.find(answer => answer.question_id === question.id)
                    ?.answer || ''
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  updateAnswers(event.target.value, question.id)
                }
                onChangeComment={(event: ChangeEvent<HTMLInputElement>) =>
                  updateComment(event.target.value, question.id)
                }
                comment={
                  answers.find(answer => answer.question_id === question.id)
                    ?.comment || ''
                }
                name={question.name}
                questionId={question.id}
              />
            </div>
          );
        }
        if (question.type === ANSWER_TYPES.OPEN) {
          return (
            <div key={question.id} className={styles.answerContainer}>
              <AnswerOpen
                required={question.question_data.required}
                value={
                  answers.find(answer => answer.question_id === question.id)
                    ?.answer || ''
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  updateAnswers(event.target.value, question.id)
                }
                name={question.name}
              />
            </div>
          );
        }
        if (question.type === ANSWER_TYPES.CLOSE) {
          if (question.question_data.multi_answer) {
            return (
              <div key={question.id} className={styles.answerContainer}>
                <AnswerCloseMultiple
                  required={question.question_data.required}
                  value={
                    answers.find(answer => answer.question_id === question.id)
                      ?.answer || ''
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    updateClosedAnswer(event.target.value, question.id);
                  }}
                  name={question.name}
                  questionId={question.id}
                  variants={question.question_data.question_close_variants}
                />
              </div>
            );
          } else {
            return (
              <div key={question.id} className={styles.answerContainer}>
                <AnswerClose
                  required={question.question_data.required}
                  value={
                    answers.find(answer => answer.question_id === question.id)
                      ?.answer || ''
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    updateAnswers([Number(event.target.value)], question.id)
                  }
                  name={question.name}
                  questionId={question.id}
                  variants={question.question_data.question_close_variants}
                />
              </div>
            );
          }
        }
      })}
      <div className={styles.answerContainer}>
        <InputMaskBar
          type="phone"
          placeholder="+7 XXX XXX XX XX"
          value={phoneNumber}
          star={false}
          label={t('phone_number')}
          name="phone"
          mask={masksphone}
          onChange={event => setPhoneNumber(event.target.value)}
          disabled={isLoading}
        />
      </div>
      <label className={styles.checkboxWrapper}>
        <CustomCheckbox
          isChecked={isContactMeOn}
          onChange={() => setIsContactMeOn(!isContactMeOn)}
          color="#ffdd2d"
        />
        <span className={styles.checkboxLabel}>{t('ready_for_call')}</span>
      </label>
      <span className={styles.errorMessage}>{error}</span>
      <div className={styles.submitContainer}>
        <Button
          disabled={isLoading}
          text={t('send')}
          type="submit"
          className={styles.submit}
        />
      </div>
    </form>
  );
};

export default CompleteQuestionnariePageForm;
