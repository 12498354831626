import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { Button } from '~/ui';
import cn from 'classnames';
import { useToastError } from '~/utils/useToastError';
import { useTranslation } from 'react-i18next';

import styles from './XlsUserUploadForm.module.scss';
import UploadFile from '~/components/Shared/UploadFile/UploadFile';

interface XlsUserUploadFormProps {
  close: () => void;
}

interface XlsUserUploadFormValues {
  file: File;
  company_name: 1;
  accommodation_id: 1;
}

const XlsUserUploadForm = ({ close }: XlsUserUploadFormProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const toastError = useToastError();
  const { t } = useTranslation();

  const { mutate: uploadFoods } = useMutation(
    async (params: XlsUserUploadFormValues) => {
      const response = await instance.postForm('admin/upload-users', params);
      return response.data.result;
    },
    {
      onSuccess: () => {},
      onError: e => {
        toastError(e);
      },
    }
  );

  const onClosePopup = () => {
    setSelectedFile(null);
    close();
  };

  const handleProcessClick = () => {
    if (!selectedFile) return;
    uploadFoods({
      file: selectedFile,
      company_name: 1,
      accommodation_id: 1,
    });

    onClosePopup();
  };

  const handleFileChange = (uploadedFile: File) => {
    setSelectedFile(uploadedFile);
  };

  return (
    <>
      <UploadFile
        onChange={handleFileChange}
        className={styles.content__upload}
        selectedFile={selectedFile}
      />

      <div className={styles.contentWrapper}>
        <button
          onClick={handleProcessClick}
          type="submit"
          className={cn(styles.contentBtn, {
            [styles['contentBtn--handle']]: selectedFile,
          })}
        >
          <span>{t('process')}</span>
        </button>

        <Button
          onClick={onClosePopup}
          className={cn(styles.contentBtn)}
          text={t('cancel')}
          disabled={false}
        />
      </div>
    </>
  );
};

export default XlsUserUploadForm;
