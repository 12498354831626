import { Link } from 'react-router-dom';
import { asideBarList, asideBarListSetting } from './AsideBarList';
import { useMutation } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useAuthentication } from '~/components/Authentication/useAuthentication';
import ArrowRight from '~/assets/svg/newSvg/arrow-right.svg?react';
import ExitSvg from '~/assets/svg/newSvg/aside/exit.svg?react';
import { IconButton } from '~/ui';
import AsideList from './AsideList/AsideList';
import styles from './Aside.module.scss';
import { deleteLocalPoints } from '~/utils/deleteLocalPoints';
import { useTranslation } from 'react-i18next';
import ExpandIcon from './images/expand-icon.svg?react';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useContext } from 'react';
import { checkIfFoodAdmin } from '~/utils/getUserRole';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';

const Aside = (): JSX.Element => {
  const authentication = useAuthentication();
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);

  const { mutate: logout } = useMutation(
    async () => {
      const response = await instance.post('logout', {}, {});
      return response.data;
    },
    {
      onSuccess: () => {
        authentication.logout();
      },
    }
  );

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
    localStorage.setItem('isExpanded', JSON.stringify(!isExpanded));
  };

  const handleLogout = () => {
    deleteLocalPoints();
    logout();
  };

  useEffect(() => {
    const isExpanded = localStorage.getItem('isExpanded');
    if (isExpanded) {
      setIsExpanded(JSON.parse(isExpanded));
    }
  }, []);

  return (
    <aside className={styles.aside}>
      <div className={styles.asideContainer}>
        <div className={styles.asideTop}>
          {isExpanded && (
            <div className={styles.logo}>
              <Link to="/accommodations">
                <span>IFCM</span>
              </Link>
            </div>
          )}
          <button onClick={handleToggleExpand} className={styles.expandButton}>
            <ExpandIcon
              className={cn(
                styles.expandIcon,
                isExpanded ? styles.expandIconExpanded : undefined
              )}
            />
          </button>
        </div>
        {isFoodAdminAccess ? (
          ''
        ) : (
          <div className={styles.asideCenter}>
            <AsideList list={asideBarList} isExpanded={isExpanded} />
          </div>
        )}
        <div className={styles.asideBottom}>
          <AsideList isExpanded={isExpanded} list={asideBarListSetting} />
          <IconButton className={styles.exitBtn} onClick={handleLogout}>
            <span className={styles.exitBtnIcon}>
              <ExitSvg className={styles.exitBtnSvg} />
            </span>
            {isExpanded ? (
              <span className={styles.exitBtnText}>
                {t('exit')}
                <ArrowRight className={styles.exitBtnArrow} />
              </span>
            ) : (
              ''
            )}
          </IconButton>
        </div>
      </div>
    </aside>
  );
};

export default Aside;
