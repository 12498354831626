import { ReactNode, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Modal.module.scss';
import CloseIcon from '~/assets/svg/newSvg/close-icon.svg?react';
import CloseCrossIcon from '~/assets/svg/newSvg/cross-close.svg?react';
import { Portal } from '../Portal/Portal';

export const CLOSE_BUTTON_STYLE = {
  SIMPLE: 'simple',
  CIRCLE: 'circle',
};

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: string;
  modalStyles?: string;
  overlayStyle?: string;
  isCloseButtonShown?: boolean;
  closeButtonStyle?: string;
}

export const Modal = ({
  children,
  isOpen,
  onClose,
  size,
  modalStyles,
  overlayStyle,
  isCloseButtonShown,
  closeButtonStyle = CLOSE_BUTTON_STYLE.CIRCLE,
}: Props): JSX.Element => {
  const [isClosing, setIsClosing] = useState(false);

  const closeHandler = useCallback(() => {
    if (onClose) {
      setIsClosing(true);
      onClose();
      setIsClosing(false);
    }
  }, [onClose]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeHandler();
      }
    },
    [closeHandler]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <Portal>
      <div
        className={cn(
          styles.modal,
          overlayStyle,
          { [styles['opened']]: isOpen },
          { [styles['isClosing']]: isClosing }
        )}
      >
        <div className={styles.overlay} onClick={closeHandler}>
          <div
            className={cn(
              modalStyles,
              styles.content,
              size === 'full' && styles.fullSize
            )}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
          >
            {children}
            {isCloseButtonShown && isOpen && (
              <button
                className={cn(
                  styles.closeButton,
                  closeButtonStyle === CLOSE_BUTTON_STYLE.CIRCLE &&
                    styles.closeButtonCircle,
                  closeButtonStyle === CLOSE_BUTTON_STYLE.SIMPLE &&
                    styles.closeButtonSimple
                )}
                onClick={onClose}
              >
                {closeButtonStyle === CLOSE_BUTTON_STYLE.CIRCLE && (
                  <CloseIcon />
                )}
                {closeButtonStyle === CLOSE_BUTTON_STYLE.SIMPLE && (
                  <CloseCrossIcon />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};
