import styles from './StatusBadge.module.scss';
import cn from 'classnames';

interface IStatusBadgeProps {
  name: string;
  type: string;
}

const StatusBadge = ({ name, type }: IStatusBadgeProps) => {
  return (
    <div className={cn(styles.statusBadge, styles[`statusBadge--${type}`])}>
      {name}
    </div>
  );
};

export default StatusBadge;
