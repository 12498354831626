import { useFormik } from 'formik';
import styles from './authform.module.scss';
import { useAuthentication } from '../Authentication/useAuthentication';
import { Button, InputBar, InputPassword } from '~/ui';
import { AxiosError } from 'axios';
import { useToastError } from '~/utils/useToastError';
import { validate } from '~/utils/validateAuth';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';

interface FormikValues {
  email: string;
  password: string;
}

const AuthForm = (): JSX.Element => {
  const { login } = useAuthentication();
  const toastError = useToastError();
  const { t } = useTranslation();
  const analytics = getAnalytics();

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validate,

    onSubmit: async (values: FormikValues) => {
      try {
        await login(values.email, values.password);
        logEvent(analytics, 'login', {
          email: values.email,
          type: 'success',
        });
        formik.resetForm();
      } catch (error) {
        logEvent(analytics, 'login', {
          email: values.email,
          type: 'error',
        });
        if (!(error instanceof AxiosError)) {
          return toastError(error);
        }
        return toastError(error);
      }
    },
  });

  return (
    <div className={styles.form__wrapper}>
      <div className={styles.logo}>
        <span className={styles.logo__text}>OAZIS</span>
      </div>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <InputBar
          name="email"
          value={formik.values.email}
          disabled={false}
          label={t('login')}
          star={false}
          placeholder={t('login_validate')}
          onChange={formik.handleChange}
          error={!!formik.errors.email}
          errors={formik.errors.email}
        />

        <InputPassword
          star={false}
          value={formik.values.password}
          label={t('password')}
          name="password"
          placeholder="***********"
          onChange={formik.handleChange}
          disabled={false}
          popover={false}
          error={!!formik.errors.password}
          errors={formik.errors.password}
        />

        <div className={styles.form__button}>
          <Button
            text={t('sign_in')}
            type="submit"
            disabled={!(formik.isValid && formik.dirty)}
          />
        </div>
      </form>
    </div>
  );
};

export default AuthForm;
