import axios from 'axios';

const BASE_URL = import.meta.env.VITE_BASE_URL;
const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN');
const instance = axios.create({
  baseURL: BASE_URL,
});

instance.defaults.headers.common['authorization'] = `Bearer ${AUTH_TOKEN}`;

export class ApiError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

instance.interceptors.response.use(
  response => {
    return response;
  },

  error => {
    if (!error.response || !error.response.status) return Promise.reject(error);

    if (error.response.status === 401) {
      throw new ApiError('Ошибка: ошибка авторизации', 401);
    } else if (error.response.status === 404) {
      throw new ApiError('Ошибка: некорректный путь запроса', 404);
    } else if (error.response.status === 500) {
      throw new ApiError('Ошибка: нет ответа от сервера', 500);
    } else if (error.response.status === 504) {
      throw new ApiError('Ошибка: нет соединения с интернетом', 504);
    }

    return Promise.reject(error);
  }
);

export { instance, BASE_URL };
