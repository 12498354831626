import ReactPaginate from 'react-paginate';
import styles from './pagination.module.scss';
import { useTranslation } from 'react-i18next';

interface SelectedPage {
  selected: number;
}

interface PaginationProps {
  forcePage?: number;
  pageCount: number;
  onPageChange: (selectedPage: SelectedPage) => void;
}

export const Pagination = ({
  forcePage = 1,
  pageCount,
  onPageChange,
}: PaginationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={styles.pagination}>
      <div></div>
      <ReactPaginate
        forcePage={forcePage - 1}
        previousLabel={t('back')}
        nextLabel={t('forward')}
        pageCount={pageCount}
        onPageChange={onPageChange}
        containerClassName={styles.paginationBtns}
        previousLinkClassName={styles.previousBtn}
        nextLinkClassName={styles.nextBtn}
        disabledClassName={styles.paginationDisabled}
        activeClassName={styles.paginationActive}
      />
    </div>
  );
};
