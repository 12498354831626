import '~/assets/css/popover-xls.css';
import cn from 'classnames';
import styles from './heading.module.scss';
import LoaderText from '../LoaderText/LoaderText';
import Breadcrumbs from '../Breadcrumbs';

interface HeadingProps {
  classNameText?: string;
  text?: string;
  badge?: JSX.Element;
  actions?: React.ReactNode;
  arrowBtn?: React.ReactNode;
  popover?: React.ReactNode;
  classNameHeading?: string;
  classNameContainer?: string;
}

const Heading = ({
  classNameText,
  text,
  badge,
  actions,
  arrowBtn,
  classNameHeading,
  classNameContainer,
}: HeadingProps): JSX.Element => {
  return (
    <div className={cn(styles.container, classNameContainer)}>
      <Breadcrumbs isLoading={text ? false : true} />
      <div className={cn(styles.heading, classNameHeading)}>
        <div className={styles.headingWrapper}>
          {arrowBtn}
          <LoaderText
            className={styles.headingTextWrapper}
            isLoading={text ? false : true}
          >
            <h2 className={cn(styles.headingText, classNameText)}>{text}</h2>
            {badge ? badge : ''}
          </LoaderText>
        </div>
        <div className={styles.headingBtns}>{actions}</div>
      </div>
    </div>
  );
};

export default Heading;
