import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import styles from './ConfirmedTransactionsTable.module.scss';
import { useTranslation } from 'react-i18next';
import { ITransaction } from '../../TransactionsFoodAdminBoard';
import ImageContainer from '~/pages/EditFood/FullMenu/ImageContainer/ImageContainer';
import { Modal } from '~/components/Shared/Modal/Modal';
import { useState } from 'react';

interface Props {
  transactions: ITransaction[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
}

export const ConfirmedTransactionsTable = ({
  transactions,
  sort,
  setSort,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const clickPreviewImage = (image: string) => {
    setIsModalOpen(true);
    setSelectedImage(image);
  };

  return (
    <div className={styles.tableWrapper}>
      <Table className={styles.transactionsTable}>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="order_number" sortable={false}>
            {t('order_number')}
          </TableCellHead>
          <TableCellHead field="id" sortable={false}>
            {t('dish_name')}
          </TableCellHead>
          <TableCellHead field="amount" sortable={false}>
            {t('quantity_full')}
          </TableCellHead>
          <TableCellHead field="image" sortable={false}>
            {t('Photo')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {transactions.map(
            ({ id, food_orders_id, qty, food_wares }: ITransaction) => (
              <TableRow key={id}>
                <TableCell>#{food_orders_id}</TableCell>
                <TableCell>{food_wares[0].wares.wares_name}</TableCell>
                <TableCell>{qty}</TableCell>
                <TableCell>
                  <div className={styles.photoCell}>
                    <ImageContainer
                      image={food_wares[0].wares.photos[0].full_photo_url}
                      onClick={() =>
                        clickPreviewImage(
                          food_wares[0].wares.photos[0].full_photo_url
                        )
                      }
                    />
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
          {transactions.length === 0 && (
            <span className={styles.emptyPlaceholder}>
              {t('confirmed_dishes_empty')}
            </span>
          )}
        </TableBody>
      </Table>

      <Modal
        modalStyles={styles.pictureModal}
        overlayStyle={styles.pictureModalOverlay}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCloseButtonShown
      >
        <img className={styles.foodModalImage} src={selectedImage} />
      </Modal>
    </div>
  );
};
