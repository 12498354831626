import { IVariant } from '~/components/QuestionnaireForm';
import styles from './AnswerClose.module.scss';

interface IAnswerCloseProps {
  questionId: number;
  name: string;
  required: boolean;
  value: number | string | number[];
  onChange: (value: any) => void;
  variants?: IVariant[];
}

const AnswerClose = ({
  variants,
  name,
  required,
  questionId,
  value,
  onChange,
}: IAnswerCloseProps) => {
  return (
    <div className={styles.answerClosedContainer}>
      <span className={styles.answerTitle}>
        {name}
        {required && <span className={styles.requiredStar}>*</span>}
      </span>
      <fieldset id={`closed-${questionId}`} className={styles.answersContainer}>
        {variants?.map(({ id, name }) => {
          return (
            <div className={styles.closedInputContainer} key={id}>
              <input
                className={styles.closedInput}
                name={String(questionId)}
                id={String(id)}
                type="radio"
                required={required}
                value={id}
                checked={Number(value) === id}
                onChange={onChange}
              />
              <label className={styles.answer} htmlFor={String(id)} key={id}>
                {name}
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export const AnswerCloseMultiple = ({
  variants,
  name,
  required,
  questionId,
  value,
  onChange,
}: IAnswerCloseProps) => {
  const valueArray = Array.isArray(value) ? value : [value];

  return (
    <div className={styles.answerClosedMultipleContainer}>
      <span className={styles.answerTitle}>
        {name}
        {required && <span className={styles.requiredStar}>*</span>}
      </span>
      <ul id={`closed-${questionId}`} className={styles.answersContainer}>
        {variants?.map(({ id, name }) => {
          return (
            <li className={styles.closedInputContainer} key={id}>
              <input
                className={styles.closedInput}
                name={String(questionId)}
                id={String(id)}
                type="checkbox"
                value={id}
                checked={valueArray.includes(id)}
                onChange={onChange}
              />
              <label className={styles.answer} htmlFor={String(id)} key={id}>
                {name}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AnswerClose;
