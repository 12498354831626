import Octagon from '~/assets/svg/newSvg/alert-octagon.svg?react';
import styles from './Notice.module.scss';

interface Props {
  text: string;
}

export const Notice = ({ text }: Props): JSX.Element => {
  return (
    <div className={styles.notice}>
      <div className={styles.svgWrapper}>
        <Octagon />
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};
