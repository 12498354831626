import { ChangeEvent } from 'react';
import Search from '~/assets/svg/newSvg/search.svg?react';
import CloseIcon from '~/assets/svg/newSvg/close-cross.svg?react';

import styles from './SearchInput.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClearPress: () => void;
}

export const SearchInput = ({
  value,
  onChange,
  onClearPress,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div onClick={e => e.stopPropagation()} className={styles.inputWrapper}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.input}
        placeholder={t('search')}
      />
      {value ? (
        <CloseIcon className={styles.closeIcon} onClick={onClearPress} />
      ) : (
        <Search className={styles.search} />
      )}
    </div>
  );
};
