import styles from './Table.module.scss';
import TableLoader from './TableLoader';

interface TableBodyProps {
  children: React.ReactNode;
  isLoading?: boolean;
  loadingRows?: number;
}

export const TableBody = ({
  children,
  isLoading,
  loadingRows = 5,
}: TableBodyProps): JSX.Element => {
  return (
    <tbody className={styles.tbody}>
      {isLoading ? <TableLoader loadingRows={loadingRows} /> : children}
    </tbody>
  );
};
