import { useTranslation } from 'react-i18next';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import QuestionnaireForm from '~/components/QuestionnaireForm';

const CreateQuestionnarie = () => {
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('questionnaire_create')} />}>
      <QuestionnaireForm />
    </Page>
  );
};

export default CreateQuestionnarie;
