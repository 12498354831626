import cn from 'classnames';
import styles from './TransactionsBoard/TransactionsTable/TransactionsTable.module.scss';
import i18next from 'i18next';

function renderStatus(status: string) {
  const t = i18next.t;
  return (
    <div>
      {status === 'Готово' ? (
        <span className={cn(styles.status, styles.statusPurple)}>
          {t('done')}
        </span>
      ) : null}

      {status === 'Получено' ? (
        <span className={cn(styles.status, styles.statusGreen)}>
          {t('received')}
        </span>
      ) : null}

      {status === 'Готовится' ? (
        <span className={cn(styles.status, styles.statusPurple)}>
          {t('in_preparation')}
        </span>
      ) : null}

      {status === 'Отменён' ? (
        <span className={cn(styles.status, styles.statusRed)}>
          {t('canceled')}
        </span>
      ) : null}
    </div>
  );
}

export { renderStatus };
