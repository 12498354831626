import cn from 'classnames';
import styles from './ArchiveTable.module.scss';

function renderStatus(status: string) {
  return (
    <div>
      {status === '1' ? (
        <span className={cn(styles.status, styles.statusPurple)}>Создан</span>
      ) : null}

      {status === '2' ? (
        <span className={cn(styles.status, styles.statusOrange)}>
          Отправлен
        </span>
      ) : null}

      {status === '3' ? (
        <span className={cn(styles.status, styles.statusGreen)}>Завершен</span>
      ) : null}
    </div>
  );
}

export { renderStatus };
