import badImage from '~/assets/png/bad_smile.png';
import normalImage from '~/assets/png/normal_smile.png';
import goodImage from '~/assets/png/good_smile.png';
import superImage from '~/assets/png/super_smile.png';
import i18next from 'i18next';

export const getRatings = () => {
  const t = i18next.t;
  return [
    {
      id: 1,
      value: t('quality_bad'),
      image: badImage,
    },
    {
      id: 2,
      value: t('quality_okay'),
      image: normalImage,
    },
    {
      id: 3,
      value: t('quality_good'),
      image: goodImage,
    },
    {
      id: 4,
      value: t('quality_super'),
      image: superImage,
    },
  ];
};
