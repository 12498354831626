import { useState } from 'react';
import styles from './ImageContainer.module.scss';
import cn from 'classnames';

interface IImageContainerProps {
  image: string;
  width?: number;
  height?: number;
  onClick: () => void;
}

const ImageContainer = ({
  image,
  width = 80,
  height = 50,
  onClick,
}: IImageContainerProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <>
      {isLoading && <div style={{ width, height }} className={styles.loader} />}
      <button
        className={cn(
          styles.imagePreviewButton,
          styles.imagePreviewButtonLoading
        )}
        onClick={onClick}
      >
        <img
          style={{ width, height }}
          className={cn(styles.foodImage, isLoading && styles.foodImageLoading)}
          src={image}
          width={width}
          height={height}
          onLoad={() => setIsLoading(false)}
        />
      </button>
    </>
  );
};

export default ImageContainer;
