import { Button } from '~/ui';
import { Modal } from '../Modal/Modal';
import styles from './VideoPlayer.module.scss';
import { useState } from 'react';

interface Props {
  url: string;
  poster: string;
}

export const VideoPlayer = ({ url, poster }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <>
      <div className={styles.videoPlayerWrapper}>
        <video
          poster={poster}
          onClick={open}
          controls
          className={styles.videoPlayer}
        >
          <source src={url} type="video/mp4" />
          Просмотр видео не поддерживается вашим браузером
        </video>
        <button
          type="button"
          onClick={open}
          className={styles.videoPlayerModalButton}
        ></button>
      </div>
      <Modal
        modalStyles={styles.videoModal}
        isOpen={isOpen}
        onClose={close}
        size="full"
      >
        <video
          onClick={open}
          poster={poster}
          controls
          className={styles.videoPlayerBig}
        >
          <source src={url} type="video/mp4" />
          Просмотр видео не поддерживается вашим браузером
        </video>
        <div className={styles.modal__actions}>
          <Button text="Закрыть" color="gray" onClick={close} />
        </div>
      </Modal>
    </>
  );
};
