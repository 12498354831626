import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import {
  AccommodationForm,
  AccommodationValues,
} from '~/components/AccommodationForm';
import Page from '~/components/Shared/Layout/Page/Page';
import styles from './CreateAccommodation.module.scss';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';
import Heading from '~/components/Shared/Heading/Heading';

const CreateAccommodation = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isAssetEditable = checkIfUserSuperAdmin(user?.data.role_name);
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const client = useQueryClient();

  const { mutateAsync: createAccommodation } = useMutation(
    async ({
      name,
      section,
      description,
      address,
      floors,
      rooms,
      beds,
      uploadPhotosValue,
      asset_id,
    }: AccommodationValues) => {
      const response = await instance.postForm('admin/accommodations', {
        name,
        section,
        description,
        address,
        floors,
        rooms,
        beds,
        image: uploadPhotosValue.files,
        asset_id,
      });

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['accommodations']);
        navigate('/accommodations');
        toastSuccess(t('new_accommodation_added'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={t('creating_placement')}
          classNameText={styles.heading}
        />
      }
    >
      <AccommodationForm
        isAssetEditAvailable={isAssetEditable}
        onSubmit={values => createAccommodation(values)}
        storageKey="create-accommodation"
      />
    </Page>
  );
};

export default CreateAccommodation;
