import form from '~/theme/ui/form.module.scss';
import TextIcon from '~/assets/svg/newSvg/options/text.svg?react';
import CheckboxIcon from '~/assets/svg/newSvg/options/checkbox.svg?react';
import StarIcon from '~/assets/svg/newSvg/options/star.svg?react';
import i18next from 'i18next';

export const types = {
  open: 1,
  close: 2,
  estimate: 3,
};

export const getTypeOptions = () => {
  return [
    {
      id: types.open,
      typeName: 'open',
      typeNamePlain: 'Текстовый вопрос',
      name: (
        <span className={form.option}>
          <TextIcon /> {i18next.t('open_question')}
        </span>
      ),
    },
    {
      id: types.close,
      typeName: 'close',
      typeNamePlain: 'Закрытый вопрос',
      name: (
        <span className={form.option}>
          <CheckboxIcon /> {i18next.t('closed_question')}
        </span>
      ),
    },
    {
      id: types.estimate,
      typeName: 'estimate',
      typeNamePlain: 'Выставление оценки',
      name: (
        <span className={form.option}>
          <StarIcon /> {i18next.t('estimate_question')}
        </span>
      ),
    },
  ];
};
