import { ReactNode, useState } from 'react';
import styles from './Tooltip.module.scss';
import cn from 'classnames';

interface ITooltipProps {
  children: ReactNode;
  title: string;
  className?: string;
  tooltipStyle?: string;
  position?: string;
}

const Tooltip = ({
  children,
  title,
  className,
  tooltipStyle,
  position = 'top',
}: ITooltipProps) => {
  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(false);

  const showTip = () => {
    setIsTooltipShown(true);
  };

  const hideTip = () => {
    setIsTooltipShown(false);
  };

  return (
    <div
      className={cn(styles.tooltip, tooltipStyle)}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {isTooltipShown && (
        <div
          className={cn(
            styles.tooltip__content,
            className,
            position === 'right' ? styles.right : styles.top,
            position === 'left' ? styles.left : styles.top
          )}
        >
          {title}
          <div className={styles.tooltipArrow}></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
