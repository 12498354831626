import { useLocalStorage } from '~/utils/useLocalStorage';
import styles from './CreateQrForm.module.scss';
import { useFormik } from 'formik';
import { useToastError } from '~/utils/useToastError';
import { Button } from '~/ui';
import { useTranslation } from 'react-i18next';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';

interface QrModule {
  id: number;
  name: string;
  type: string;
}
export interface CreateQrValues {
  module: QrModule;
  object: {
    id: number;
    name: string;
    type: string;
  };
}

interface Props {
  storageKey: string;
  onSubmit: (values: CreateQrValues) => void;
}

const CreateQrFrom = ({ storageKey, onSubmit }: Props): JSX.Element => {
  const toastError = useToastError();
  const { t } = useTranslation();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: '',
    page: 1,
  };

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<CreateQrValues>({
      initialValues: {
        module: {
          id: 0,
          name: '',
          type: '',
        },
        object: {
          id: 0,
          name: '',
          type: '',
        },
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: [],
    });

  const formik = useFormik<CreateQrValues>({
    initialValues: storedInitialValues,

    onSubmit: async (values: CreateQrValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const getDataForSelectedModule = () => {
    switch (formik.values.module?.type) {
      case 'accommodation': {
        return {
          query: 'admin/accommodations',
          queryKey: ['accommodations', params],
        };
      }
      case 'food': {
        return { query: 'food-admin', queryKey: ['food', params] };
      }
      case 'questionnaire': {
        return {
          query: 'admin/questionnaires',
          queryKey: ['questionnaires', params],
        };
      }
      default: {
        return { query: false };
      }
    }
  };

  return (
    <form
      className={styles.form}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <CustomQuerySelect
        label={t('module')}
        placeholder={t('choose_module')}
        value={formik.values.module}
        query="admin/qr-modules"
        queryKey={['qr-modules', params]}
        params={params}
        onChange={value => {
          formik.setFieldValue('object', { id: 0, name: '', type: '' });
          formik.setFieldValue('module', value);
        }}
      />
      {formik.values.module.type &&
      getDataForSelectedModule().query !== false ? (
        <div className={styles.objectWrapper}>
          <CustomQuerySelect
            label={t('object')}
            placeholder={t('choose_module')}
            value={formik.values.object}
            onChange={value => {
              formik.setFieldValue('object', value);
            }}
            query={getDataForSelectedModule()?.query as string}
            queryKey={getDataForSelectedModule()?.queryKey as [string, object]}
            params={params}
          />
        </div>
      ) : (
        ''
      )}
      <Button
        type="submit"
        text={t('generate_qr')}
        className={styles.formBtn}
        disabled={formik.isSubmitting}
      />
    </form>
  );
};

export default CreateQrFrom;
