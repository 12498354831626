import { useParams } from 'react-router-dom';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { orders } from '~/utils/orders';
import dayjs from 'dayjs';
import { OrderForm } from './OrderForm/OrderForm';

export const Order = (): JSX.Element => {
  const { orderID } = useParams();
  const order = orders.find(el => el.id === Number(orderID));

  return (
    <Page
      heading={
        <Heading
          text={`Заказ ${order?.orderId} от ${dayjs(order?.orderDate).format(
            'DD.MM.YYYY'
          )}`}
        />
      }
    >
      {order && <OrderForm order={order} />}
    </Page>
  );
};
