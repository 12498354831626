import { Board } from '~/components/Shared/Layout/Board/Board';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import { Button, InputBar, Pagination, Spinner } from '~/ui';
import { instance } from '~/utils/api/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { useToastError } from '~/utils/useToastError';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import QuestionnairesTable from './QuestionnairesTable/QuestionnairesTable';

import styles from './QuestionnairesBoard.module.scss';

interface SelectedPage {
  selected: number;
}

const QuestionnairesBoard = () => {
  const { initialValues, storedInitialValues, create } = useLocalStorage<any>({
    initialValues: { page: 15 },
    key: 'questionnaires-points',
    exclude: [],
  });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const client = useQueryClient();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
  };

  const {
    data: questionnaires,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/questionnaires', {
        params,
      });
      return response.data;
    },
    queryKey: ['questionnaires', params],
    keepPreviousData: true,
    onError: error => {
      toastError(error);
    },
  });

  const handleDeleteQuestionnaire = async (id: number) => {
    try {
      await instance.delete(`admin/questionnaires/${id}`);
      client.invalidateQueries(['questionnaires']);
      toastSuccess(t('questionnaire_deleted'));
    } catch (error) {
      toastError(error);
    }
  };

  let content;

  const handleDeleteSelected = async () => {
    try {
      await instance.delete('admin/questionnaires-many', {
        data: { questionnaire_ids: selectedItems },
      });
      setSelectedItems([]);
      client.invalidateQueries(['questionnaires']);
      toastSuccess(t('questionnaires_deleted'));
    } catch (error) {
      toastError(error);
    }
  };

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredUsers = selectedItems.filter(
        selectedId => selectedId !== id
      );
      setSelectedItems(filteredUsers);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedItems.length === questionnaires?.data.length) {
      setSelectedItems([]);
    } else {
      const userIdArray = questionnaires?.data.map((user: any) => user.id);
      setSelectedItems(userIdArray);
    }
  };

  if (questionnaires?.data) {
    content = (
      <QuestionnairesTable
        questionnaires={questionnaires?.data}
        sort={sort}
        setSort={setSort}
        clickConfirmDelete={handleDeleteQuestionnaire}
        selectedItems={selectedItems}
        handleToggle={handleSelect}
        handleToggleAll={handleToggleAll}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.questionnairesTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedItems.length && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteSelected}
              className={styles.deleteButton}
            />
          )}
          <InputBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            value={searchTerm}
            placeholder={t('search_by_name')}
            classNameInput={styles.questionnairesTableInput}
            label={`${t('search')}:`}
            star={false}
            classNameInputWrapper={styles.questionnairesTableInputWrapper}
          />
        </div>
      </div>
      {content}
      <div className={styles.questionnairesTablePagination}>
        <TableAmountPage
          firstRow={questionnaires?.from}
          lastRow={questionnaires?.to}
          total={questionnaires?.total}
        />
        <Pagination
          pageCount={questionnaires?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};

export default QuestionnairesBoard;
