import styles from './EstimateAnswerStats.module.scss';
import StarIcon from '~/assets/svg/newSvg/options/star.svg?react';
import cn from 'classnames';
import { pluralize } from '~/utils/helpers';
import { getEstimateTypes } from './utils';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface IAnswer {
  id: number;
  estimation: number;
  percent: number;
  count: number;
  comment: string;
}

export interface IEstimateAnswer {
  question_id: number;
  question_name: string;
  question_type: string;
  count: number;
  answers: IAnswer[];
  comments: string[];
}

interface IEstimateAnswerStatsProps {
  answer: IEstimateAnswer;
  isMultiStatHidden?: boolean;
}

const pluralizeVariant = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('question'),
    i18next.t('question_2'),
    i18next.t('question_3'),
  ]);

const EstimateAnswerStats = ({
  answer,
  isMultiStatHidden,
}: IEstimateAnswerStatsProps) => {
  const { t } = useTranslation();
  const sortEstimate = (a: IAnswer, b: IAnswer) => {
    if (a.count < b.count) {
      return 1;
    } else {
      return -1;
    }
  };
  const ESTIMATE_TYPES: {
    [key: number]: { name: string; image: string; color: string };
  } = getEstimateTypes();

  return (
    <li className={styles.estimateAnswerStats}>
      <div className={styles.topLine}>
        <StarIcon />
        <span className={styles.typeName}>{t('estimate_question')}</span>
      </div>
      <span className={styles.name}>{answer.question_name}</span>
      {isMultiStatHidden && (
        <span className={styles.amount}>{pluralizeVariant(answer.count)}</span>
      )}
      <ul className={styles.answerList}>
        {answer.answers.sort(sortEstimate).map(item => {
          return (
            <>
              <li key={item.estimation} className={cn(styles.line)}>
                <div
                  style={{
                    width: `${Math.round(item.percent)}%`,
                    backgroundColor: ESTIMATE_TYPES[item.estimation].color,
                  }}
                  className={styles.lineFilled}
                />
                <div className={styles.estimateTypeContainer}>
                  <img
                    className={styles.smileImage}
                    src={ESTIMATE_TYPES[item.estimation].image}
                    alt=""
                  />
                  <span>{ESTIMATE_TYPES[item.estimation].name}</span>
                </div>
                {isMultiStatHidden && (
                  <span className={styles.personAmount}>
                    {item.count} {t('users_amount')}
                  </span>
                )}
              </li>
            </>
          );
        })}
      </ul>
      <ul className={styles.commentList}>
        {answer.comments.map(comment => (
          <li className={styles.comment}>{comment}</li>
        ))}
      </ul>
    </li>
  );
};

export default EstimateAnswerStats;
