import styles from './Table.module.scss';
import cn from 'classnames';

interface TableRowProps {
  children: React.ReactNode;
  style?: string;
  onClick?: () => void;
}

export const TableRow = ({ children, onClick, style }: TableRowProps) => {
  return (
    <tr
      onClick={onClick}
      className={cn(
        styles.tableRow,
        onClick ? styles.tableRowClickable : null,
        style
      )}
    >
      {children}
    </tr>
  );
};
