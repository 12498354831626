import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { DishForm, DishFormValues, DishItem } from '~/components/DishForm';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import styles from './EditDish.module.scss';
import { useToastSuccess } from '~/utils/useToastSuccess';
interface FoodItem {
  food_menu_id?: number;
}

interface FoodMenu {
  data: DishItem;
}

const EditDish = (): JSX.Element => {
  const { dishID } = useParams();
  const { foodID } = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const client = useQueryClient();

  const { data: foodItem } = useQuery<FoodItem>({
    queryFn: async () => {
      const response = await instance.get(`food-admin/${foodID}`);

      return response.data;
    },
    queryKey: ['food-item', foodID],
    onError: error => {
      toastError(error);
    },
  });

  const foodMenuId = foodItem?.food_menu_id;

  const { data } = useQuery<FoodMenu>({
    queryFn: async () => {
      const response = await instance.get(`admin/ifcm-wares-all/${dishID}`);
      return response.data;
    },
    queryKey: ['dish', dishID],
    keepPreviousData: true,
    onError: error => {
      toastError(error);
    },
  });

  const dishItem = data?.data;

  const { mutateAsync: updateDish } = useMutation(
    async (values: DishFormValues) => {
      if (!dishItem) return;

      const deletedPhotoIds =
        dishItem.photos
          ?.filter(
            item =>
              !values.uploadPhotosValue.photos.find(
                photo => photo.id === item.id
              )
          )
          .map(photo => photo.id) || [];
      const response = await instance.postForm(
        `admin/ifcm-wares-all/${dishItem.id}`,
        {
          wares_name: values.wares_name,
          weight: values.weight,
          ingredients: values.ingredients,
          image: values.uploadPhotosValue.files,
          deletedPhotoIds,
        },
        {
          params: {
            _method: 'patch',
          },
        }
      );

      return response.data;
    },
    {
      onSuccess: (data: DishFormValues) => {
        client.setQueryData(
          ['menu-dishes', foodMenuId],
          (oldDishes?: DishFormValues[]) => {
            if (!oldDishes) {
              return [data];
            } else {
              return [...oldDishes, data];
            }
          }
        );
        toastSuccess('Меню отредактировано');
        navigate(`/food/${foodID}`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={dishItem?.wares_name}
          classNameText={styles['heading-text']}
        />
      }
    >
      <DishForm dishItem={dishItem} onSubmit={values => updateDish(values)} />
    </Page>
  );
};

export default EditDish;
