import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import dayjs from 'dayjs';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { Button, InputBar, Pagination, Spinner } from '~/ui';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { Modal } from '~/components/Shared/Modal/Modal';
import { FullMenuBoard } from './FullMenuBoard/FullMenuBoard';
import CurrentMenuTable, { CurrentMenuItem } from './CurrentMenuTable';
import '~/assets/css/select-table.css';
import styles from './CurrentMenuBoard.module.scss';
import CurrentMenuOptions from './CurrentMenuOptions/CurrentMenuOptions';
import { useToastError } from '~/utils/useToastError';
import table from '~/theme/ui/table.module.scss';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';

interface SelectedPage {
  selected: number;
}

interface UploadFileValue {
  files: File[];
  food_id: number;
  date: string;
}

const currentDate = dayjs().format('YYYY-MM-DD');
const nextDay = dayjs().add(1, 'day').format('YYYY-MM-DD');

const dates = [
  { id: 1, name: currentDate },
  { id: 2, name: nextDay },
  { id: 3, name: dayjs().add(2, 'day').format('YYYY-MM-DD') },
  { id: 4, name: dayjs().add(3, 'day').format('YYYY-MM-DD') },
  { id: 5, name: dayjs().add(4, 'day').format('YYYY-MM-DD') },
  { id: 6, name: dayjs().add(5, 'day').format('YYYY-MM-DD') },
  { id: 7, name: dayjs().add(6, 'day').format('YYYY-MM-DD') },
];
interface Props {
  foodId: number;
  foodPointType: number;
}

export const CurrentMenuBoard = ({
  foodId,
  foodPointType,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [selectedFoods, setSelectedFoods] = useState<number[]>([]);
  const [foodWares, setFoodWares] = useState<CurrentMenuItem[]>([]);
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<{
    id: number;
    name: string;
  }>(dates[0]);

  const actualDate = useMemo(() => {
    if (!selectedDate || !dates.length) return '';

    const dateObj = dates.find(date => date.id === selectedDate.id);
    return dateObj?.name || '';
  }, [selectedDate]);

  const params = {
    sort: sort.direction,
    perPage: 5,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    ...(foodPointType !== 2 ? { date: actualDate } : {}),
    food_id: foodId,
  };

  const client = useQueryClient();

  const {
    data: menu,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/food-wares', {
        params,
      });
      setFoodWares(response.data.data);
      return response.data;
    },
    onError: error => {
      toastError(error);
    },

    queryKey: ['food-wares', foodId, params],
    keepPreviousData: true,
  });

  const { mutate: uploadMenu } = useMutation(
    async ({ files, food_id, date }: UploadFileValue) => {
      const response = await instance.postForm('admin/food-wares-upload', {
        files,
        food_id,
        ...(foodPointType !== 2 ? { date } : {}),
      });
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['food-wares', foodId]);
        setSelectedFile(null);
      },
      onError: () => {
        setSelectedFile(null);
      },
    }
  );

  const handleSelectFood = (id: number) => {
    if (selectedFoods.includes(id)) {
      const filteredFoods = selectedFoods.filter(
        selectedFoodId => selectedFoodId !== id
      );
      setSelectedFoods(filteredFoods);
    } else {
      setSelectedFoods([...selectedFoods, id]);
    }
  };

  const handleToggleAllFoods = () => {
    if (selectedFoods.length === menu?.data.length) {
      setSelectedFoods([]);
    } else {
      const foodIdArray = menu?.data.map((food: CurrentMenuItem) => food.id);
      setSelectedFoods(foodIdArray);
    }
  };

  const handleDeleteSelectedFoods = async () => {
    try {
      await instance.delete('admin/food-wares', {
        data: { ware_ids: selectedFoods },
      });
      setSelectedFoods([]);
      client.invalidateQueries(['food-wares']);
      toastSuccess(t('food_wares_deleted'));
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    setSelectedFoods([]);
  }, [page, searchTerm]);

  useEffect(() => {
    if (!selectedFile) return;

    uploadMenu({
      files: selectedFile,
      food_id: foodId,
      date: actualDate,
    });
  }, [selectedFile]);

  return (
    <Board>
      <div className={styles.options}>
        <InputBar
          onChange={e => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={t('search_by_name')}
          classNameInput={styles.input}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.inputWrapper}
        />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedFoods.length && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteSelectedFoods}
              className={styles.deleteButton}
            />
          )}
          {foodPointType !== 2 ? (
            <div className={styles.selectWrapper}>
              {dates.length && (
                <CustomSelect
                  label=""
                  placeholder={t('choose')}
                  options={dates}
                  value={selectedDate}
                  className={styles.dateSelect}
                  classNameLabel={styles.dateSelectLabel}
                  onChange={value => {
                    if (
                      !Array.isArray(value) &&
                      typeof value.name === 'string'
                    ) {
                      setSelectedDate({
                        id: Number(value.id),
                        name: value.name,
                      });
                    }
                  }}
                />
              )}
            </div>
          ) : (
            ''
          )}
          <CurrentMenuOptions
            foodId={foodId}
            selectedDate={selectedDate.id}
            setIsOpen={setIsOpen}
            foodPointType={foodPointType}
          />
        </div>

        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
          <FullMenuBoard
            selectedDate={actualDate}
            foodId={foodId}
            foodPointType={foodPointType}
            onClose={() => setIsOpen(false)}
          />
        </Modal>
      </div>
      {foodWares.length && foodId ? (
        <CurrentMenuTable
          currentMenu={foodWares}
          updateCurrentMenu={setFoodWares}
          sort={sort}
          setSort={setSort}
          foodId={foodId}
          params={params}
          selectedFoods={selectedFoods}
          handleSelect={handleSelectFood}
          handleToggleAll={handleToggleAllFoods}
          t={t}
        />
      ) : !foodWares.length ? (
        <span className={table.noResult}>{t('add_dish_to_menu')}</span>
      ) : isError ? (
        <ErrorMessage error={error} />
      ) : (
        <Spinner />
      )}

      <div className={styles.pagination}>
        <TableAmountPage
          firstRow={menu?.meta?.from}
          lastRow={menu?.meta?.to}
          total={menu?.meta?.total}
        />
        <Pagination
          pageCount={menu?.meta?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
