import { pluralize } from '~/utils/helpers';
import StatBar from '../StatBar/StatBar';
import styles from './FeedbackStats.module.scss';
import i18next from 'i18next';

export interface IFeedbackStat {
  category_id: number;
  category_name: string;
  percent: number;
  estimation_count: number;
  estimation_less_three: number;
}

interface IFeedbackStatsProps {
  feedbacks: IFeedbackStat[];
}

const pluralizeEstimate = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('estimate_1'),
    i18next.t('estimate_2'),
    i18next.t('estimate_3'),
  ]);

const FeedbackStats = ({ feedbacks }: IFeedbackStatsProps) => {
  return (
    <div className={styles.feedbackStats}>
      {feedbacks?.map(feedback => {
        return (
          <div key={feedback.category_id} className={styles.feedbackStatsBar}>
            <StatBar
              label={feedback.category_name}
              percent={feedback.percent}
              explanation={`${pluralizeEstimate(
                feedback.estimation_count
              )} (${pluralizeEstimate(feedback.estimation_less_three)} > 3)`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default FeedbackStats;
