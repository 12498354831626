import { ChangeEventHandler } from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import './textarea.css';
import styles from './textarea.module.scss';

interface TextareaProps {
  label: string;
  placeholder?: string;
  maxLength: number;
  value: string;
  onBlur?: () => void;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  name: string;
  rows: number;
  star: boolean;
  disabled?: boolean;
  error?: boolean;
  errors?: string;
  showCounter?: boolean;
  className?: string;
}

export const Textarea = ({
  label,
  placeholder,
  maxLength,
  value,
  onChange,
  onBlur,
  name,
  rows,
  star,
  disabled,
  error,
  errors,
  showCounter = false,
  className,
}: TextareaProps) => {
  const { TextArea } = Input;

  return (
    <div className={cn(styles.textarea__label, className)}>
      {label && (
        <span className={styles.textarea__text}>
          {label}
          {star ? <span className={styles.textarea__star}>*</span> : null}
        </span>
      )}
      <TextArea
        onBlur={onBlur}
        rows={rows}
        placeholder={placeholder || ''}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
        className={cn(styles.textarea, error && styles.textarea__errors)}
      />
      {showCounter ? (
        <span
          className={cn(
            styles.textarea__counter,
            disabled && styles.textarea__counterDisabled
          )}
        >
          {value.length}/{maxLength}
        </span>
      ) : (
        ''
      )}
      {error ? <div className={styles.errors__text}>{errors}</div> : null}
    </div>
  );
};
