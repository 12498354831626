import { useTranslation } from 'react-i18next';
import styles from './ConfirmDeleteContent.module.scss';
import { Button } from '~/ui';

interface IConfirmDeleteContent {
  clickDelete: () => void;
  clickCancel: () => void;
}

const ConfirmDeleteContent = ({
  clickDelete,
  clickCancel,
}: IConfirmDeleteContent) => {
  const { t } = useTranslation();
  return (
    <>
      <span className={styles.text}>{t('sure_to_delete_all_dish')}</span>
      <div className={styles.buttons}>
        <Button
          className={styles.deleteButton}
          color="red"
          text={t('delete')}
          onClick={clickDelete}
        />
        <Button
          className={styles.cancelButton}
          color="empty"
          text={t('cancel')}
          onClick={clickCancel}
        />
      </div>
    </>
  );
};

export default ConfirmDeleteContent;
