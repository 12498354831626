import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Notifications from './Notifications/Notifications';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';

const NotificationsContainer = () => {
  const toastError = useToastError();

  const { data: notifications, refetch } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/new-ticket');
      return data;
    },
    queryKey: ['notifications-count'],
    onError: error => {
      toastError(error);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 60000);
    () => clearInterval(interval);
  }, []);

  return <Notifications count={notifications?.new_tickets_count} />;
};

export default NotificationsContainer;
