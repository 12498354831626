import { useContext, useState } from 'react';
import { ApiError, instance } from '~/utils/api/api';
import { Button, Spinner } from '~/ui';
import '~/assets/css/select-xls.css';
import cn from 'classnames';
import styles from './XlsUserModalContent.module.scss';
import { useMutation } from '@tanstack/react-query';
import { downloadFile } from '~/utils/downloadFile';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';

interface Props {
  onClose: () => void;
}

export const XlsUserModalContent = ({ onClose }: Props) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthenticationContext);

  const { t } = useTranslation();

  const closePopup = () => {
    setMessage('');
    onClose();
  };

  const downloadUsers = useMutation(
    ['downloadUsers'],
    async () => {
      const response = await instance.get(
        `golang-download-users?user_id=${user?.data.id}`,
        {
          responseType: 'blob',
        }
      );
      return response.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, 'text/xlsx', 'users.xlsx');
        closePopup();
        setLoading(false);
      },

      onError: error => {
        if (error instanceof ApiError && error?.code === 404) {
          setMessage(t('users_uploaded_error'));
        }
        setTimeout(() => setLoading(false), 300);
      },
    }
  );

  const handleDownload = () => {
    setLoading(true);
    downloadUsers.mutate();
  };

  return (
    <>
      <div className={styles.contentWrapper}>
        {!loading && (
          <p className={styles.contentText}>
            <span className={styles.contentSubText}>{t('attention')}!</span>
            {message ? message : t('list_warning')}
          </p>
        )}
        {loading && (
          <p className={styles.contentText}>
            <span className={styles.contentSubText}>{t('list_loading')}</span>
            <p>{t('list_loading_description')}</p>
            <Spinner />
          </p>
        )}
        <div className={styles.btnWrapper}>
          <Button
            onClick={handleDownload}
            className={cn(styles.contentBtn)}
            text={t('download_file')}
            disabled={downloadUsers.isLoading}
          />
          <Button
            onClick={closePopup}
            className={cn(styles.contentBtn)}
            text={t('cancel')}
            disabled={false}
          />
        </div>
      </div>
    </>
  );
};
