import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { RequestsBoard } from './RequestsBoard/RequestsBoard';
import { XlsUploadFilePopover } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import XlsReportRequestsPopoverContent from './XlsReportRequestsPopoverContent/XlsReportRequestsPopoverContent';
import { useTranslation } from 'react-i18next';

const Requests = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('request_list')}
          actions={
            <>
              <XlsUploadFilePopover
                btnText={t('request_analytics')}
                content={<XlsReportRequestsPopoverContent />}
              />
            </>
          }
        />
      }
    >
      <RequestsBoard />
    </Page>
  );
};

export default Requests;
