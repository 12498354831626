import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Chart,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

dayjs.locale('ru', {
  weekStart: 1,
});
dayjs.extend(customParseFormat);

interface CalendarData {
  date: string;
  count: number;
}

interface Props {
  week: number;
  calendarData: CalendarData[];
  total: number;
}

Chart.defaults.font.family = 'Manrope-Regular';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const AccommodationCalendarWeek = ({
  week,
  calendarData,
  total,
}: Props) => {
  const startDate = dayjs().week(week).startOf('week');
  const days: string[] = [];
  const { t } = useTranslation();

  for (let i = 0; i < 7; i++) {
    days.push(startDate.add(i, 'day').format('DD.MM'));
  }

  const daysOfWeek = [
    t('mon'),
    t('tue'),
    t('wed'),
    t('thu'),
    t('fri'),
    t('sat'),
    t('sun'),
  ];
  const labels = days.map((value, index) => `${value} ${daysOfWeek[index]}`);

  let filteredDateArray: CalendarData[] = [];
  for (let i = 0; i < 7; ++i) {
    const day = dayjs(startDate).add(i, 'days').format('YYYY-MM-DD');
    const calendarDataDay = calendarData?.find(item => item.date === day);

    filteredDateArray[i] = calendarDataDay || {
      date: day,
      count: 0,
    };
  }

  const occupiedData = filteredDateArray
    ? filteredDateArray.map((item: CalendarData) => item.count)
    : [];

  const totalArr = [total, total, total, total, total, total, total];

  function subtractArrays(
    totalArr: number[],
    occupiedData: number[]
  ): number[] | null {
    return totalArr.map((value, index) => value - occupiedData[index]);
  }

  const resultArray = subtractArrays(totalArr, occupiedData);

  const data = {
    labels,
    datasets: [
      {
        label: t('busy'),
        data: occupiedData,
        backgroundColor: '#826AF9',
        barPercentage: 0.35,
        borderRadius: 8,
      },
      {
        label: i18next.t('total'),
        data: resultArray,
        backgroundColor: '#F8D3FF',
        barPercentage: 0.35,
        borderRadius: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        font: {
          family: 'Manrope',
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        max: total,
        ticks: {
          stepSize: Math.ceil(total / 5),
          color: '#4B465C80',
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: '#4B465C80',
        },
      },
    },
  };

  return <Bar options={options} data={data} />;
};
