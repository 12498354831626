import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './board.module.scss';

interface BoardProps {
  children: ReactNode;
  classNames?: string;
}

export const Board = ({ children, classNames }: BoardProps): JSX.Element => {
  return <div className={cn(styles.board, classNames)}>{children}</div>;
};
