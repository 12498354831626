import React, { ReactNode, useState } from 'react';
import styles from './PreviewPhone.module.scss';
import StatusIcons from './images/status.svg?react';
import BellIcon from './images/bell-icon.svg?react';
import ProfileIcon from './images/profile-icon.svg?react';
import NotificationIcon from './images/notification-icon.svg?react';
import AccommodationIcon from './images/accommodation-icon.svg?react';
import AddIcon from './images/plus-icon.svg?react';
import CloseIcon from './images/close-icon.svg?react';
import OpenIcon from './images/open-icon.svg?react';
import CompassIcon from './images/compas-icon.svg?react';
import CalendarIcon from './images/сalendar-icon.svg?react';
import ArrowRightIcon from './images/arrow-right-icon.svg?react';
import Place1Image from './images/place1.jpg';
import Place2Image from './images/place2.jpg';
import Place3Image from './images/place3.jpg';
import HomeIcon from './images/home-icon.svg?react';
import OrdersIcon from './images/orders-icon.svg?react';
import FoodsIcon from './images/foods-icon.svg?react';
import FeedbackIcon from './images/feedback-icon.svg?react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IPreviewPhoneProps {
  color: string | undefined;
  textColor: string | undefined;
  children: ReactNode;
  username: string | undefined;
}

const PreviewPhone: React.FC<IPreviewPhoneProps> = ({
  color,
  textColor,
  username,
  children,
}) => {
  const [isAccommodationOpen, setIsAccommodationOpen] = useState<boolean>(true);

  const { t } = useTranslation();

  return (
    <div className={styles.previewPhone}>
      <div className={styles.previewPhoneHead}>
        <span className={styles.previewPhoneHeadTime}>
          {dayjs(new Date()).format('HH:mm')}
        </span>
        <div className={styles.previewPhoneHeadBlock} />
        <StatusIcons className={styles.previewPhoneHeadIcons} />
      </div>
      <div className={styles.previewScrollable}>
        <div className={styles.appTopLine}>
          <div className={styles.appTopLineLeft}>
            <span className={styles.appTopLineLeftName}>
              {`${username}, ${t('good_morning')}!`}
            </span>
            <span className={styles.appTopLineLeftSubtitle}>
              {t('good_day')}!
            </span>
          </div>
          <div className={styles.appTopLineIcons}>
            <ProfileIcon />
            <BellIcon />
          </div>
        </div>
        <div className={styles.tabs}>
          <div className={styles.tab}>
            <span className={styles.tabTitle}>{t('announcements')}</span>
            <button className={styles.tabButton}>
              <NotificationIcon className={styles.tabIcon} />
            </button>
          </div>
          <div className={styles.tab}>
            <span className={styles.tabTitle}>{t('create_a_request')}</span>
            <button className={styles.tabButton}>
              <AddIcon className={styles.tabIcon} />
            </button>
          </div>
        </div>
        <div className={styles.accommodationNavigation}>
          <span className={styles.accommodationNavigationTitle}>
            {t('your_placement')}
          </span>
          <button
            style={{ backgroundColor: color, color: textColor }}
            className={styles.accommodationNavigationButton}
          >
            {t('change')}
          </button>
        </div>
        <div className={styles.accommodation}>
          <div className={styles.accommodationTitleContainer}>
            <div className={styles.accommodationTitleContainerLeft}>
              <AccommodationIcon />
              <div className={styles.accommodationTitleContainerInfo}>
                <span className={styles.accommodationTitleDescription}>
                  {t('accommodation_facility')}
                </span>
                <span className={styles.accommodationTitleName}>
                  {`${t('dormitory')} #55`}
                </span>
              </div>
            </div>
            {isAccommodationOpen ? (
              <button
                className={styles.accommodationOpenButton}
                onClick={() => setIsAccommodationOpen(false)}
              >
                <CloseIcon />
              </button>
            ) : (
              <button
                className={styles.accommodationOpenButton}
                onClick={() => setIsAccommodationOpen(true)}
              >
                <OpenIcon />
              </button>
            )}
          </div>

          {isAccommodationOpen && (
            <>
              <div className={styles.tags}>
                <div className={cn(styles.tag, styles.tagYellow)}>
                  Экономкласс
                </div>
                <div className={cn(styles.tag, styles.tagGreen)}>Проживает</div>
              </div>

              <ul className={styles.placeInfo}>
                <li className={styles.place}>
                  <CalendarIcon />
                  <span className={styles.text}>13.03.23 - 13.06.23</span>
                </li>
                <li className={styles.place}>
                  <CompassIcon />
                  <span className={styles.text}>
                    г. Норильск, ул. Магистральная 130 / 3
                  </span>
                </li>
              </ul>

              <span className={styles.descriptionTitle}>Описание</span>
              <p className={styles.description}>
                Наше общежитие - уютное место для проживания. Тут ты найдешь все
                необходимое для комфортного проживания, включая мебель, удобные
                кровати и оснащенную кухню.
              </p>

              <button className={styles.foodPlacesLink}>
                <span
                  className={styles.foodPlacesText}
                  style={{ color: color }}
                >
                  Столовые
                </span>
                <ArrowRightIcon stroke={color} />
              </button>

              <ul className={styles.placeImages}>
                <li className={styles.placeImage}>
                  <img src={Place1Image} />
                </li>
                <li className={styles.placeImage}>
                  <img src={Place2Image} />
                </li>
                <li className={styles.placeImage}>
                  <img src={Place3Image} />
                </li>
              </ul>
            </>
          )}
        </div>
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.navigation}>
          <button
            style={{ backgroundColor: color }}
            className={cn(styles.tab, styles.tabActive)}
          >
            <HomeIcon stroke={textColor} />
            <span style={{ color: textColor }} className={styles.tabName}>
              {t('MainTitle')}
            </span>
          </button>
          <button className={styles.tab}>
            <OrdersIcon />
            <span className={styles.tabName}>{t('applications')}</span>
          </button>
          <button className={styles.tab}>
            <FoodsIcon />
            <span className={styles.tabName}>{t('nutrition')}</span>
          </button>
          <button className={styles.tab}>
            <FeedbackIcon />
            <span className={styles.tabName}>{t('feedback_shortened')}</span>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PreviewPhone;
