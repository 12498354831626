import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useFormik } from 'formik';
import { useToastError } from '~/utils/useToastError';
import { Button, Textarea } from '~/ui';
import styles from './FeedbackCommentForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface FeedbackCommentValues {
  description: string;
  feedback_id: string;
}

export const FeedbackCommentForm = (): JSX.Element => {
  const { feedbackID } = useParams();
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const { mutate: createComment } = useMutation(
    async ({ description, feedback_id }: FeedbackCommentValues) => {
      const response = await instance.post('feedback-comment', {
        description,
        feedback_id: Number(feedback_id),
      });
      return response.data;
    },
    {
      onSuccess: (data: FeedbackCommentValues) => {
        client.setQueryData(
          ['feedback-comments'],
          (oldComments?: FeedbackCommentValues[]) => {
            if (!oldComments) {
              return [data];
            }
            return [...oldComments, data];
          }
        );

        navigate(`/feedback/${feedbackID}`);
        toastSuccess(t('comment_sent'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const formik = useFormik<FeedbackCommentValues>({
    initialValues: {
      feedback_id: '',
      description: '',
    },

    onSubmit: (values: FeedbackCommentValues) => {
      createComment({
        description: values.description,
        feedback_id: values.feedback_id,
      });
      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.setFieldValue('feedback_id', feedbackID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackID]);

  return (
    <div className={styles.formWrapper}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <Textarea
          rows={4}
          label={t('comment')}
          placeholder={t('comment_text')}
          maxLength={600}
          value={formik.values.description}
          onChange={formik.handleChange}
          name="description"
          star={false}
          disabled={false}
        />

        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.formBtn}
          disabled={!(formik.isValid && formik.dirty)}
        />
      </form>
    </div>
  );
};
