import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import styles from './TransactionsFoodAdminTable.module.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import InfoIcon from '~/assets/svg/newSvg/info-icon.svg?react';
import { renderStatus } from '~/pages/Transactions/utils';
import Timer from './Timer/Timer';
import { ITransaction } from '../TransactionsFoodAdminBoard';
import dayjs from 'dayjs';

interface Props {
  transactions: ITransaction[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  onRowClick: (id: number) => void;
}

export const TransactionsFoodAdminTable = ({
  transactions,
  sort,
  setSort,
  onRowClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.tableWrapper}>
      <Table className={styles.transactionsTable}>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="user_name">{t('employee')}</TableCellHead>
          <TableCellHead field="uniqueId">{t('employee_id')}</TableCellHead>
          <TableCellHead field="id">{t('order_number')}</TableCellHead>
          <TableCellHead field="date">{t('order_date')}</TableCellHead>
          <TableCellHead field="delivery_date">
            {t('delivery_date')}
          </TableCellHead>
          <TableCellHead field="status">{t('status')}</TableCellHead>
          <TableCellHead field="total_sum">{t('order_price')}</TableCellHead>
          <TableCellHead field="time">{t('time_left')}</TableCellHead>
        </TableHead>
        <TableBody>
          {transactions.map(
            ({
              id,
              user,
              created_at,
              user_name,
              status_title,
              total_sum,
              delivery_date,
              has_cancel_wares,
              date_time,
            }: ITransaction) => (
              <TableRow onClick={() => onRowClick(id)} key={id}>
                <TableCell>{user_name}</TableCell>
                <TableCell>{user.uniqueId}</TableCell>
                <TableCell>#{id}</TableCell>
                <TableCell>{dayjs(created_at).format('DD.MM.YYYY')}</TableCell>
                <TableCell>{delivery_date}</TableCell>
                <TableCell>
                  <div className={styles.statusCell}>
                    {renderStatus(status_title)}

                    {has_cancel_wares ? (
                      <Tooltip
                        tooltipStyle={styles.tooltipStyle}
                        className={styles.transactionsTooltip}
                        title={t('request_closed_tooltip')}
                      >
                        <InfoIcon className={styles.tooltipIcon} />
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                </TableCell>
                <TableCell>{total_sum} &#8381;</TableCell>
                <TableCell>
                  <Timer expiryTime={date_time} />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
