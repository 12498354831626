import i18next from 'i18next';
interface ModuleItem {
  id: string;
  name: string;
  [key: string]: string;
}

export const getTimezonesList = (timezones: string[]): ModuleItem[] => {
  const t = i18next.t;

  const timezoneOffset: Record<string, string> = {
    'Asia/Irkutsk': `+8 GMT (${t('Irkutsk')})`,
    'Asia/Kamchatka': `+12 GMT (${t('Kamchatka')})`,
    'Asia/Krasnoyarsk': `+7 GMT (${t('Krasnoyarsk')})`,
    'Asia/Magadan': `+12 GMT (${t('Magadan')})`,
    'Asia/Novosibirsk': `+7 GMT (${t('Novosibirsk')})`,
    'Asia/Omsk': `+6 GMT (${t('Omsk')})`,
    'Asia/Sakhalin': `+11 GMT (${t('Sakhalin')})`,
    'Asia/Vladivostok': `+10 GMT (${t('Vladivostok')})`,
    'Asia/Yakutsk': `+9 GMT (${t('Yakutsk')})`,
    'Asia/Yekaterinburg': `+5 GMT (${t('Yekaterinburg')})`,
    'Europe/Moscow': `+3 GMT (${t('Moscow')})`,
  };

  const timezonesList: ModuleItem[] = timezones?.map((str: string) => ({
    id: str,
    name: timezoneOffset[str],
  }));

  return timezonesList;
};

export const getTimezonesUtcList = (timezones: string[]): ModuleItem[] => {
  const t = i18next.t;

  const timezoneUtcOffset: Record<string, string> = {
    'Asia/Irkutsk': `+8 UTC (${t('Irkutsk')})`,
    'Asia/Kamchatka': `+12 UTC (${t('Kamchatka')})`,
    'Asia/Krasnoyarsk': `+7 UTC (${t('Krasnoyarsk')})`,
    'Asia/Magadan': `+11 UTC (${t('Magadan')})`,
    'Asia/Novosibirsk': `+7 UTC (${t('Novosibirsk')})`,
    'Asia/Omsk': `+6 UTC (${t('Omsk')})`,
    'Asia/Sakhalin': `+11 UTC (${t('Sakhalin')})`,
    'Asia/Vladivostok': `+10 UTC (${t('Vladivostok')})`,
    'Asia/Yakutsk': `+9 UTC (${t('Yakutsk')})`,
    'Asia/Yekaterinburg': `+5 UTC (${t('Yekaterinburg')})`,
    'Europe/Moscow': `+3 UTC (${t('Moscow')})`,
  };

  const timezonesUtcList: ModuleItem[] = timezones?.map((str: string) => ({
    id: str,
    name: timezoneUtcOffset[str],
  }));

  return timezonesUtcList;
};
