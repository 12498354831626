import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import { ButtonLink } from '~/ui';
import { AnnouncementsBoard } from './AnnouncementsBoard/AnnouncementsBoard';
import { useTranslation } from 'react-i18next';

const Announcements = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('announcements')}
          actions={
            <ButtonLink
              text={t('create_new_announcement')}
              to="/announcements/create"
            />
          }
        />
      }
    >
      <AnnouncementsBoard />
    </Page>
  );
};

export default Announcements;
