import { useTranslation } from 'react-i18next';
import { InputBar } from '~/ui';
import { useTimer } from '~/utils/useTimer';

import styles from './Timer.module.scss';

interface TimerProps {
  expiryTime: string;
  enabled: boolean;
}

const Timer = ({ expiryTime, enabled }: TimerProps) => {
  const { days, hours, minutes, seconds } = useTimer({
    expiryTime: expiryTime,
    enabled: enabled,
  });
  const { t } = useTranslation();

  return (
    <InputBar
      value={`${days}:${hours}:${minutes}:${seconds}`}
      label={t('countdown')}
      classNameInput={styles.countdown}
    />
  );
};

export default Timer;
