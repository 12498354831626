import badImage from '~/assets/png/bad_smile.png';
import normalImage from '~/assets/png/normal_smile.png';
import goodImage from '~/assets/png/good_smile.png';
import superImage from '~/assets/png/super_smile.png';
import i18next from 'i18next';

export const getGrades = () => {
  const t = i18next.t;
  return {
    bad: {
      icon: badImage,
      title: t('quality_bad'),
    },
    normal: {
      icon: normalImage,
      title: t('quality_okay'),
    },
    good: {
      icon: goodImage,
      title: t('quality_good'),
    },
    super: {
      icon: superImage,
      title: t('quality_super'),
    },
  };
};

export const gradesList = Object.entries(getGrades()).map(([id, value]) => ({
  ...value,
  id,
}));
