import Download from '~/assets/svg/newSvg/download.svg?react';
import cn from 'classnames';
import styles from './xlsBtn.module.scss';

interface XlsBtnProps {
  onClick: () => void;
  xlsBtnText: string;
  classNames?: string;
  disabled?: boolean;
}

export const XlsBtn = ({
  onClick,
  xlsBtnText,
  classNames,
  disabled = false,
}: XlsBtnProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.xlsBtn, classNames)}
      disabled={disabled}
    >
      <span className={styles.xlsBtnText}>{xlsBtnText}</span>

      <span className={cn(styles.xlsBtnIcon, styles.xlsBtnIconBack)}>
        <Download />
      </span>
    </button>
  );
};
