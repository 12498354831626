// import books from '../assets/svg/header/books.svg';
import i18next from 'i18next';
import dashboard from '../assets/svg/header/dashboard.svg?react';
import transactions from '../assets/svg/header/transactions.svg?react';
// import faq from '../assets/svg/header/faq.svg';
// import phone from '../assets/svg/header/phone.svg';
// import users from '../assets/svg/header/users.svg';

export const headerBarList = () => {
  const t = i18next.t;
  return [
    { id: 0, Icon: dashboard, path: '/dashboard', text: t('Dashboard') },
    { id: 1, Icon: transactions, path: '/transactions', text: t('orders') },
    // { id: 1, src: users, path: '/user', text: 'Сотрудники' },
    // { id: 2, src: books, path: '#', text: 'Справочники' },
    // { id: 3, src: faq, path: '#', text: 'FAQ' },
    // { id: 4, src: phone, path: '#', text: 'Контакты' },
  ];
};
