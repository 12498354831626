import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import styles from './UserPageInfo.module.scss';
import cn from 'classnames';
import * as Tabs from '@radix-ui/react-tabs';
import { useTranslation } from 'react-i18next';

interface Statistics {
  total_users: number | null;
  in_trip: number | null;
  waiting: number | null;
  ended: number | null;
}

interface Props {
  userTripStatus: string;
  setTripStatus: (newTrip: string) => void;
}

const TRIP_STATUSES = {
  ALL: '',
  ACTIVE: 'active',
  WAITING: 'waiting',
  ENDED: 'ended',
};

export const UserPageInfo = ({ userTripStatus, setTripStatus }: Props) => {
  const { t } = useTranslation();
  const { data: userStatistics } = useQuery<Statistics>({
    queryFn: async () => {
      const response = await instance.get('admin/users-statistics');

      return response.data;
    },
    queryKey: ['users-statistics'],
  });

  return (
    <Tabs.Root
      className={styles.tabsRoot}
      value={userTripStatus}
      onValueChange={value => setTripStatus(value)}
    >
      <Tabs.List className={styles.userpageInfoList}>
        <Tabs.Trigger
          value=""
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: userTripStatus === TRIP_STATUSES.ALL,
          })}
        >
          {t('total')}{' '}
          {userStatistics?.total_users ? (
            `(${userStatistics?.total_users})`
          ) : (
            <span className={styles.amountPlaceholder}>(10)</span>
          )}
        </Tabs.Trigger>
        <Tabs.Trigger
          value="active"
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: userTripStatus === TRIP_STATUSES.ACTIVE,
          })}
        >
          {t('business_trip')}{' '}
          {userStatistics?.in_trip ? (
            `(${userStatistics?.in_trip})`
          ) : (
            <span className={styles.amountPlaceholder}>(10)</span>
          )}
        </Tabs.Trigger>
        <Tabs.Trigger
          value="waiting"
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]:
              userTripStatus === TRIP_STATUSES.WAITING,
          })}
        >
          {t('awaiting_shipment')}{' '}
          {userStatistics?.waiting !== null &&
          userStatistics?.waiting !== undefined ? (
            `(${userStatistics?.waiting})`
          ) : (
            <span className={styles.amountPlaceholder}>(10)</span>
          )}
        </Tabs.Trigger>
        <Tabs.Trigger
          value="ended"
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: userTripStatus === TRIP_STATUSES.ENDED,
          })}
        >
          {t('finished_business_trip')}{' '}
          {userStatistics?.ended ? (
            `(${userStatistics?.ended})`
          ) : (
            <span className={styles.amountPlaceholder}>(10)</span>
          )}
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  );
};
