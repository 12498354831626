import { NavLink } from 'react-router-dom';
import ArrowRight from '~/assets/svg/newSvg/arrow-right.svg?react';
import styles from './AsideList.module.scss';
import { ReactNode, useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfFoodAdmin, checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import NotificationsContainer from '../../Header/NotificationsContainer/NotificationsContainer';

interface AsideItem {
  id: number;
  path: string;
  text: string;
  icon: ReactNode;
}

interface AsideListProps {
  isExpanded: boolean;
  list: AsideItem[];
}

const AsideList = ({ list, isExpanded }: AsideListProps): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isSuperAdminAccess = checkIfUserSuperAdmin(user?.data.role_name);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);

  const filteredList = list.filter(item => {
    if (isFoodAdminAccess) {
      return item.path === '/settings';
    }
    return isSuperAdminAccess || item.path !== '/assets';
  });

  const { t } = useTranslation();

  return (
    <ul
      className={cn(
        styles.aside__list,
        isExpanded ? styles.aside__listExpanded : undefined
      )}
    >
      {filteredList.map(({ id, path, text, icon }) => {
        return (
          <li key={id} className={styles.aside__item}>
            <NavLink
              to={path}
              className={({ isActive }) =>
                `${isActive ? styles.active : ''} ${styles.aside__link}`
              }
            >
              <span className={styles.aside__icon}>{icon}</span>

              <div className={styles.aside__text}>
                {isExpanded ? <span>{t(text)}</span> : ''}
                <div className={styles.asideRight}>
                  {path === '/requests' && !isFoodAdminAccess ? (
                    <NotificationsContainer />
                  ) : (
                    ''
                  )}
                  {isExpanded ? <ArrowRight className={styles.arrow} /> : ''}
                </div>
              </div>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

export default AsideList;
