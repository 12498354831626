import { toast } from 'react-toastify';
import AlertIcon from '../assets/svg/newSvg/success-alert.svg?react';
import '~/assets/css/toastify.css';

export function useToastSuccess() {
  return (message: string) => {
    return toast(
      <div className="toastContent">
        <span className="toastContentTitle">{message}</span>
      </div>,
      {
        type: 'success',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: <AlertIcon />,
      }
    );
  };
}
