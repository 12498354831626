import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink, IconButton } from '~/ui';
import { renderStatus } from './utils';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './ActualTable.module.scss';
import { renderComment } from '~/pages/Feedbacks/utils';

interface Social {
  id: number;
  category: string;
  place: string;
  name: string;
  status: string;
}

interface Props {
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  socials: Social[];
}

export const ActualTable = ({ sort, setSort, socials }: Props): JSX.Element => {
  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="category">Предмет опроса</TableCellHead>
        <TableCellHead field="place">Выборка</TableCellHead>
        <TableCellHead field="name">Название опроса</TableCellHead>
        <TableCellHead field="status">Статус</TableCellHead>
        <TableCellHead sortable={false} field="actions">
          Действие
        </TableCellHead>
      </TableHead>
      <TableBody>
        {socials.map(({ id, category, place, name, status }: Social) => (
          <TableRow key={id}>
            <TableCell>{category}</TableCell>
            <TableCell>{place}</TableCell>
            <TableCell>{renderComment(name)}</TableCell>
            <TableCell>{renderStatus(status)}</TableCell>
            <TableCell>
              <div className={styles.actions}>
                <IconButton onClick={() => {}}>
                  <Basket />
                </IconButton>
                <IconButtonLink
                  to={`/socials/${id}`}
                  className={styles.btnLink}
                >
                  <Edit />
                </IconButtonLink>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
