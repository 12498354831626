import { InputBar } from '~/ui';
import styles from './QuestionnaireStats.module.scss';
import StatBar from '../StatBar/StatBar';
import { useTranslation } from 'react-i18next';

const QuestionnaireStats = ({ questionnaire }: { questionnaire: any }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.questionnaireStats}>
      <InputBar label="" value={questionnaire?.name} disabled />
      <div className={styles.statBarWrapper}>
        <StatBar
          percent={100}
          label={`${t('questionnaire_sent')} (${
            questionnaire?.all_count || 0
          })`}
        />
        <div className={styles.statBar}>
          <StatBar
            percent={Math.round(questionnaire?.answer_percent) || 0}
            label={`${t('questionnaire_answers')} (${
              questionnaire?.answer_count || 0
            })`}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireStats;
