import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthentication } from './Authentication/useAuthentication';

interface PrivateRouteProps {
  children: ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const location = useLocation();
  const { authenticated } = useAuthentication();

  if (!authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    return children;
  }
}

export default PrivateRoute;
