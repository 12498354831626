import { useAuthentication } from '~/components/Authentication/useAuthentication';
import Content from './Content';
import Layout from '~/components/Shared/Layout';

const NotFound = () => {
  const { authenticated } = useAuthentication();
  return authenticated ? (
    <Layout>
      <Content />
    </Layout>
  ) : (
    <Content />
  );
};

export default NotFound;
