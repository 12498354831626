import { useTranslation } from 'react-i18next';
import styles from './NoSolutionButton.module.scss';

const NoSolutionButton = () => {
  const { t } = useTranslation();

  return (
    <a href="mailto:oazis@ifcmgroup.ru" className={styles.noSolutionButton}>
      {t('couldnt_solve')}
    </a>
  );
};

export default NoSolutionButton;
