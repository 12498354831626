import { useState } from 'react';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Toggle from '~/components/Shared/Toggle/Toggle';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './PersonFood.module.scss';
import { IconButton, IconButtonLink } from '~/ui';
import { TableCell, TableRow } from '~/ui/Table';
import { positionTitle } from '../.';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';

interface IPersonFoodProps {
  id: number;
  name: string;
  patronymic: string;
  email: string;
  phone: string;
  position: string;
  foodMenuId: number;
  isAutosendOn: boolean;
}

const PersonFood = ({
  id,
  name,
  patronymic,
  email,
  phone,
  position,
  isAutosendOn,
  foodMenuId,
}: IPersonFoodProps) => {
  const [isChecked, setIsChecked] = useState(isAutosendOn);
  const client = useQueryClient();
  const toastError = useToastError();

  const handleToggleAutosend = async () => {
    const savedIsChecked = isChecked;
    setIsChecked(!isChecked);
    try {
      await instance.post(`admin/food-person-autosend/${id}`);
    } catch (error) {
      setIsChecked(savedIsChecked);
      toastError(error);
    }
  };

  const { mutate: deletePerson } = useMutation(
    async (id: string) => {
      return await instance.delete(`admin/food-person/${id}`);
    },
    {
      onSuccess: () => {
        client.invalidateQueries([['food-persons'], foodMenuId]);
      },
    }
  );

  return (
    <TableRow key={id}>
      <TableCell>
        {name}&ensp;
        {patronymic}
      </TableCell>
      <TableCell>{positionTitle(position)}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>
        <div className={styles.actions}>
          <IconButton onClick={() => deletePerson(id.toString())}>
            <Basket />
          </IconButton>
          <IconButtonLink
            to={`/food/${foodMenuId}/persons/${id}`}
            className={styles.btnLink}
          >
            <Edit />
          </IconButtonLink>
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.actions}>
          <Toggle isChecked={isChecked} onChange={handleToggleAutosend} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PersonFood;
