import cn from 'classnames';
import styles from './GrayBanner.module.scss';

interface Props {
  text: string;
  className?: string;
}

export const GrayBanner = ({ text, className }: Props): JSX.Element => {
  return (
    <div className={cn(styles.banner, className)}>
      <p className={styles.bannerText}>{text}</p>
    </div>
  );
};
