import React, { useState } from 'react';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { IconButton } from '~/ui';
import styles from './BusinessTripTable.module.scss';
import { DatePicker } from 'antd';
import DatePickerIcon from '~/assets/svg/datePicker/datePickerIcon.svg?react';
import CheckMarkIcon from '~/assets/svg/newSvg/mark-icon.svg?react';
import CloseMarkIcon from '~/assets/svg/newSvg/basket.svg?react';
import { ParamsProps } from '~/utils/api/queries';
import { AccommodationItem } from '~/components/AccommodationForm';
import { instance } from '~/utils/api/api';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { FormikValues } from 'formik';
import { IUserBusinessTrip } from '../../UserForm';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import EditRow from './EditRow/EditRow';
import { useTranslation } from 'react-i18next';
import { IRoom } from '../../BusinessTripForm';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';

interface IBusinessTripTableProps {
  accommodations: AccommodationItem[];
  editRowId: number | null;
  setEditRowId: (id: number | null) => void;
  formik: FormikValues;
  isCreateShown: boolean;
  businessTrips: IUserBusinessTrip[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  params: ParamsProps;
  closeCreateTrip: () => void;
  isEditing: boolean | undefined;
}

export interface IAccommodation {
  id: number;
  name: string;
}

interface IBusinessTrip {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  accommodation_id: number;
  created_at: string;
  updated_at: string;
  accommodation: AccommodationItem;
}

const BusinessTripTable: React.FC<IBusinessTripTableProps> = ({
  accommodations,
  editRowId,
  setEditRowId,
  formik,
  isCreateShown,
  businessTrips,
  sort,
  setSort,
  params,
  closeCreateTrip,
  isEditing,
}) => {
  const { userID } = useParams();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [accommodation, setAccommodation] = useState<IAccommodation>({
    id: 0,
    name: '',
  });
  const [room, setRoom] = useState<IRoom>({
    id: 0,
    name: '',
  });
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const { t } = useTranslation();

  const clearValues = () => {
    setStartDate(null);
    setEndDate(null);
    setAccommodation({
      id: 0,
      name: '',
    });
  };

  const setRowEdit = (id: number) => {
    setEditRowId(id);
    clearValues();
    closeCreateTrip();
  };

  const closeEditing = () => {
    setEditRowId(null);
  };

  const closeTripEdit = () => {
    setStartDate(null);
    setEndDate(null);
    closeCreateTrip();
  };

  const saveNewTrip = async () => {
    try {
      const { data } = await instance.post('admin/business-trip', [
        {
          accommodation_id: accommodation.id,
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
          user_id: userID,
          room_id: room.id || null,
        },
      ]);
      formik.setFieldValue('business_trips', [
        ...formik.values.business_trips,
        ...data,
      ]);
      closeCreateTrip();
      toastSuccess(`${t('new_business_trip_added_successfully')}!`);
      clearValues();
    } catch (error) {
      toastError(t('error_adding_new_business_trip'));
    }
  };

  const updateTrip = async (id: number) => {
    try {
      const { data } = await instance.put(`admin/business-trip/${id}`, {
        accommodation_id: accommodation.id,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        room_id: room.id || null,
      });

      formik.setFieldValue('business_trips', [
        ...formik.values.business_trips.filter(
          (trip: IBusinessTrip) => trip.id !== id
        ),
        data,
      ]);

      toastSuccess(`${t('business_trip_successfully_edited')}!`);
      setEditRowId(null);
    } catch (error) {
      toastError(t('error_editing_business_trip'));
    }
  };

  const saveTripToForm = () => {
    accommodations.find(accomodationCompared => {
      return accomodationCompared.id === accommodation.id;
    });
    formik.setFieldValue('business_trips', [
      ...formik.values.business_trips,
      {
        id: formik.values.business_trips.length + 1,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        accommodation: accommodations.find(
          accomodation => accomodation.id === accommodation.id
        ),
        room_id: room.id || null,
        room: room,
      },
    ]);
    closeTripEdit();
  };

  const deleteTripFromForm = (id: number) => {
    formik.setFieldValue(
      'business_trips',
      formik.values.business_trips.filter(
        (trip: IBusinessTrip) => id !== trip.id
      )
    );
  };

  const deleteTrip = async (id: number) => {
    const initialTrips = formik.values.business_trips;
    formik.setFieldValue(
      'business_trips',
      formik.values.business_trips.filter(
        (trip: IBusinessTrip) => id !== trip.id
      )
    );
    try {
      await instance.delete(`admin/business-trip/${id}`);
      toastSuccess(`${t('business_trip_successfully_deleted')}!`);
    } catch (error) {
      formik.setFieldValue('business_trips', initialTrips);
      toastError(t('error_deleting_business_trip'));
    }
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead sortable={false} field="start_date">
          {t('business_trip_start_date')}
        </TableCellHead>
        <TableCellHead sortable={false} field="end_date">
          {t('business_trip_end_date')}
        </TableCellHead>
        <TableCellHead sortable={false} field="object">
          {t('accommodation_facility_section')}
        </TableCellHead>
        <TableCellHead sortable={false} field="object">
          {t('room')}
        </TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {businessTrips.map(
          ({
            id,
            start_date,
            end_date,
            accommodation: accommodationToEdit,
            room: roomToEdit,
          }: any) => {
            if (editRowId === id) {
              return (
                <EditRow
                  id={id}
                  startDateToEdit={start_date}
                  endDateToEdit={end_date}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  accommodationToEdit={accommodationToEdit}
                  accommodation={accommodation}
                  room={room}
                  setRoom={setRoom}
                  setAccomodation={setAccommodation}
                  params={params}
                  updateTrip={updateTrip}
                  clickStopEditing={closeEditing}
                />
              );
            } else {
              return (
                <TableRow key={id}>
                  <TableCell>
                    {dayjs(start_date).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>{dayjs(end_date).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>
                    {accommodationToEdit?.name}/{accommodationToEdit?.section}
                  </TableCell>
                  <TableCell className={styles.roomCell}>
                    {roomToEdit?.name || t('not_available')}
                  </TableCell>
                  <TableCell>
                    <div className={styles.BusinessTripTableActions}>
                      <ModalConfirm
                        title={`${t('sure_to_delete_business_trip')}?`}
                        onConfirm={() => {
                          isEditing ? deleteTrip(id) : deleteTripFromForm(id);
                        }}
                      >
                        {open => (
                          <IconButton onClick={open}>
                            <Basket />
                          </IconButton>
                        )}
                      </ModalConfirm>
                      {isEditing ? (
                        <IconButton
                          onClick={() => {
                            setRowEdit(id);
                            setRoom(roomToEdit);
                          }}
                          className={styles.BusinessTripTableBtnLink}
                        >
                          <Edit />
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          }
        )}
        {isCreateShown && (
          <TableRow>
            <TableCell>
              <DatePicker
                className={styles.BusinessTripDatePicker}
                allowClear={false}
                suffixIcon={<DatePickerIcon />}
                disabledDate={d => !d || d.isAfter(endDate)}
                format="DD.MM.YYYY"
                placeholder={t('date')}
                value={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
              />
            </TableCell>
            <TableCell>
              <DatePicker
                className={styles.BusinessTripDatePicker}
                allowClear={false}
                suffixIcon={<DatePickerIcon />}
                disabledDate={d => !d || d.isBefore(startDate)}
                format="DD.MM.YYYY"
                placeholder={t('date')}
                value={endDate}
                onChange={date => {
                  setEndDate(date);
                }}
              />
            </TableCell>
            <TableCell>
              <CustomQuerySelect
                label=""
                query="admin/accommodations"
                queryKey={['accommodations', params]}
                placeholder={t('accommodations_validate')}
                params={params}
                value={accommodation}
                onChange={value => {
                  if (!Array.isArray(value) && value?.name) {
                    setAccommodation(value);
                    setRoom({
                      id: 0,
                      name: '',
                    });
                  }
                }}
                required
                isSearchEnabled
              />
            </TableCell>
            <TableCell>
              <CustomQuerySelect
                onChange={value => {
                  if (!Array.isArray(value) && value?.name) {
                    setRoom(value);
                  }
                }}
                value={room}
                query={'admin/rooms'}
                queryKey={[
                  'rooms',
                  {
                    sort: '',
                    perPage: 100,
                    field: '',
                    page: 1,
                    accommodation_id: accommodation.id,
                  },
                ]}
                params={{
                  sort: '',
                  perPage: 100,
                  field: '',
                  page: 1,
                  accommodation_id: accommodation.id,
                }}
                placeholder={t('validate_room')}
                required
              />
            </TableCell>
            <TableCell>
              <div className={styles.BusinessTripTableActions}>
                <IconButton onClick={isEditing ? saveNewTrip : saveTripToForm}>
                  <CheckMarkIcon className={styles.BusinessTripSaveIcon} />
                </IconButton>
                <IconButton onClick={closeTripEdit}>
                  <CloseMarkIcon className={styles.BusinessTripSaveIcon} />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default BusinessTripTable;
