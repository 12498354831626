import { Navigate } from 'react-router-dom';
import { useAuthentication } from '~/components/Authentication/useAuthentication';
import AuthForm from '~/components/AuthForm/AuthForm';
import './Login.scss';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfFoodAdmin } from '~/utils/getUserRole';

const Login = (): JSX.Element => {
  const { authenticated } = useAuthentication();
  const { user } = useContext(AuthenticationContext);
  const isFoodAdmin = checkIfFoodAdmin(user?.data.role_name);

  if (authenticated) {
    if (isFoodAdmin) {
      return <Navigate to="/transactions" />;
    }
    return <Navigate to="/accommodations" />;
  }

  return (
    <div className="loginPage">
      <AuthForm />
    </div>
  );
};

export default Login;
