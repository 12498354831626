import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import UploadFile from './UploadFile/UploadFile';
import styles from './UploadUsers.module.scss';
import { useToastError } from '~/utils/useToastError';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { toastUpload } from '~/utils/useToastUpload';
import { instance } from '~/utils/api/api';

const UploadUsers = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const toastError = useToastError();
  const { user } = useContext(AuthenticationContext);

  const { mutate: uploadFoods } = useMutation(
    async () => {
      const formData = new FormData();
      formData.append('excel', selectedFile as File);
      if (user?.data.id) {
        formData.append('user_id', String(user?.data?.id) as string);
      }
      const response = await instance.post('golang-upload-user', formData);
      return response.data;
    },
    {
      onSuccess: data => {
        if (user?.data?.id) {
          localStorage.setItem('uploadId', data.unique_id);
        }
      },
      onError: (e: any) => {
        if (e.response.status === 409) {
          toastError(e.response.data.error);
        } else {
          toastError(e);
        }
      },
    }
  );
  const uniqueId = localStorage.getItem('uploadId');
  toastUpload(uniqueId);

  useEffect(() => {
    handleProcessClick();
  }, [selectedFile]);

  const handleProcessClick = () => {
    if (!selectedFile) return;
    uploadFoods();
  };

  const handleFileChange = (uploadedFile: File) => {
    setSelectedFile(uploadedFile);
  };

  return (
    <UploadFile
      onChange={handleFileChange}
      className={styles.content__upload}
      selectedFile={selectedFile}
      removeFile={() => setSelectedFile(null)}
    />
  );
};

export default UploadUsers;
