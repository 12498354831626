import ImageContainer from '~/pages/EditFood/FullMenu/ImageContainer/ImageContainer';
import CheckMarkIcon from '~/assets/svg/newSvg/mark-icon.svg?react';
import { useTranslation } from 'react-i18next';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import UploadPhoto, {
  UploadPhotosValue,
} from '~/components/UploadPhoto/UploadPhoto';
import DeleteIcon from '~/assets/svg/newSvg/basket.svg?react';
import { getLocalTimeDate } from '~/utils/getLocalTimeDate';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { IconButton, Textarea } from '~/ui';
import moment from 'moment-timezone';
import { useState } from 'react';

import styles from './Comment.module.scss';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

interface User {
  full_name: string;
  role_name: string;
}

interface ICommentProps {
  isEditShown?: boolean;
  id: number;
  user: User;
  description: string;
  date: string;
  images: {
    id: number;
    name: string;
    full_photo_url: string;
  }[];
  onImageClick?: (image: string) => void;
  onConfirmClick: (id: number) => void;
}

const Comment = ({
  isEditShown,
  id,
  user,
  description,
  date,
  images,
  onImageClick,
  onConfirmClick,
}: ICommentProps) => {
  const [descriptionEditing, setDescriptionEditing] =
    useState<string>(description);
  const [photosEditing, setPhotosEditing] = useState<UploadPhotosValue>({
    photos: images,
    files: [],
  });
  const [deletedPhotos, setDeletedPhotos] = useState<number[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { t } = useTranslation();
  const toastError = useToastError();
  const { requestID } = useParams();
  const client = useQueryClient();

  const handleSubmitUpdateComment = async () => {
    try {
      await instance.postForm(`admin/ticket-comment/${id}?_method=put`, {
        description: descriptionEditing,
        images: photosEditing.files,
        deleted_images: deletedPhotos,
      });
      setDeletedPhotos([]);
      setPhotosEditing({
        photos: images,
        files: [],
      });
      setDescriptionEditing(description);
      client.invalidateQueries(['request', requestID]);
    } catch (error) {
      toastError(error);
    }
  };

  const handleOpenEditing = () => {
    setIsEditing(true);
    setDescriptionEditing(description);
    setPhotosEditing({
      photos: images,
      files: [],
    });
  };

  const handleCloseEditing = () => {
    setIsEditing(false);
    setDescriptionEditing(description);
    handleSubmitUpdateComment();
  };

  const onPhotoDelete = (id: number) => {
    setDeletedPhotos([...deletedPhotos, id]);
  };

  return (
    <li className={styles.commentItem} key={id}>
      <div className={styles.commentItemInfo}>
        <div className={styles.userWrapper}>
          <p className={styles.userName}>{user.full_name}</p>
          <span className={styles.userRole}>{user.role_name}</span>
        </div>
        {isEditing ? (
          <Textarea
            className={styles.textarea}
            name="description"
            rows={4}
            label=""
            star={false}
            value={descriptionEditing}
            onChange={event => setDescriptionEditing(event.target.value)}
            maxLength={600}
          />
        ) : (
          <p className={styles.commentText}>{description}</p>
        )}
        <div className={styles.photosWrapper}>
          {isEditing ? (
            <UploadPhoto
              imageWidth={60}
              imageHeight={60}
              onChange={uploadPhotosValue => {
                setPhotosEditing(uploadPhotosValue);
              }}
              onPhotoDelete={onPhotoDelete}
              values={photosEditing}
              label=""
            />
          ) : (
            <>
              {images?.length ? (
                <ul className={styles.images}>
                  {images.map(image => (
                    <li className={styles.imageItem}>
                      <ImageContainer
                        width={60}
                        height={60}
                        onClick={
                          onImageClick
                            ? () => onImageClick(image.full_photo_url)
                            : () => {}
                        }
                        image={image.full_photo_url}
                      />
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </>
          )}
        </div>
        <span className={styles.commentDate}>
          {getLocalTimeDate(moment(date).format())}
        </span>
      </div>
      <div className={styles.commentAction}>
        {isEditShown ? (
          <>
            {isEditing ? (
              <IconButton onClick={handleCloseEditing}>
                <CheckMarkIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleOpenEditing}>
                <Edit />
              </IconButton>
            )}
          </>
        ) : (
          ''
        )}
        <ModalConfirm
          title={`${t('delete_comment_confirm_1')}\n${t(
            'delete_comment_confirm_2'
          )}`}
          onConfirm={() => onConfirmClick(id)}
        >
          {open => (
            <IconButton onClick={open}>
              <DeleteIcon />
            </IconButton>
          )}
        </ModalConfirm>
      </div>
    </li>
  );
};

export default Comment;
