import { ErrorMessage, FormikContext, useField } from 'formik';
import s from './ErrorField.module.scss';
import { useContext } from 'react';

interface ErrorFieldProps {
  name: string;
}

const ErrorField = (props: ErrorFieldProps) => {
  const { name } = props;

  const hasContext = useContext(FormikContext);

  if (!hasContext) {
    return null;
  }

  const [_, meta] = useField(name);

  if (!meta.touched || !meta.error) {
    return null;
  }

  return (
    <div className={s.container}>
      <ErrorMessage name={name} />
    </div>
  );
};

export default ErrorField;
