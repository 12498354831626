import { ApiError } from '~/utils/api/api';
import styles from './ErrorMessage.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  error?: unknown;
}

export const ErrorMessage = ({ error }: Props) => {
  const { t } = useTranslation();
  let message = '';

  if (!(error instanceof ApiError)) {
    return t('unknown_error');
  } else {
    message = error?.message;
  }

  return (
    <div className={styles.errorMessage}>
      <h5 className={styles.errorMessageHeading}>{t('error_has_occurred')}:</h5>
      <p className={styles.errorMessageText}>"{message}"</p>
      <p className={styles.errorMessageText}>{t('repeat_reload_error')}</p>
    </div>
  );
};
