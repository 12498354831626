import { useEffect, useState } from 'react';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { validate } from '~/utils/validateAsset';
import { useFormik } from 'formik';
import styles from './EditAsset.module.scss';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { useToastError } from '~/utils/useToastError';
import { instance } from '~/utils/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import EditAssetForm from './EditAssetForm/EditAssetForm';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { Button } from '~/ui';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

export interface IColorScheme {
  id: number;
  name: string;
  color: string;
  text_color: string;
}

export interface IAssetValues {
  id: number;
  name: string;
  description: string;
  inn: string;
  color_id: number;
  logo?: File | null;
  address: string;
  image_path: string;
  delete_image: boolean;
}

const EditAsset = () => {
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const [colors, setColors] = useState<IColorScheme[]>([]);
  const [asset, setAsset] = useState<IAssetValues>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const client = useQueryClient();

  const { assetID: id } = useParams();

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<IAssetValues>({
      initialValues: {
        id: 0,
        name: '',
        description: '',
        inn: '',
        color_id: 1,
        logo: null,
        address: '',
        image_path: '',
        delete_image: false,
      },
      exclude: [],
    });

  const formik = useFormik({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async values => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('inn', values.inn);
      if (values.logo) {
        formData.append('image', values.logo);
      }
      formData.append('color_id', String(values.color_id));
      formData.append('address', values.address);
      formData.append('delete_image', values.logo ? '0' : '1');
      try {
        await instance.post(`admin/assets/${id}?_method=put`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        navigate('/assets');
        client.invalidateQueries(['assets']);
        clear();
        formik.resetForm({ values: initialValues });
        toastSuccess(t('asset_edited'));
      } catch (error) {
        toastError(error);
      } finally {
        setIsLoading(true);
      }
    },
  });

  create(formik.values);

  const loadColors = async () => {
    try {
      const { data } = await instance.get('admin/color-scheme');

      setColors(data);
    } catch (error) {
      toastError(error);
    }
  };

  const loadAssetById = async () => {
    setIsLoading(true);
    try {
      const { data } = await instance.get(`admin/assets/${id}`);
      queryClient.setQueryData(['asset', id], data);
      formik.setValues(data);
      setAsset(data);
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAsset = async () => {
    try {
      await instance.delete(`admin/assets/${id}`);

      clear();
      formik.resetForm({ values: initialValues });
      client.invalidateQueries(['assets']);
      navigate('/assets');
      toastSuccess(t('asset_deleted'));
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    loadColors();
    loadAssetById();
  }, []);

  return (
    <Page
      heading={
        <Heading
          text={asset?.name}
          classNameText={styles.createUserPageText}
          actions={
            <ModalConfirm
              title={`${t('asset_delete_confirm')} ${asset?.name}?`}
              onConfirm={() => deleteAsset()}
            >
              {open => (
                <Button
                  className={styles.deleteAssetButton}
                  disabled={false}
                  color="red"
                  text={t('delete')}
                  onClick={open}
                />
              )}
            </ModalConfirm>
          }
        />
      }
    >
      <EditAssetForm
        isLoading={isLoading}
        colorSchemes={colors}
        formik={formik}
      />
    </Page>
  );
};

export default EditAsset;
