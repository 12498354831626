import { useState } from 'react';
import styles from './ClosedAnswersForm.module.scss';
import CheckIcon from '~/assets/svg/newSvg/check.svg?react';
import AddIcon from '~/assets/svg/newSvg/addRaw.svg?react';
import BasketIcon from '~/assets/svg/newSvg/basketRaw.svg?react';
import { instance } from '~/utils/api/api';
import { FormikValues } from 'formik';
import { pluralize } from '~/utils/helpers';
import { useToastError } from '~/utils/useToastError';
import { IVariant } from '~/components/QuestionnaireForm';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

type IClosedAnswersForm = {
  questionId: number;
  variants: IVariant[];
  formik: FormikValues;
};
const MAX_COUNT = 12;

const pluralizeVariant = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('variant'),
    i18next.t('variant_2'),
    i18next.t('variant_3'),
  ]);

const ClosedAnswersForm = ({
  questionId,
  variants,
  formik,
}: IClosedAnswersForm) => {
  const [isNewAnswerOpened, setIsNewAnswerOpened] = useState(false);
  const [newVariantName, setNewVariantName] = useState('');
  const toastError = useToastError();
  const { t } = useTranslation();

  const saveNewVariant = async () => {
    try {
      const { data } = await instance.post(`admin/variants`, {
        question_id: questionId,
        variants: [newVariantName],
      });
      formik.setFieldValue('variants', [...variants, ...data]);
      setIsNewAnswerOpened(false);
      setNewVariantName('');
    } catch (error) {
      toastError(error);
    }
  };

  const clickDeleteVariant = async (id: number) => {
    const initialVariant = variants;
    formik.setFieldValue(
      'variants',
      variants?.filter(variant => variant.id !== id)
    );
    try {
      await instance.delete(`admin/variants/${id}`);
    } catch (error) {
      formik.setFieldValue('variants', initialVariant);
    }
  };

  const cancelAddNewVariant = async () => {
    setIsNewAnswerOpened(false);
    setNewVariantName('');
  };

  if (variants?.length > 1) {
    delete formik.errors.variants;
  } else {
    formik.setErrors({
      variants: variants?.length > 2 ? '' : t('at_least_2_variants'),
    });
  }

  return (
    <div className={styles.closedAnswersForm}>
      <div className={styles.closedAnswersFormWrapper}>
        <ul className={styles.variantList}>
          {variants?.map(variant => (
            <li className={styles.variant}>
              {variant.name}
              <button
                type="button"
                onClick={() => clickDeleteVariant(variant.id)}
                className={styles.variantDeleteButton}
              >
                <BasketIcon className={styles.deleteIcon} />
              </button>
            </li>
          ))}
        </ul>
        {isNewAnswerOpened && (
          <div className={styles.inputWrapper}>
            <input
              value={newVariantName}
              onChange={event => setNewVariantName(event.target.value)}
              className={styles.input}
              type="text"
              placeholder={t('question_variant_validate')}
            />
            <button
              onClick={saveNewVariant}
              type="button"
              className={styles.saveVariantButton}
              disabled={newVariantName.length < 2}
            >
              <CheckIcon />
            </button>
            <button
              onClick={cancelAddNewVariant}
              className={styles.deleteVariantButton}
            >
              <BasketIcon className={styles.deleteIcon} />
            </button>
          </div>
        )}
        {(variants?.length || 0) < MAX_COUNT && (
          <button
            className={styles.addButton}
            type="button"
            onClick={() => setIsNewAnswerOpened(true)}
          >
            <AddIcon />
            {t('add_variant')}
          </button>
        )}
      </div>
      <span className={styles.description}>
        {variants?.length === MAX_COUNT
          ? `${t('cant_add_more_variants')} ${pluralizeVariant(MAX_COUNT)}`
          : `${t('can_add_more_variants')} ${pluralizeVariant(
              MAX_COUNT - (variants?.length || 0)
            )}`}
      </span>
      <span className={styles.error}>{formik.errors.variants}</span>
    </div>
  );
};

export default ClosedAnswersForm;
