import { forwardRef, useEffect } from 'react';
import Comment from './Comment/Comment';

import styles from './CommentList.module.scss';
import './popover.css';

interface Comment {
  id: number;
  description: string;
  created_at: string;
  images: {
    id: number;
    name: string;
    full_photo_url: string;
  }[];
}

interface User {
  full_name: string;
  role_name: string;
}

interface ICommentsListProps {
  comments: Comment[];
  user: User;
  isEditShown?: boolean;
  onClick: (id: number) => void;
  handleClickImage?: (image: string) => void;
  scrollToBottom?: () => void;
}

type Ref = HTMLDivElement;

export const CommentList = forwardRef<Ref, ICommentsListProps>(
  (
    { comments, user, isEditShown, onClick, handleClickImage, scrollToBottom },
    ref
  ): JSX.Element | null => {
    useEffect(() => {
      if (scrollToBottom) {
        scrollToBottom();
      }
    }, [comments.length]);

    return (
      <ul className={styles.commentList}>
        {comments.map(({ id, description, created_at, images }: Comment) => (
          <Comment
            isEditShown={isEditShown}
            id={id}
            user={user}
            onImageClick={handleClickImage}
            onConfirmClick={onClick}
            description={description}
            date={created_at}
            images={images}
          />
        ))}
        <div ref={ref} className={styles.scrollTo}></div>
      </ul>
    );
  }
);
