import { useField } from 'formik';
import cn from 'classnames';
import s from './SwitchInput.module.scss';

interface SwitchInputProps {
  label: string;
  name?: string;
}

function SwitchInput(props: SwitchInputProps) {
  const { name, label } = props;
  const [field, _, helpers] = useField(name || '');

  const handleClick = () => {
    helpers.setValue(!field.value);
  };

  return (
    <div className={s.container}>
      {label}
      <button
        type="button"
        className={cn(s.box, field.value && s.box_active)}
        onClick={handleClick}
      ></button>
    </div>
  );
}

export default SwitchInput;
