import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['ru', 'en', 'dev'],
    fallbackLng: 'ru',
    detection: {
      order: [
        'cookie',
        'localStorage',
        'htmlTags',
        'path',
        'subdomain',
        'queryString',
      ],
      caches: ['cookie', 'localStorage'],
    },
    debug: true,
    backend: { loadPath: '/src/locales/{{lng}}/translation.json' },
  });

export default i18n;
