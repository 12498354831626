import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import cn from 'classnames';
import { InputBar, Select } from '~/ui';
import form from '~/theme/ui/form.module.scss';
import button from '~/theme/ui/button.module.scss';
import SwitchInput from '~/ui/SwitchInput';
import AddIcon from '~/assets/svg/newSvg/actions/add-icon-empty.svg?react';
import { types, getTypeOptions } from './typeOptions';
import styles from './CreateQuestionForm.module.scss';
import TextFieldArray from '~/ui/TextFieldArray/TextFieldArray';
import FieldGroup from '~/ui/FieldGroup';
import Grade from '~/ui/Grade';
import { IVariant } from '../QuestionnaireForm';
import { useTranslation } from 'react-i18next';

export interface QuestionItem {
  id: number;
  name: string;
  type: 'close' | 'open' | 'estimate';
  userId?: number | null;
  question_data: {
    multi_answer?: boolean;
    variants?: IVariant[];
    question: string;
    required: boolean;
    question_close_variants?: IVariant[];
  };
  question: string;
  required: boolean;
  variants?: IVariant[];
  [key: string]: any;
  multi_answer?: boolean;
}

const QuestionSchema = yup.object().shape({
  id: yup.number(),
  name: yup.string().required('question_write'),
  type: yup.number().required(),
  answer: yup.string(),
  required: yup.boolean(),
  variants: yup
    .array()
    .of(yup.string().required('question_variant_validate'))
    .max(8),
  question_close_variants: yup.array().optional(),
  multi_answer: yup.boolean(),
});

export type QuestionValues = yup.InferType<typeof QuestionSchema>;

interface QuestionFormProps {
  handleCloseCreate: () => void;
  clickAddQuestion: (
    values: any,
    formik: FormikValues,
    initialValues: QuestionValues
  ) => void;
}

const CreateQuestionForm = ({
  handleCloseCreate,
  clickAddQuestion,
}: QuestionFormProps) => {
  const { t } = useTranslation();
  const typeOptions = getTypeOptions();

  const initialValues = {
    name: '',
    type: 2,
    answer: undefined,
    required: true,
    multi_answer: false,
    clickedButton: undefined,
    variants: [],
  };

  const handleCloseClick = async (formik: FormikValues) => {
    handleCloseCreate();
    formik.resetForm({ values: initialValues });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={QuestionSchema}
      onSubmit={(values, formik) =>
        clickAddQuestion(values, formik, initialValues)
      }
    >
      {formik => {
        if (formik.values.type === types.close) {
          if (formik.values.variants?.length > 1) {
            delete formik.errors.variants;
          } else {
            formik.setErrors({
              variants:
                formik.values.variants?.length > 2
                  ? ''
                  : t('at_least_2_variants'),
            });
          }
        } else {
          delete formik.errors.variants;
        }
        return (
          <form className={form.container} onSubmit={formik.handleSubmit}>
            <div className={form.group}>
              <InputBar
                name="question"
                label={t('question_title')}
                placeholder={t('question_write')}
                value={formik.values.name}
                disabled={false}
                star={true}
                onChange={event =>
                  formik.setFieldValue('name', event.target.value)
                }
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                errors={t(formik.errors.name || '')}
                classNameInput={styles.input}
              />
              <Select
                name="type"
                label={t('question_type')}
                value={Number(formik.values.type)}
                onChange={value => formik.setFieldValue('type', value)}
                options={typeOptions}
                star={true}
                size="small"
                className={styles.input}
              />
            </div>

            {formik.values.type === types.close && (
              <div className={styles.answersContainer}>
                <TextFieldArray
                  name="variants"
                  label={t('question_variants')}
                  maxCount={12}
                />
              </div>
            )}
            <span className={styles.error}>{formik.errors.variants}</span>

            {formik.values.type === types.estimate && (
              <div className={styles.answersContainer}>
                <FieldGroup label={t('estimation')}>
                  {(['bad', 'normal', 'good', 'super'] as const).map(grade => (
                    <FieldGroup.Item key={grade}>
                      <Grade value={grade} />
                    </FieldGroup.Item>
                  ))}
                </FieldGroup>
              </div>
            )}

            <div className={styles.footer}>
              {formik.values.type === types.close && (
                <SwitchInput
                  name="multi_answer"
                  label={t('multiple_answers')}
                />
              )}

              <SwitchInput name="required" label={t('required_question')} />
            </div>

            <div className={cn(form.actions, styles.actions)}>
              <button
                className={button.primary}
                onClick={() => handleCloseClick(formik)}
              >
                {t('cancel')}
              </button>

              <button
                disabled={!formik.isValid}
                type="submit"
                className={button.success}
              >
                <AddIcon className={button.icon} />
                {t('add_new_question')}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateQuestionForm;
