import styles from './BigBadge.module.scss';

interface IBigBadgeProps {
  title: number | string | null;
  description: string;
  Icon: React.FC;
}

const BigBadge = ({ title, description, Icon }: IBigBadgeProps) => {
  return (
    <div className={styles.bigBadge}>
      <div className={styles.bigBadgeText}>
        {title !== undefined && title !== null ? (
          <span className={styles.title}>{title}</span>
        ) : (
          <span className={styles.placeholderNumber}>100</span>
        )}
        <span className={styles.description}>{description}</span>
      </div>
      <Icon />
    </div>
  );
};

export default BigBadge;
