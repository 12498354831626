import cn from 'classnames';
import s from './Actions.module.scss';

interface ActionProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'success' | 'danger';
  onClick?: () => void;
  className?: string;
}

export const Action = (props: ActionProps) => {
  const { children, variant = 'secondary', onClick, className } = props;
  return (
    <div>
      <button
        type="button"
        className={cn(s.action, s[`action--${variant}`], className)}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

const Actions = (props: React.PropsWithChildren) => {
  return <div className={s.container}>{props.children}</div>;
};

export default Actions;
