import { ChangeEvent, useEffect, useState } from 'react';
import { Input, Popover } from 'antd';
import { Button, PasswordValidPopup, PasswordValidPopupProps } from '..';
import cn from 'classnames';
import styles from './InputPassword.module.scss';
import './popover.css';
import { useTranslation } from 'react-i18next';

interface InputPasswordProps {
  star: boolean;
  value: string;
  label: string;
  name: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  errors?: string;
  error?: boolean;
  onClick?: () => void;
  popover?: boolean;
  setPasswordValid?: (passwordValid: boolean) => void;
}

export const InputPassword = ({
  star,
  value,
  label,
  name,
  placeholder,
  onChange,
  disabled,
  errors,
  error,
  onClick,
  popover = true,
  setPasswordValid,
}: InputPasswordProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [validCheck, setValidCheck] = useState<PasswordValidPopupProps>({
    lengthCheck: false,
    capsCheck: false,
    letterCheck: false,
    numberCheck: false,
    russianLettersCheck: false,
  });
  const validPassword = Object.values(validCheck).every(
    value => value === true
  );
  const { t } = useTranslation();

  useEffect(() => {
    return popover ? setIsInputFocused(false) : setIsInputFocused(true);
  }, [popover]);

  useEffect(() => {
    if (value !== null) {
      const capsCheck = /[A-Z]/.test(value);
      const letterCheck = /[a-z]/.test(value);
      const numberCheck = /[0-9]/.test(value);
      const lengthCheck = value.length >= 8;
      const russianLettersCheck = value !== '' && !/[А-Яа-я]/.test(value);

      setValidCheck({
        capsCheck,
        letterCheck,
        numberCheck,
        lengthCheck,
        russianLettersCheck,
      });
    }
  }, [value]);

  useEffect(() => {
    if (!setPasswordValid) return;

    setPasswordValid(validPassword);
  }, [validPassword]);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    popover ? setIsInputFocused(false) : setIsInputFocused(true);
  };

  return (
    <div className={styles.input__wrapper}>
      <label className={styles.input__label} htmlFor={name}>
        <span className={styles.input__text}>
          {label}
          {star ? <span className={styles.input__star}>*</span> : null}
        </span>
        <Popover
          placement="left"
          trigger="focus"
          content={
            <PasswordValidPopup
              capsCheck={validCheck.capsCheck}
              letterCheck={validCheck.letterCheck}
              numberCheck={validCheck.numberCheck}
              lengthCheck={validCheck.lengthCheck}
              russianLettersCheck={validCheck.russianLettersCheck}
            />
          }
          className={`ant-popover ${popover ? 'ant-popover' : 'popverHidden'}`}
          open={isInputFocused}
        ></Popover>
        <Input.Password
          id={name}
          type="password"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          className={cn(styles['form__input--password'], {
            [styles['input__errors']]: error,
          })}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          disabled={disabled}
        />
        {error ? <div className={styles.errors__text}>{errors}</div> : null}
        {disabled ? (
          <Button
            text={t('change_password')}
            className={styles.btn}
            disabled={false}
            onClick={onClick}
          />
        ) : null}
      </label>
    </div>
  );
};
