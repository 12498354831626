import NoSolutionButton from '../NoSolutionButton/NoSolutionButton';
import CloseIcon from './images/close-icon.svg?react';
import OpenIcon from './images/open-icon.svg?react';
import { useState } from 'react';

import styles from './QuestionAccordion.module.scss';
import { useTranslation } from 'react-i18next';

interface IQuestionAccordionProps {
  title: string;
  description: string | JSX.Element;
  isAddedDescriptionShown?: boolean;
}

const QuestionAccordion = ({
  title,
  description,
  isAddedDescriptionShown,
}: IQuestionAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <li className={styles.questionAccordion}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={styles.questionAccordionButton}
      >
        <div className={styles.topLine}>
          <span className={styles.name}>{title}</span>
          {isOpen ? (
            <CloseIcon className={styles.icon} />
          ) : (
            <OpenIcon className={styles.icon} />
          )}
        </div>
      </button>
      {isOpen ? (
        <div className={styles.bottom}>
          <p className={styles.description}>
            {description}
            <br />
            {isAddedDescriptionShown ? t('if_error_stays') : ''}
          </p>
          <div className={styles.button}>
            <NoSolutionButton />
          </div>
        </div>
      ) : (
        ''
      )}
    </li>
  );
};

export default QuestionAccordion;
