import { useTranslation } from 'react-i18next';
import Dropdown from '~/components/Shared/Dropdown';
import Actions, { Action } from '~/ui/Actions';
import QrCodeIcon from './images/qr-code.svg?react';

const FoodAdminBoardActions = ({ onQrPress }: { onQrPress: () => void }) => {
  const { t } = useTranslation();

  return (
    <Dropdown caption={t('actions')}>
      {closeDropdown => (
        <Actions>
          <Action
            onClick={() => {
              onQrPress();
              closeDropdown();
            }}
            variant="success"
          >
            <QrCodeIcon />
            <span>{t('scan_qr')}</span>
          </Action>
        </Actions>
      )}
    </Dropdown>
  );
};

export default FoodAdminBoardActions;
