import { Select } from 'antd';
import styles from './TableSelect.module.scss';
import '~/assets/css/select-table.css';
import { useTranslation } from 'react-i18next';

const PER_PAGE = [5, 10, 15];

export const TableSelect = ({ perPage, setPerPage }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.tableSelect}>
      <span className={styles.showText}>{t('show')}</span>
      <div className="tableSelectWrapper">
        <Select
          defaultValue={5}
          value={perPage}
          onChange={value => {
            setPerPage(Number(value));
          }}
        >
          {PER_PAGE.map(perPage => (
            <Select.Option
              key={perPage}
              value={perPage}
              className={styles.selectOption}
            >
              {perPage}
            </Select.Option>
          ))}
        </Select>
      </div>

      <span className={styles.noticeText}>{t('entries')}</span>
    </div>
  );
};
