import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import { ButtonLink, XlsBtn } from '~/ui';
import { ActualBoard } from './ActualSocial/ActualBoard';
import { ArchiveBoard } from './ArchiveSocial/ArchiveBoard';
import * as Tabs from '@radix-ui/react-tabs';
import cn from 'classnames';
import styles from './Social.module.scss';

const Social = (): JSX.Element => {
  const navigate = useNavigate();
  const initialTab = new URLSearchParams(location.search).get('tab');
  const [activeTab, setActiveTab] = useState(initialTab || 'actual');

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/social/${value}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    setActiveTab(tab || 'actual');
  }, [location.search]);

  return (
    <Page
      heading={
        <Heading
          text="Соцопрос"
          actions={
            <>
              <ButtonLink text="Создать новый опрос" to="/social/create" />
              <XlsBtn xlsBtnText="Выгрузить XLS" onClick={() => ''} />
            </>
          }
        />
      }
    >
      <Tabs.Root
        className={styles.tabsRoot}
        value={activeTab}
        onValueChange={handleTabChange}
      >
        <div className={styles.tabsWrapper}>
          <Tabs.List
            className={styles.tabsList}
            aria-label="Manage your account"
          >
            <Tabs.Trigger
              value="actual"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'actual',
              })}
            >
              Актуальные
            </Tabs.Trigger>
            <Tabs.Trigger
              value="archive"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'archive',
              })}
            >
              Архив
            </Tabs.Trigger>
          </Tabs.List>
        </div>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="actual"
        >
          <ActualBoard />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="archive"
        >
          <ArchiveBoard />
        </Tabs.Content>
      </Tabs.Root>
    </Page>
  );
};

export default Social;
