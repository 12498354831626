import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './ButtonLinks.module.scss';

interface ButtonLinkProps {
  text: string;
  onClick?: () => void;
  to: string;
  className?: string;
  color?: string;
}

export const ButtonLink = ({
  text,
  onClick,
  to,
  className,
  color,
}: ButtonLinkProps) => {
  return (
    <Link
      to={to}
      className={cn(
        styles.buttonLink,
        className,
        styles[`buttonLink--${color}`]
      )}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};
