import { ReactNode, useContext } from 'react';
import UpSvg from '~/assets/svg/newSvg/up.svg?react';
import DownSvg from '~/assets/svg/newSvg/down.svg?react';
import { TableContext } from '.';
import { IconButton } from '..';
import cn from 'classnames';
import styles from './Table.module.scss';

interface TableCellHeadProps {
  children: ReactNode;
  field: string;
  sortable?: boolean;
  className?: string;
}

export const TableCellHead = ({
  children,
  field,
  sortable = true,
  className,
}: TableCellHeadProps): JSX.Element => {
  const { sort, onSortChange } = useContext(TableContext);

  function onClick() {
    if (sortable === false) return;
    let direction = 'asc';
    if (sort.field === field) {
      if (sort.direction === 'asc') {
        direction = 'desc';
      } else if (sort.direction === 'desc') {
        direction = '';
        field = '';
      }
    }
    onSortChange(field, direction);
  }

  return (
    <th className={cn(styles.tableCellHead, className)}>
      <div className={styles.tableCellHeadContainer}>
        <div
          onClick={onClick}
          className={cn(
            styles.tableCellHeadTrigger,
            sort.field === field && styles['tableCellHeadTrigger--active']
          )}
        >
          <h4 className={styles.tableCellHeadHeading}>{children}</h4>
          {sortable && (
            <div className={styles.tableCellHeadBtns}>
              <IconButton
                className={cn(styles.sortBtn, {
                  [styles['sortBtnHide']]:
                    sort.direction === 'desc' && sort.field === field,
                })}
              >
                <UpSvg />
              </IconButton>
              <IconButton
                className={cn(styles.sortBtn, {
                  [styles['sortBtnHide']]:
                    sort.direction === 'asc' && sort.field === field,
                })}
              >
                <DownSvg />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </th>
  );
};
