import BadImage from '../images/bad.png';
import OkayImage from '../images/okay.png';
import GoodImage from '../images/good.png';
import SuperImage from '../images/super.png';
import { InputBar } from '~/ui';
import styles from './AnswerEstimate.module.scss';
import cn from 'classnames';

interface IAnswerEstimateProps {
  questionId: number;
  name: string;
  required: boolean;
  value: string | number | number[];
  answer?: string;
  comment?: string;
  onChange: (value: any) => void;
  onChangeComment?: (comment: any) => void;
}

const AnswerEstimate = ({
  name,
  required,
  questionId,
  value,
  comment = '',
  onChange,
  onChangeComment,
}: IAnswerEstimateProps) => {
  return (
    <div className={styles.estimate}>
      <span className={styles.answerTitle}>
        {name}
        {required && <span className={styles.requiredStar}>*</span>}
      </span>
      <fieldset
        id={`estimate-${questionId}`}
        className={styles.estimateAnswers}
      >
        <div className={styles.estimateSelect}>
          <input
            id={`Плохо-${questionId}`}
            className={styles.estimateSelectInput}
            name={`estimate-${questionId}`}
            type="radio"
            value={1}
            checked={Number(value) === 1}
            onChange={onChange}
            required={required}
          />
          <label
            className={cn(styles.estimateLabel, styles.estimateLabelBad)}
            htmlFor={`Плохо-${questionId}`}
          >
            <img src={BadImage} />
          </label>
          <span className={cn(styles.description, styles.descriptionBad)}>
            Плохо
          </span>
        </div>
        <div className={styles.estimateSelect}>
          <input
            id={`Неплохо-${questionId}`}
            className={styles.estimateSelectInput}
            name={`estimate-${questionId}`}
            type="radio"
            value={2}
            checked={Number(value) === 2}
            onChange={onChange}
            required={required}
          />
          <label
            className={cn(styles.estimateLabel, styles.estimateLabelOkay)}
            htmlFor={`Неплохо-${questionId}`}
          >
            <img src={OkayImage} />
          </label>
          <span className={cn(styles.description, styles.descriptionOkay)}>
            Неплохо
          </span>
        </div>
        <div className={styles.estimateSelect}>
          <input
            id={`Хорошо-${questionId}`}
            className={styles.estimateSelectInput}
            name={`estimate-${questionId}`}
            type="radio"
            value={3}
            checked={Number(value) === 3}
            onChange={onChange}
            required={required}
          />
          <label
            className={cn(styles.estimateLabel, styles.estimateLabelGood)}
            htmlFor={`Хорошо-${questionId}`}
          >
            <img src={GoodImage} />
          </label>
          <span className={cn(styles.description, styles.descriptionGood)}>
            Хорошо
          </span>
        </div>
        <div className={styles.estimateSelect}>
          <input
            id={`Супер-${questionId}`}
            className={styles.estimateSelectInput}
            name={`estimate-${questionId}`}
            type="radio"
            value={4}
            checked={Number(value) === 4}
            onChange={onChange}
            required={required}
          />
          <label
            defaultChecked
            className={cn(styles.estimateLabel, styles.estimateLabelSuper)}
            htmlFor={`Супер-${questionId}`}
          >
            <img src={SuperImage} />
          </label>
          <span className={cn(styles.description, styles.descriptionSuper)}>
            Супер
          </span>
        </div>
      </fieldset>
      <InputBar
        classNameInput={styles.openInput}
        onChange={onChangeComment}
        value={comment}
        label=""
        placeholder="Расскажите, что нам нужно улучшить"
      />
    </div>
  );
};

export default AnswerEstimate;
