import LoaderText from '~/components/Shared/LoaderText/LoaderText';
import styles from './Notifications.module.scss';
import { memo } from 'react';

const Notifications = ({ count }: { count: number }) => {
  if (!count) {
    return;
  }
  return (
    <div
      style={
        String(count).length === 1
          ? { padding: '2px 8px' }
          : { padding: '2px 5px' }
      }
      className={styles.notifications}
    >
      <span className={styles.notificationsCount}>
        <LoaderText size={13} isLoading={!count} text="22">
          {count}
        </LoaderText>
      </span>
    </div>
  );
};

export default memo(Notifications);
