export const deleteLocalPoints = () => {
  const points = [
    'accommondation-points',
    'announcement-points',
    'dishes-points',
    'feedbacks-points',
    'food-points',
    'qr-points',
    'notification-points',
    'requests-points',
    'social-points',
    'transactions-points',
    'users-points',
  ];

  points.forEach(point => {
    localStorage.removeItem(point);
  });
};
