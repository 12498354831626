import { useEffect, useRef, useState } from 'react';
import BurgerIcon from '~/assets/svg/newSvg/burger.svg?react';
import useOutsideClick from '~/utils/hooks/useOutsideClick';
import s from './Dropdown.module.scss';

interface DropdownProps {
  children: React.ReactNode | ((close: () => void) => JSX.Element);
  caption: string;
}

const Dropdown = (props: DropdownProps) => {
  const { children, caption } = props;
  const ref = useRef<HTMLDivElement>(null!);
  const [isOpen, setOpen] = useState(false);

  const close = () => setOpen(false);
  const toggle = () => setOpen(v => !v);

  useOutsideClick(ref, close);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => e.key === 'Escape' && close();
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className={s.container} ref={ref}>
      <button type="button" onClick={toggle} className={s.caption}>
        <BurgerIcon />
        {caption}
      </button>
        {isOpen && (
          <div className={s.content}>
            {typeof children === 'function' ? children(close) : children}
          </div>
        )}
    </div>
  );
};

export default Dropdown;
