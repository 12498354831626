import { FormikHandlers } from 'formik';
import { ChangeEvent } from 'react';
import cn from 'classnames';
import styles from './InputBar.module.scss';

interface InputBarProps {
  name?: string;
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  label: string;
  color?: string;
  star?: boolean;
  placeholder?: string;
  classNameInput?: string;
  classNameInputWrapper?: string;
  errors?: string;
  error?: boolean;
  onBlur?: FormikHandlers['handleBlur'];
  required?: boolean;
  type?: 'text' | 'numeric';
}

export const InputBar = (props: InputBarProps) => {
  const {
    errors,
    error,
    onBlur,
    name,
    value,
    onChange,
    disabled,
    label,
    color,
    star,
    placeholder,
    classNameInput,
    classNameInputWrapper,
    required = false,
    type = 'text',
  } = props;

  return (
    <div className={cn(styles.input__wrapper, classNameInputWrapper)}>
      <label className={styles.input__label} htmlFor={name}>
        <span className={styles.input__text}>
          {label}
          {star ? <span className={styles.input__star}>*</span> : null}
        </span>
        <input
          name={name}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          className={cn(
            styles.form__input,
            classNameInput,
            {
              [styles['input__errors']]: error,
            },
            styles[`form__input--${color}`]
          )}
          id={name}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          required={required}
        />

        {error ? <div className={styles.errors__text}>{errors}</div> : null}
      </label>
    </div>
  );
};
