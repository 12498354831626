import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import Dropdown from '~/components/Shared/Dropdown';
import Actions, { Action } from '~/ui/Actions';
import DownloadIcon from '~/assets/svg/newSvg/actions/download.svg?react';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import AnalyticIcon from '~/assets/svg/newSvg/actions/analytic.svg?react';
import { Modal } from '~/components/Shared/Modal/Modal';
import { XlsUserModalContent } from '~/components/XlsUserPopoverContent/XlsUserModalContent.module';
import XlsUserUploadForm from './XlsUserUploadForm/XlsUserUploadForm';
import { downloadReportFile } from '~/utils/downloadReportFile';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { downloadFile } from '~/utils/downloadFile';
import LoaderSpinner from '~/components/Shared/LoaderSpinner/LoaderSpinner';
import styles from './UserBoardActions.module.scss';
import { useToastError } from '~/utils/useToastError';
import UploadUsers from '../UploadUsers/UploadUsers';

type ModalName = 'download' | 'analytics' | 'upload';

const UserBoardActions = () => {
  const { user } = useContext(AuthenticationContext);
  const [activeModal, setActiveModal] = useState<ModalName>();
  const [isLoading, setIsLoading] = useState<{
    isDownloading: boolean;
    isUploading: boolean;
  }>({
    isDownloading: false,
    isUploading: false,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastError = useToastError();

  const closeModal = () => setActiveModal(undefined);

  const downloadFileReport = async () => {
    let typeReportPath: string;
    let typeReportName: string;

    typeReportPath = 'analytics-user';
    typeReportName = 'analytics-user';

    downloadReportFile(typeReportPath, typeReportName, null, null);
  };

  const downloadUsers = useMutation(
    ['downloadUsers'],
    async () => {
      setIsLoading({ ...isLoading, isDownloading: true });
      const response = await instance.get(
        `golang-download-users?user_id=${user?.data.id}`,
        {
          responseType: 'blob',
        }
      );
      return response.data;
    },
    {
      onSuccess: data => {
        downloadFile(data, 'text/xlsx', 'users.xlsx');
        setIsLoading({ ...isLoading, isDownloading: false });
      },

      onError: error => {
        setIsLoading({ ...isLoading, isDownloading: false });
        toastError(error);
      },
    }
  );

  const handleDownload = () => {
    downloadUsers.mutate();
  };

  return (
    <>
      <Dropdown caption={t('actions')}>
        {closeDropdown => (
          <Actions>
            <Action onClick={handleDownload}>
              <div className={styles.actionWrapper}>
                {isLoading.isDownloading && (
                  <div className={styles.loaderWrapper}>
                    <LoaderSpinner />
                  </div>
                )}
                <div
                  style={isLoading.isDownloading ? { opacity: 0 } : {}}
                  className={styles.text}
                >
                  <DownloadIcon /> {t('download')} XLS
                </div>
              </div>
            </Action>
            <Action>
              <UploadUsers />
            </Action>
            <Action
              variant="success"
              onClick={() => {
                closeDropdown();
                navigate('/user/create');
              }}
            >
              <AddIcon /> {t('create_employee')}
            </Action>
            <Action
              variant="primary"
              onClick={() => {
                downloadFileReport();
                closeDropdown();
              }}
            >
              <AnalyticIcon /> {t('employee_analytics')}
            </Action>
          </Actions>
        )}
      </Dropdown>
      <Modal isOpen={activeModal === 'download'} onClose={closeModal}>
        <XlsUserModalContent onClose={closeModal} />
      </Modal>
      <Modal isOpen={activeModal === 'upload'} onClose={closeModal}>
        <XlsUserUploadForm close={closeModal} />
      </Modal>
    </>
  );
};

export default UserBoardActions;
