import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import ArrowLeftSvg from '~/assets/svg/newSvg/arrow-left.svg?react';
import { FeedbackCommentForm } from './FeedbackCommentForm/FeedbackCommentForm';
import Page from '~/components/Shared/Layout/Page/Page';
import { IconButtonLink } from '~/ui';
import Heading from '~/components/Shared/Heading/Heading';
import styles from './FeedbackComment.module.scss';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { CommentList } from '~/components/CommentList/CommentList';

interface User {
  id: number;
  full_name: string;
  role_name: string;
}

interface Comment {
  id: number;
  description: string;
  created_at: string;
  images: {
    id: number;
    name: string;
    full_photo_url: string;
  }[];
}

interface FeedbackItem {
  feedback: {
    name: string;
    feedback_comments: Comment[];
    formatted_created_at: string;
  };
}

const FeedbackComment = (): JSX.Element => {
  const { feedbackID } = useParams();
  const { t } = useTranslation();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const client = useQueryClient();

  const { data, isError, isLoading } = useQuery<FeedbackItem>({
    queryFn: async () => {
      const response = await instance.get(`/admin/feedback/${feedbackID}`);
      return response.data;
    },
    onError: error => {
      toastError(error);
    },
    queryKey: ['feedback', feedbackID],
  });

  const feedback = data?.feedback;
  const comments = data?.feedback.feedback_comments;

  const { data: user } = useQuery<User>({
    queryFn: async () => {
      const response = await instance.get('admin/get-user-auth');
      return response.data;
    },
    queryKey: ['user-auth'],
  });

  const { mutate: deleteComment } = useMutation(
    async (id: number) => {
      const response = await instance.delete(`feedback-comment/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['feedback', feedbackID]);
        toastSuccess(t('comment_deleted'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={`${t('response')} ${feedback?.formatted_created_at}`}
          classNameText={styles.commentHeading}
          arrowBtn={
            <IconButtonLink
              className={styles.leftBtn}
              to={`/feedback/${feedbackID}`}
            >
              <ArrowLeftSvg />
            </IconButtonLink>
          }
        />
      }
      isError={isError}
      isLoading={isLoading}
    >
      <div className={styles.commentComments}>
        {user && comments && (
          <CommentList
            comments={comments}
            user={user}
            onClick={deleteComment}
          />
        )}
      </div>
      <FeedbackCommentForm />
    </Page>
  );
};

export default FeedbackComment;
