import {
  nameValidate,
  telValidate,
  emailValidate,
  phraseValidate,
} from './regex';
import i18next from 'i18next';

const innRegex = /^\d{10}(?:\d{2})?$/;

export const validate = values => {
  const errors = {};

  // name

  if (!values.name) {
    errors.name = i18next.t('name_validate');
  } else if (values.name.length < 2) {
    errors.name = i18next.t('name_length_validate');
  } else if (values.name.length >= 32) {
    errors.name = i18next.t('name_length_max_validate');
  }

  // Description

  if (!values.description) {
    errors.description = i18next.t('description_validate');
  } else if (values.description.length < 2) {
    errors.description = i18next.t('description_length_validate');
  }

  // INN

  if (!values.inn) {
    errors.inn = i18next.t('validate_tin');
  } else if (innRegex.test(values.inn) === false) {
    errors.inn = i18next.t('tin_length_validate');
  }

  // Address

  if (!values.address) {
    errors.address = i18next.t('address_validate');
  } else if (values.address.length < 2) {
    errors.address = i18next.t('address_length_validate');
  }

  return errors;
};
