import styles from './ContainerBlock.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  isWithContainer: boolean;
}

const ContainerBlock = ({ isWithContainer }: Props) => {
  const { t } = useTranslation();

  return (
    <label className={styles.containerBlock}>
      <span className={styles.labelText}>{t('container')}</span>
      <span className={styles.name}>
        {isWithContainer ? t('yes') : t('no')}
      </span>
    </label>
  );
};

export default ContainerBlock;
