import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Table.module.scss';

interface TableCellProps {
  children: ReactNode;
  hidden?: boolean;
  className?: string;
}

export const TableCell = ({
  children,
  hidden,
  className,
}: TableCellProps): JSX.Element => {
  return (
    <td
      className={cn(
        styles.tableCell,
        hidden && styles.tableCellHidden,
        className
      )}
    >
      {children}
    </td>
  );
};
