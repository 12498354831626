import { emailValidate } from './regex';
import i18next from 'i18next';

export const validate = values => {
  const errors = {};

  // password

  if (!values.password) {
    errors.password = i18next.t('password_validate');
  }

  // Email

  if (!values.email) {
    errors.email = i18next.t('email_validate');
  } else if (!emailValidate(values.email)) {
    errors.email = i18next.t('email_correct_validate');
  }

  return errors;
};
