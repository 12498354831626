import cn from 'classnames';
import styles from './AnnouncementsBoard/AnnouncementsTable/AnnouncementsTable.module.scss';
import i18next from 'i18next';

export function renderStatus(status_id: number) {
  const t = i18next.t;

  if (status_id === 2) {
    return (
      <span className={cn(styles.status, styles.statusGreen)}>
        {t('status_sent')}
      </span>
    );
  } else if (status_id === 1) {
    return (
      <span className={cn(styles.status, styles.statusOrange)}>
        {t('status_awaiting')}
      </span>
    );
  } else if (status_id === 3) {
    return (
      <span className={cn(styles.status, styles.statusRed)}>
        {t('status_archived')}
      </span>
    );
  } else {
    return null;
  }
}
