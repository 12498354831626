import BadSmile from './images/bad.png';
import OkaySmile from './images/okay.png';
import GoodSmile from './images/good.png';
import SuperSmile from './images/super.png';
import i18next from 'i18next';

export const getEstimateTypes = () => {
  const t = i18next.t;
  return {
    1: {
      name: t('quality_bad'),
      image: BadSmile,
      color: '#ed424233',
    },
    2: {
      name: t('quality_okay'),
      image: OkaySmile,
      color: '#dcedf5',
    },
    3: {
      name: t('quality_good'),
      image: GoodSmile,
      color: '#e3e1fc',
    },
    4: {
      name: t('quality_super'),
      image: SuperSmile,
      color: '#1cbe6333',
    },
  };
};
