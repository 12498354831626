import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './PaymentStatus.module.scss';

interface IPaymentStatusProps {
  status: string;
}

const PaymentStatus = ({ status }: IPaymentStatusProps) => {
  const { t } = useTranslation();

  if (status === 'succeeded') {
    return (
      <span className={cn(styles.status, styles.success)}>
        {t('payment_success')}
      </span>
    );
  }

  if (status === 'pending') {
    return (
      <span className={cn(styles.status, styles.pending)}>
        {t('payment_pending')}
      </span>
    );
  }

  if (status === 'canceled') {
    return (
      <span className={cn(styles.status, styles.canceled)}>
        {t('payment_failed')}
      </span>
    );
  }

  if (status === 'refunded') {
    return (
      <span className={cn(styles.status, styles.refunded)}>
        {t('payment_refunded')}
      </span>
    );
  }
};

export default PaymentStatus;
