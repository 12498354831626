import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './OrdersTable.module.scss';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { renderStatus } from '~/pages/Transactions/utils';

export interface Order {
  id: number;
  user_name: string;
  user_id: number;
  created_at: string;
  status_title: string;
  total_sum: string;
  food_name: string;
  delivery_date: string;
}

interface Props {
  orders: Order[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
}

export const OrdersTable = ({ orders, sort, setSort }: Props): JSX.Element => {
  const { foodID } = useParams();
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="user_name">{t('employee')}</TableCellHead>
        <TableCellHead field="user_id">{t('employee_id')}</TableCellHead>
        <TableCellHead field="id">{t('order_number')}</TableCellHead>
        <TableCellHead field="created_at">{t('order_date')}</TableCellHead>
        <TableCellHead field="delivery_date">
          {t('delivery_date')}
        </TableCellHead>
        <TableCellHead field="status">{t('status')}</TableCellHead>
        <TableCellHead field="total_sum">{t('order_price')}</TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {orders.map(
          ({
            id,
            user_name,
            user_id,
            created_at,
            status_title,
            total_sum,
            delivery_date,
          }: Order) => (
            <TableRow key={id}>
              <TableCell>{user_name}</TableCell>
              <TableCell>{user_id}</TableCell>
              <TableCell>#{id}</TableCell>
              <TableCell>{dayjs(created_at).format('DD.MM.YYYY')}</TableCell>
              <TableCell>{delivery_date}</TableCell>
              <TableCell>{renderStatus(status_title)}</TableCell>
              <TableCell>{total_sum} &#8381;</TableCell>
              <TableCell>
                <div className={styles.actions}>
                  <IconButtonLink
                    to={`/food/${foodID}/orders/${id}`}
                    className={styles.btnLink}
                  >
                    <Edit />
                  </IconButtonLink>
                </div>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
