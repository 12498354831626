import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToastError } from '~/utils/useToastError';
import { instance } from '~/utils/api/api';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { FullMenuTable } from './FullMenuTable';
import { GrayBanner } from '~/ui/GrayBanner/GrayBanner';
import { Button, InputBar, Pagination, Spinner } from '~/ui';
import '~/assets/css/select-table.css';
import styles from './FullMenuBoard.module.scss';
import { useTranslation } from 'react-i18next';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';

interface SelectedPage {
  selected: number;
}

interface FoodType {
  id: number;
  name: string;
}

interface FullMenuBoardProps {
  foodId: number;
  foodPointType: number;
  selectedDate: string;
  onClose: () => void;
}

export const FullMenuBoard = ({
  foodId,
  selectedDate,
  onClose,
  foodPointType,
}: FullMenuBoardProps): JSX.Element => {
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedWares, setSelectedWares] = useState<number[]>([]);
  const [foodType, setFoodType] = useState<FoodType>({
    id: 0,
    name: '',
  });
  const toastError = useToastError();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { t } = useTranslation();

  const params = {
    sort: sort.direction,
    perPage: 5,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    ...(foodPointType === 1 ? { date: selectedDate } : {}),
  };

  const client = useQueryClient();

  const {
    data: menu,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/ifcm-wares-all', {
        params,
      });
      return response.data;
    },

    queryKey: ['ifcm-wares-all', foodId, params],
    keepPreviousData: true,
  });

  const { data: foodTypes } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`food-type?food_id=${foodId}`);
      setFoodType({
        id: response?.data?.data[0].id,
        name: response?.data?.data[0].name,
      });
      return response.data;
    },
    queryKey: ['food-types'],
    onError: error => {
      toastError(error);
    },
  });

  const handleClose = () => {
    setSelectedWares([]);
    setFoodType({
      id: foodTypes?.data[0].id,
      name: foodTypes?.data[0].name,
    });
    onClose();
  };

  const { mutate: createCurrentMenu } = useMutation(
    async () => {
      const response = await instance.post('admin/food-wares', {
        wares_id: selectedWares,
        food_id: foodId,
        ...(foodPointType === 1 ? { date: selectedDate } : {}),
        visibility: 1,
        food_types_ids: [foodType.id],
      });
      return response.data;
    },
    {
      onSuccess() {
        client.invalidateQueries(['food-wares', foodId]);
        handleClose();
      },
      onError(error) {
        toastError(error);
      },
    }
  );

  let content: React.ReactNode;

  if (menu?.data && foodId) {
    content = (
      <FullMenuTable
        menu={menu?.data}
        sort={sort}
        setSort={setSort}
        selectedWares={selectedWares}
        setSelectedWares={setSelectedWares}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board classNames={styles.board}>
      <div className={styles.boardTop}>
        <h3 className={styles.heading}>{t('add_dish')}</h3>
        <Button
          onClick={handleClose}
          text={t('close')}
          color="red"
          disabled={false}
          className={styles.closeBtn}
        />
      </div>

      <GrayBanner
        text={t('selected_dishes_displayed_one_service')}
        className={styles.fullMenuBanner}
      />

      <div className={styles.options}>
        <InputBar
          onChange={e => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={t('search_by_name')}
          classNameInput={styles.input}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.inputWrapper}
        />

        <div className={styles.foodTypeSelect}>
          {foodTypes?.data && (
            <CustomSelect
              label=""
              placeholder={t('choose')}
              options={foodTypes?.data}
              value={foodType}
              onChange={value => {
                if (!Array.isArray(value) && typeof value.name === 'string') {
                  setFoodType({ id: Number(value.id), name: value.name });
                }
              }}
            />
          )}
        </div>
      </div>
      {content}
      <div className={styles.pagination}>
        <TableAmountPage
          firstRow={menu?.meta?.from}
          lastRow={menu?.meta?.to}
          total={menu?.meta?.total}
        />
        <Pagination
          pageCount={menu?.meta?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
      <div className={styles.boardBottom}>
        <Button
          onClick={() => createCurrentMenu()}
          text={t('save_n_exit')}
          className={styles.saveBtn}
          disabled={!(selectedWares.length && foodType)}
        />
      </div>
    </Board>
  );
};
