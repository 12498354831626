import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { ButtonLink } from '~/ui';
import { useTranslation } from 'react-i18next';
import { QrBoard } from './QrBoard/QrBoard';

const GeneratorQr = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('qr')}
          actions={
            <>
              <ButtonLink text={t('qr_code_create')} to="/qr/create" />
            </>
          }
        />
      }
    >
      <QrBoard />
    </Page>
  );
};

export default GeneratorQr;
