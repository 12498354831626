import i18next from 'i18next';

export interface ModuleItem {
  id: number;
  name: string;
}

export const getModules = () => {
  return [{ id: 1, name: i18next.t('accommodations_multi') }];
};

export const getCheckIn = () => {
  return [
    { id: 1, name: i18next.t('day_before') },
    { id: 2, name: i18next.t('two_days_before') },
    { id: 3, name: i18next.t('three_days_before') },
    { id: 4, name: i18next.t('four_days_before') },
    { id: 5, name: i18next.t('five_days_before') },
    { id: 6, name: i18next.t('six_days_before') },
    { id: 7, name: i18next.t('week_before') },
  ];
};

export const getCheckOut = () => {
  return [
    { id: 1, name: i18next.t('day_after') },
    { id: 2, name: i18next.t('two_days_after') },
    { id: 3, name: i18next.t('three_days_after') },
    { id: 4, name: i18next.t('four_days_after') },
    { id: 5, name: i18next.t('five_days_after') },
    { id: 6, name: i18next.t('six_days_after') },
    { id: 7, name: i18next.t('week_after') },
  ];
};
