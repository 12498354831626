import { useMutation } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useNavigate } from 'react-router-dom';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import CreateQrFrom, { CreateQrValues } from './CreateQrForm/CreateQrForm';
import { downloadFile } from '~/utils/downloadFile';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';

const CreateQr = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const { mutateAsync: createTypeFood } = useMutation(
    async ({ object, module }: CreateQrValues) => {
      const qrObject = {
        name: module.name,
        qr_code_module_id: String(module.id),
        ...(object.id ? { object_id: object.id } : {}),
      };

      const response = await instance.post(
        '/admin/qr-generate-object',
        qrObject
      );

      const fileQr = await instance.get(
        `admin/qr-download/${response.data.id}`,
        { responseType: 'blob' }
      );
      return fileQr;
    },
    {
      onSuccess: fileQr => {
        downloadFile(fileQr.data, 'image/png', 'qr.png');
        navigate('/qr');
        toastSuccess(t('qr_generated'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page heading={<Heading text={t('qr_code_create')} />}>
      <CreateQrFrom
        onSubmit={values => createTypeFood(values)}
        storageKey="create-qr"
      />
    </Page>
  );
};

export default CreateQr;
