import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
}

export const IconButton = ({
  children,
  className,
  onClick,
  isDisabled = false,
  type = 'button',
}: IconButtonProps) => {
  return (
    <button
      className={cn(styles.iconButton, className)}
      onClick={onClick}
      type={type}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};
