import FadeLoader from 'react-spinners/FadeLoader';
import { CSSProperties } from 'react';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  width: '5px',
};

interface ISpinnerProps {
  height?: number;
  width?: number;
  radius?: number;
  margin?: number;
}

export const Spinner = ({
  height = 18,
  width = 4,
  radius = 3,
  margin = 2,
}: ISpinnerProps) => {
  return (
    <FadeLoader
      color={'#7367F0'}
      loading={true}
      cssOverride={override}
      height={height}
      width={width}
      radius={radius}
      margin={margin}
      speedMultiplier={1}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};
