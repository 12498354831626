import styles from './ClosedAnswerStats.module.scss';
import CheckboxIcon from '~/assets/svg/newSvg/options/checkbox.svg?react';
import cn from 'classnames';
import { pluralize } from '~/utils/helpers';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface IAnswer {
  count: number;
  percent: number;
  variant_name: string;
  variant_id: number;
}

export interface ICloseAnswer {
  question_id: number;
  question_name: string;
  question_type: string;
  count: number;
  answers: IAnswer[];
  required?: boolean;
  multi_answer?: boolean;
}

interface IOpenAnswerStats {
  answer: ICloseAnswer;
  isMultiStatHidden: boolean;
}

const pluralizeVariant = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('answer_plural'),
    i18next.t('answer_plural_2'),
    i18next.t('answer_plural_3'),
  ]);

const ClosedAnswerStats = ({ answer, isMultiStatHidden }: IOpenAnswerStats) => {
  const { t } = useTranslation();

  const sortEstimate = (a: IAnswer, b: IAnswer) => {
    if (a.count < b.count) {
      return 1;
    } else {
      return -1;
    }
  };
  return (
    <li className={styles.closeAnswerStats}>
      <div className={styles.topLine}>
        <div className={styles.topLineLeft}>
          <CheckboxIcon />
          <span className={styles.typeName}>{t('closed_question')}</span>
        </div>
        {(answer.required || answer.multi_answer) && (
          <ul className={styles.topLineQuestionPoints}>
            {answer.required && <li>{t('required_question')}</li>}
            {answer.multi_answer && <li>{t('multiple_answers_secondary')}</li>}
          </ul>
        )}
      </div>
      <span className={styles.name}>{answer?.question_name}</span>
      {isMultiStatHidden && (
        <span className={styles.amount}>{pluralizeVariant(answer?.count)}</span>
      )}
      <ul className={styles.answerList}>
        {answer.answers.sort(sortEstimate).map(item => {
          return (
            <li key={item.variant_id} className={cn(styles.line)}>
              <div
                style={{
                  width: `${Math.round(item.percent)}%`,
                }}
                className={styles.lineFilled}
              />
              <span className={styles.variantName}>{item.variant_name}</span>
              {isMultiStatHidden && (
                <span className={styles.personAmount}>
                  {item.count} {t('users_amount')}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default ClosedAnswerStats;
