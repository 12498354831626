import InfoIcon from '~/assets/svg/newSvg/info-icon.svg?react';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';

import styles from './TransactionDishesTable.module.scss';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { FoodWares } from '~/pages/Transaction/TransactionForm/TransactionForm';

interface ITransactionDishesTableProps {
  order: { data: FoodWares[] };
  selectedItems: number[];
  isAllCancelled: boolean;
  totalPrice: string;
  totalAmount: number;
  onCheckboxHeadPress: () => void;
  onCheckboxPress: (id: number) => void;
  isRowClickable?: boolean;
}

const TransactionDishesTable = ({
  order,
  totalPrice,
  totalAmount,
  selectedItems,
  isAllCancelled,
  isRowClickable,
  onCheckboxPress,
  onCheckboxHeadPress,
}: ITransactionDishesTableProps) => {
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const { t } = useTranslation();

  const getCancelledByTranslated = (user: string) => {
    if (user === 'user') {
      return t('by_user');
    }
    return t('by_admin');
  };

  return (
    <div
      className={styles.tableWrapper}
      style={
        isAllCancelled ? { backgroundColor: 'rgba(75, 70, 92, 0.08)' } : {}
      }
    >
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          {isAllCancelled ? (
            ''
          ) : (
            <TableCellHead
              className={styles.tableCellHead}
              sortable={false}
              field="checkbox"
            >
              {order?.data.filter(({ is_cancel }) => !is_cancel).length ? (
                <CustomCheckbox
                  isChecked={
                    selectedItems.length === order.data.length &&
                    !!order.data.length
                  }
                  onChange={onCheckboxHeadPress}
                />
              ) : (
                ''
              )}
            </TableCellHead>
          )}
          <TableCellHead sortable={false} field="name">
            {t('name')}
          </TableCellHead>
          <TableCellHead sortable={false} field="category">
            {t('category')}
          </TableCellHead>
          <TableCellHead sortable={false} field="weight">
            {t('measure')}
          </TableCellHead>
          <TableCellHead sortable={false} field="price">
            {t('price')}
          </TableCellHead>
          <TableCellHead sortable={false} field="amount">
            {t('quantity_full')}
          </TableCellHead>
          <TableCellHead sortable={false} field="sum">
            {t('total_amount')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {order?.data.map(
            ({
              id,
              wares,
              parent_code,
              qty,
              is_cancel,
              cancel_by,
            }: FoodWares) => {
              return (
                <TableRow
                  onClick={
                    isRowClickable ? () => onCheckboxPress(id) : undefined
                  }
                  style={is_cancel ? styles.transactionRow : ''}
                  key={id}
                >
                  {isAllCancelled ? (
                    ''
                  ) : (
                    <TableCell className={styles.checkboxCell}>
                      {!is_cancel ? (
                        <CustomCheckbox
                          isChecked={selectedItems.includes(id)}
                          onChange={() => onCheckboxPress(id)}
                        />
                      ) : (
                        <Tooltip
                          tooltipStyle={styles.tooltipStyle}
                          className={styles.transactionsTooltip}
                          position="right"
                          title={`${t(
                            'dish_cancelled_by'
                          )} ${getCancelledByTranslated(cancel_by)}`}
                        >
                          <InfoIcon
                            color="green"
                            className={styles.tooltipIcon}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  )}
                  <TableCell>{wares?.wares_name}</TableCell>
                  <TableCell>{parent_code}</TableCell>
                  <TableCell>{wares?.weight}</TableCell>
                  <TableCell>{wares?.price_list?.price}&#8381;</TableCell>
                  <TableCell>{qty}</TableCell>
                  <TableCell>
                    {(Number(wares?.price_list?.price) * qty)?.toFixed(2)}
                    &#8381;
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
        <TableRow>
          <TableCell>
            <span className={styles.boldText}>{t('in_total')}:</span>
          </TableCell>
          {isAllCancelled ? '' : <TableCell>&nbsp;</TableCell>}
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <span className={styles.boldText}>{totalAmount}</span>
          </TableCell>
          <TableCell>
            <span className={styles.boldText}>
              {totalPrice}
              &#8381;
            </span>
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
};

export default TransactionDishesTable;
