import { Button, BUTTON_STYLES, Pagination } from '~/ui';
import PhotoPicker from './PhotoPicker/PhotoPicker';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useState } from 'react';

import styles from './PhotoPickerContainer.module.scss';
import { useTranslation } from 'react-i18next';

interface SelectedPage {
  selected: number;
}

export interface IPhoto {
  id: number;
  full_photo_url: string;
  path: string;
}

interface IPhotoPickerContainerProps {
  initialPhotos: IPhoto[];
  selectedImages: IPhoto[];
  onImageChange: (image: IPhoto) => void;
  onCloseClick: () => void;
  onResetClick: () => void;
}

const PhotoPickerContainer = ({
  initialPhotos,
  selectedImages,
  onImageChange,
  onCloseClick,
  onResetClick,
}: IPhotoPickerContainerProps) => {
  const [page, setPage] = useState<number>();
  const { t } = useTranslation();

  const params = {
    page,
    perPage: 9,
  };

  const {
    data: photos,
    isFetching,
    isLoading,
  } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/announcement-images', {
        params,
      });
      return data;
    },
    queryKey: ['announcement-photos', params],
  });

  return (
    <div className={styles.photoPickerContainer}>
      <div className={styles.header}>
        <span className={styles.title}>{t('choose_photo')}</span>
        <div className={styles.buttons}>
          {JSON.stringify(initialPhotos) !== JSON.stringify(selectedImages) ? (
            <Button
              className={styles.closeButton}
              buttonStyle={BUTTON_STYLES.EMPTY}
              text={t('discard')}
              onClick={onResetClick}
              color="red"
            />
          ) : (
            ''
          )}
          <Button
            className={styles.closeButton}
            buttonStyle={BUTTON_STYLES.EMPTY}
            type="button"
            text={t('close')}
            onClick={onCloseClick}
          />
        </div>
      </div>
      <div className={styles.photoPickerWrapper}>
        <PhotoPicker
          photos={photos?.data}
          selectedImages={selectedImages}
          onImageClick={onImageChange}
          isFetching={isFetching || isLoading}
        />
      </div>
      <div className={styles.paginationContainer}>
        <Pagination
          pageCount={photos?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
      <div className={styles.controls}>
        <Button
          className={styles.confirmButton}
          text={t('confirm')}
          onClick={onCloseClick}
        />
      </div>
    </div>
  );
};

export default PhotoPickerContainer;
