import styles from './userButton.module.scss';
import { User } from './UserMenu';

interface UserButtonProps {
  user: User | undefined;
}

const UserButton: React.FC<UserButtonProps> = ({ user }) => {
  return (
    <div className={styles.userBtn}>
      {user && (
        <span className={styles.userBtnText}>
          {user?.name?.slice(0, 1)}
          {user?.patronymic?.slice(0, 1)}
        </span>
      )}
      <span className={styles.userIsOnline}></span>
    </div>
  );
};

export default UserButton;
