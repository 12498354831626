export interface IUserRole {
  id: number;
  created_at: string;
  updated_at: string;
  guard_name: string;
  name: string;
  rus_name: string;
}

export const USER_ROLES = {
  SUPER_ADMIN: 'super-admin',
  ASSET_ADMIN: 'asset-admin',
  FOOD_ADMIN: 'food-admin',
  ADMIN: 'admin',
  USER: 'user',
};

export const checkIfUserSuperAdmin = (userRole: string | undefined) =>
  userRole === USER_ROLES.SUPER_ADMIN;

export const checkIfUserAssetAdmin = (userRole: string | undefined) =>
  userRole === USER_ROLES.ASSET_ADMIN;

export const checkIfUserAdmin = (userRole: string | undefined) =>
  userRole === USER_ROLES.ADMIN;

export const checkIfUser = (userRole: string | undefined) =>
  userRole === USER_ROLES.USER;

export const checkIfFoodAdmin = (userRole: string | undefined) =>
  userRole === USER_ROLES.FOOD_ADMIN;
