import React, { useContext, useState } from 'react';
import { Button, InputBar } from '~/ui';
import styles from './EditAssetForm.module.scss';
import ColorSelect from '~/components/Shared/ColorSelect/ColorSelect';
import { IColorScheme } from '../EditAsset';
import { InputBarColor } from '~/ui/InputBarColor/InputBarColor';
import UploadPhoto from '~/components/Shared/UploadPhoto/UploadPhoto';
import CloseIcon from '~/assets/svg/newSvg/delete.svg?react';
import { FormikValues } from 'formik';
import { CLOSE_BUTTON_STYLE, Modal } from '~/components/Shared/Modal/Modal';
import PreviewPhone from '~/components/Shared/PreviewPhone/PreviewPhone';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import PhoneIcon from '~/assets/svg/newSvg/phone.svg?react';
import { useTranslation } from 'react-i18next';

interface ICreateAssetFormProps {
  colorSchemes: IColorScheme[];
  formik: FormikValues;
  isLoading: boolean;
}

const MAX_IMAGE_SIZE_MB = 2;

const EditAssetForm: React.FC<ICreateAssetFormProps> = ({
  formik,
  colorSchemes,
  isLoading,
}) => {
  const { user } = useContext(AuthenticationContext);
  const [photoLink, setPhotoLink] = useState<string | undefined>();
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const selectedColorObject = colorSchemes.find(
    colorScheme => colorScheme.id === formik.values.color_id
  );

  const handleChangePhoto = (value: FileList | null) => {
    if (value === null) {
      return;
    }
    if (value[0].size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
      formik.setErrors({ logo: t('exceeds_2_mb') });
      return;
    }
    setPhotoLink(URL.createObjectURL(value[0]));
    formik.setFieldValue('logo', value[0]);
  };

  const handleChangeColor = (id: number) => {
    formik.setFieldValue('color_id', id);
  };

  const removeLogo = () => {
    formik.setFieldValue('logo', null);
    setPhotoLink(undefined);
  };

  const removeUploadedLogo = () => {
    formik.setFieldValue('image_path', undefined);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.formItems}>
        <div className={styles.inputsLine}>
          <InputBar
            name="name"
            value={formik.values.name}
            disabled={isLoading}
            label={t('name')}
            placeholder={t('asset_name_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          <InputBar
            name="description"
            value={formik.values.description}
            disabled={isLoading}
            label={t('description')}
            placeholder={t('asset_description_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
          />
        </div>
        <div className={styles.inputsLine}>
          <InputBar
            name="inn"
            value={formik.values.inn}
            disabled={isLoading}
            label={t('tin')}
            placeholder={t('asset_inn_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.inn}
            error={formik.touched.inn && Boolean(formik.errors.inn)}
          />
          <InputBar
            name="address"
            value={formik.values.address}
            disabled={isLoading}
            label={t('address')}
            placeholder={t('asset_address_placeholder')}
            star
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.address}
            error={formik.touched.address && Boolean(formik.errors.address)}
          />
        </div>
        <InputBarColor label={t('color_palette')} star>
          <div className={styles.colorWrapper}>
            <ColorSelect
              name="color"
              options={colorSchemes}
              value={formik.values.color_id}
              onChange={handleChangeColor}
              checkColor={selectedColorObject?.text_color}
            />
            <button
              type="button"
              className={styles.previewButton}
              onClick={handleOpenPreview}
            >
              <PhoneIcon />
              {t('preview')}
            </button>
          </div>
        </InputBarColor>
        <div className={styles.uploadWrapper}>
          <span className={styles.uploadLabel}>{t('logo')}</span>
          {photoLink ? (
            <div className={styles.imageLogoWrapper}>
              <img
                className={styles.imageLogo}
                src={photoLink}
                alt="Uploaded logo"
              />
              <button
                type="button"
                onClick={removeLogo}
                className={styles.removeImageButton}
              >
                <CloseIcon />
              </button>
            </div>
          ) : (
            formik.values.image_path && (
              <div className={styles.imageLogoWrapper}>
                <img
                  className={styles.imageLogo}
                  src={formik.values.image_path}
                  alt="Uploaded logo"
                />
                <button
                  type="button"
                  onClick={removeUploadedLogo}
                  className={styles.removeImageButton}
                >
                  <CloseIcon />
                </button>
              </div>
            )
          )}
          {!isLoading && !photoLink && !formik.values.image_path && (
            <UploadPhoto
              onChange={handleChangePhoto}
              error={formik.errors.logo}
            />
          )}
          <span className={styles.imageUploadError}>{formik.errors.logo}</span>
        </div>
      </div>
      <Button
        type="submit"
        text={t('save')}
        className={styles.submitButton}
        disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
      />
      <Modal
        modalStyles={styles.previewModal}
        isOpen={isPreviewOpen}
        onClose={handleClosePreview}
        isCloseButtonShown
        closeButtonStyle={CLOSE_BUTTON_STYLE.SIMPLE}
      >
        <PreviewPhone
          username={user?.data.full_name}
          color={selectedColorObject?.color}
          textColor={selectedColorObject?.text_color}
        >
          <div className={styles.colorPreviewContainer}>
            <ColorSelect
              name="color"
              options={colorSchemes}
              value={formik.values.color_id}
              onChange={handleChangeColor}
              checkColor={selectedColorObject?.text_color}
            />
          </div>
        </PreviewPhone>
      </Modal>
    </form>
  );
};

export default EditAssetForm;
