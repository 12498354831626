import { QuestionValues } from '~/components/QuestionForm';
import { getTypeOptions, types } from '~/components/QuestionForm/typeOptions';

export const getQuestionPayload = (
  values: QuestionValues,
  questionnaireId?: number,
  isQuestionEditing: boolean = false
) => {
  const typeOptions = getTypeOptions();
  const selectedTypeName = typeOptions.find(option => option.id === values.type)
    ?.typeName;

  switch (values.type) {
    case types.open:
      return {
        ...(values.id && { id: values.id }),
        name: values.name,
        ...(questionnaireId && { questionnaire_id: questionnaireId }),
        type: selectedTypeName,
        question_data: {
          question: values.name,
          required: values.required,
        },
      };
    case types.estimate:
      return {
        ...(values.id && { id: values.id }),
        name: values.name,
        ...(questionnaireId && { questionnaire_id: questionnaireId }),
        type: selectedTypeName,
        question_data: {
          question: values.name,
          required: values.required,
          multi_answer: values.multi_answer,
        },
      };
    case types.close:
      if (isQuestionEditing) {
        return {
          ...(values.id && { id: values.id }),
          name: values.name,
          ...(questionnaireId && { questionnaire_id: questionnaireId }),
          type: selectedTypeName,
          question_data: {
            question: values.name,
            required: values.required,
            multi_answer: values.multi_answer,
            variants: values.variants,
          },
        };
      } else {
        return {
          ...(values.id && { id: values.id }),
          name: values.name,
          ...(questionnaireId && { questionnaire_id: questionnaireId }),
          type: selectedTypeName,
          question_data: {
            question: values.name,
            required: values.required,
            multi_answer: values.multi_answer,
            question_close_variants: values.variants,
          },
        };
      }
  }
};
