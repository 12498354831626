import { Checkbox, DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { BUTTON_STYLES, Button, RadioInput } from '~/ui';
import styles from './XlsReportRequestsPopoverContent.module.scss';
import { useToastError } from '~/utils/useToastError';
import { useContext, useState } from 'react';
import { PopoverContext } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import {
  downloadMultipleAccommodationReportFile,
  downloadReportFile,
} from '~/utils/downloadReportFile';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';

interface IAccommodation {
  id: number;
  name: string;
}

const XlsReportRequestsPopoverContent = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [typeReport, setTypeReport] = useState('1');
  const [accommodations, setAccommodations] = useState<IAccommodation[]>([]);
  const [userID, setUserID] = useState<number | null>(null);
  const debouncedSearchTerm = useDebounce('', 500);
  const { setIsPopoverVisible } = useContext(PopoverContext);
  const [isAllAccommodationChecked, setIsAllAccommodationChecked] =
    useState<boolean>(false);
  const toastError = useToastError();
  const { t } = useTranslation();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const onClosePopup = () => {
    setStartDate(null);
    setEndDate(null);
    setIsPopoverVisible(false);
    setAccommodations([]);
    setUserID(null);
    setTypeReport('1');
  };

  const downloadFileReport = async () => {
    let typeReportPath: string;
    let typeReportName: string;

    if (typeReport === '1') {
      typeReportPath = `analytics-ticket-accommodation`;
      typeReportName = 'analytics-request-accommodation';
    } else if (typeReport === '2') {
      typeReportPath = `analytics-ticket-accommodation-date`;
      typeReportName = 'analytics-request-accommodation-details';
    } else if (typeReport === '3') {
      typeReportPath = `analytics-ticket-user-all/${userID}`;
      typeReportName = 'analytics-ticket-user';
    } else {
      return;
    }

    try {
      if (isAllAccommodationChecked) {
        downloadReportFile(
          typeReportPath,
          typeReportName,
          startDate,
          endDate,
          true
        );
      } else {
        downloadMultipleAccommodationReportFile(
          typeReportPath,
          typeReportName,
          accommodations.map(accommodation => accommodation.id),
          startDate,
          endDate
        );
      }
    } catch (error) {
      toastError(error);
    }
    onClosePopup();
  };

  const disabledSendReport =
    (!accommodations.length &&
      !isAllAccommodationChecked &&
      typeReport === '1') ||
    (!isAllAccommodationChecked && !accommodations.length) ||
    ((!startDate || !endDate) && typeReport === '2');

  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day');
  };

  let content: React.ReactNode;

  if (typeReport === '1') {
    content = (
      <div className={styles.selectAllAccommodations}>
        <label>
          <Checkbox
            value={isAllAccommodationChecked}
            onChange={event => {
              setIsAllAccommodationChecked(event.target.checked);
            }}
          />
          <span className={styles.checkoxLabel}>
            {t('all_accommodation_selection')}
          </span>
        </label>
      </div>
    );
  } else if (typeReport === '2') {
    content = (
      <>
        <div className={styles.selectAllAccommodations}>
          <label>
            <Checkbox
              value={isAllAccommodationChecked}
              onChange={event => {
                setIsAllAccommodationChecked(event.target.checked);
              }}
            />
            <span className={styles.checkoxLabel}>
              {t('all_accommodation_selection')}
            </span>
          </label>
        </div>
        <div className={styles.dateWrapper}>
          <div className={styles.labelWrapper}>
            <span className={styles.labelText}>
              {t('date')}
              <span className={styles.labelStar}>*</span>
            </span>
            <Space direction="vertical" className="ant-space">
              <DatePicker.RangePicker
                className="datepicker"
                format="DD.MM.YYYY"
                value={[startDate, endDate]}
                onChange={dates => {
                  if (dates) {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                  } else {
                    setStartDate(null);
                    setEndDate(null);
                  }
                }}
                placeholder={[t('date_from'), t('date_by')]}
                disabledDate={disabledDate}
              />
            </Space>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.formWrapper}>
      <span className={styles.formTitle}>{t('request_analytics')}</span>
      <div className={styles.radioBtnTypeReportWrapper}>
        <RadioInput
          label={t('brief_report')}
          name="type_report"
          value="1"
          onChange={() => setTypeReport('1')}
          checkValue={typeReport}
        />
        <RadioInput
          label={t('full_report')}
          name="type_report"
          value="2"
          onChange={() => setTypeReport('2')}
          checkValue={typeReport}
        />
      </div>

      <CustomQuerySelect
        label={t('object')}
        query="admin/accommodations"
        queryKey={['accommodations', params]}
        placeholder={t('accommodations_validate')}
        params={params}
        value={accommodations}
        onChange={value => {
          if (Array.isArray(value)) {
            setAccommodations(value);
          }
        }}
        required
        disabled={isAllAccommodationChecked}
        multiselect
        isSearchEnabled
      />
      <div className={styles.contentWrapper}>{content}</div>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onClosePopup}
          className={styles.contentBtn}
          text={t('cancel')}
          disabled={false}
          buttonStyle={BUTTON_STYLES.EMPTY}
        />
        <Button
          onClick={downloadFileReport}
          type="submit"
          text={t('download_report')}
          className={styles.sendBtn}
          disabled={disabledSendReport}
        />
      </div>
    </div>
  );
};

export default XlsReportRequestsPopoverContent;
