import styles from './TakeawayBlock.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  isInCafe: boolean;
}

const TakeawayBlock = ({ isInCafe }: Props) => {
  const { t } = useTranslation();

  return (
    <label className={styles.takeawayBlock}>
      <span className={styles.labelText}>{t('delivery')}</span>
      <span className={styles.name}>
        {isInCafe ? t('in_cafeteria') : t('takeaway')}
      </span>
    </label>
  );
};

export default TakeawayBlock;
