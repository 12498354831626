import { useTranslation } from 'react-i18next';
import QuestionAccordion from '../QuestionAccordion/QuestionAccordion';

import styles from './FaqPropose.module.scss';

const FaqPropose = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles.faqPropose}>
      <QuestionAccordion
        title={t('app_data_title')}
        description={<>{t('app_data_description')}.</>}
      />
      <QuestionAccordion
        title={t('app_hack_title')}
        description={t('app_hack_description')}
      />
      <QuestionAccordion
        title={t('app_protect_data')}
        description={t('app_protect_description')}
      />
    </ul>
  );
};

export default FaqPropose;
