export const socials = [
  {
    id: 0,
    category: 'Качество питания',
    place: 'Столовая #55',
    name: 'Оцените качество обслуживания столовой iFCM Group',
    status: '1',
  },
  {
    id: 1,
    category: 'Качество размещения',
    place: 'Общежитие #2',
    name: 'Оцените качество размещения iFCM Group',
    status: '2',
  },
  {
    id: 1,
    category: 'Качество размещения',
    place: 'Общежитие #2',
    name: 'Оцените качество размещения iFCM Group',
    status: '2',
  },
];
