import Arrow from '~/assets/svg/newSvg/arrow-select.svg?react';
import CloseSvg from '~/assets/svg/newSvg/close-purple.svg?react';
import cn from 'classnames';
import styles from './SelectedItems.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  textStyle?: string;
  getValueText: (id: number) => React.ReactNode;
  isOpen: boolean;
  selectedStyle?: string;
  placeholder?: string;
  disabled?: boolean;
} & (
  | {
      value: number | null;
      onChange: (selectedValue: number) => void;
      multiple?: false;
    }
  | {
      value: number[] | null;
      onChange: (selectedValue: number[]) => void;
      multiple: true;
    }
);

export const SelectedItems = ({
  getValueText,
  isOpen,
  textStyle,
  selectedStyle,
  disabled,
  placeholder = 'to_choose',
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        styles.selectBtnContent,
        disabled ? styles.selectBtnContentDisabled : null
      )}
    >
      {props.multiple ? (
        <>
          <ul className={cn(styles.multipleList, selectedStyle)}>
            {props.value?.map(id => (
              <li key={id} className={styles.selectBtnTextWrapper}>
                <span
                  className={cn(
                    styles.selectBtnText,
                    styles.selectMultipleText
                  )}
                >
                  {getValueText(id)}
                  {props.value?.length === 0 && (
                    <span
                      className={cn(
                        styles.selectBtnText,
                        styles.selectBtnTextPlaceholder
                      )}
                    >
                      {placeholder}
                    </span>
                  )}
                </span>
                <CloseSvg
                  onClick={e => {
                    e.stopPropagation();
                    props.onChange((props.value || []).filter(el => el !== id));
                  }}
                  className={styles.closeSvg}
                />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <span className={cn(styles.selectBtnText, textStyle)}>
          {(!props.multiple && props.value) || props.value === 0 ? (
            getValueText(props.value)
          ) : (
            <span
              className={cn(
                styles.selectBtnText,
                styles.selectBtnTextPlaceholder
              )}
            >
              {t('to_choose')}
            </span>
          )}
        </span>
      )}

      {props.multiple && !props.value?.length && (
        <span
          className={cn(styles.selectBtnText, styles.selectBtnTextPlaceholder)}
        >
          {t('to_choose')}
        </span>
      )}

      <Arrow
        className={cn(styles.selectBtnArrow, isOpen && styles.openedArrow)}
      />
    </div>
  );
};
