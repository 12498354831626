import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';

import styles from './CreateAnnouncement.module.scss';
import {
  AnnouncementForm,
  AnnouncementValues,
} from '~/components/AnnouncementForm';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';

const CreateAnnouncement = (): JSX.Element => {
  const navigate = useNavigate();
  const toastError = useToastError();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();

  const client = useQueryClient();

  const { mutateAsync: createAnnouncement } = useMutation(
    async ({
      module,
      status_id,
      name,
      text,
      start_date,
      end_date,
      photos,
      announcement_accommodations,
      announcement_organizations,
    }: AnnouncementValues) => {
      const response = await instance.postForm('admin/announcement', {
        module,
        status_id,
        name,
        text,
        start_date,
        end_date,
        image_ids: photos.map(photo => photo.id),
        accommodation_ids: announcement_accommodations.map(
          organization => organization.id
        ),
        organization_ids: announcement_organizations.map(
          organization => organization.id
        ),
      });

      return response.data;
    },
    {
      onSuccess: () => {
        toastSuccess(t('announcement_created'));
        client.invalidateQueries(['announcements']);
        navigate('/announcements');
      },
      onError: error => {
        toastError(error);
      },
    }
  );
  return (
    <Page
      heading={
        <Heading
          text={t('create_announcement')}
          classNameText={styles.createAnnouncementText}
        />
      }
    >
      <AnnouncementForm
        onSubmit={createAnnouncement}
        storageKey="create-announcement"
      />
    </Page>
  );
};

export default CreateAnnouncement;
