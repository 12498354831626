import QuestionnaireStats from './QuestionnaireStats/QuestionnaireStats';
import StatActions from './OrdersStats/StatActions/StatActions';
import DashboardBlock from './DashboardBlock/DashboardBlock';
import FeedbackStats, { IFeedbackStat } from './FeedbackStats/FeedbackStats';
import OrdersStats, { IOrderStats } from './OrdersStats/OrdersStats';
import ArrowLeft from './images/arrow-left.svg?react';
import ArrowRight from './images/arrow-right.svg?react';
import { instance } from '~/utils/api/api';
import { useEffect, useRef, useState } from 'react';
import TicketsStats, { ITicketStats } from './TicketsStats/TicketsStats';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Filters, { ICategory } from './Filters/Filters';
import RequestFilter from './RequestFilter/RequestFilter';
import { useToastError } from '~/utils/useToastError';

import styles from './Dashboard.module.scss';

interface IDashboardProps {
  startDate: string;
  endDate: string;
}

const Dashboard = ({ startDate, endDate }: IDashboardProps) => {
  const [orders, setOrders] = useState<IOrderStats>({
    all_count: 0,
    statuses: {
      created: 0,
      canceled: 0,
      success: 0,
    },
  });
  const [tickets, setTickets] = useState<ITicketStats>({
    all_count: 0,
    statuses: {
      created: 0,
      closed: 0,
      completed: 0,
      expired: 0,
      in_progress: 0,
    },
  });
  const [questionnaires, setQuestionnaires] = useState<any>([
    {
      name: 'Questionnaire',
      all_count: 0,
      answer_percent: 0,
      answer_count: 0,
    },
  ]);
  const [currentQuestionnaire, setCurrentQuestionnaire] = useState<number>(0);
  const [ticketCategories, setTicketCategories] = useState<ICategory[]>([]);
  const [feedback, setFeedback] = useState<IFeedbackStat[] | []>([]);
  const ordersRef = useRef<HTMLDivElement>(null!);
  const toastError = useToastError();
  const [isRequestFilterShown, setIsRequestFilterShown] =
    useState<boolean>(false);
  const [isTicketFilterShown, setIsTicketFilterShown] =
    useState<boolean>(false);
  const [ticketsFilters, setTicketsFilters] = useState({
    isDateEnabled: false,
    isAllObjectsInfoEnabled: false,
    isFavoriteObjectsEnabled: false,
    isCriticalityEnabled: false,
    isCategoriesEnabled: false,
    categories: [],
    criticality: {
      high: false,
      medium: false,
      low: false,
    },
  });
  const [requestFilters, setRequestFilters] = useState({
    isCategoryEnabled: false,
    categories: {
      breakfast: false,
      lunch: false,
      dinner: false,
    },
  });
  const { t } = useTranslation();

  const loadOrders = async () => {
    try {
      const { data } = await instance.get(
        `admin/dashboard/orders?time[]=${startDate}&time[]=${endDate}${
          requestFilters.categories.breakfast ? '&types[]=Завтрак' : ''
        }${requestFilters.categories.lunch ? '&types[]=Обед' : ''}${
          requestFilters.categories.dinner ? '&types[]=Ужин' : ''
        }`
      );
      setOrders(data);
    } catch (error) {
      toastError(error);
    }
  };

  const loadTickets = async () => {
    try {
      const { data } = await instance.get(
        `admin/dashboard/tickets?time[]=${startDate}&time[]=${endDate}${
          ticketsFilters.criticality.high ? '&ticket_criticism_ids[]=1' : ''
        }${
          ticketsFilters.criticality.medium ? '&ticket_criticism_ids[]=2' : ''
        }${ticketsFilters.criticality.low ? '&ticket_criticism_ids[]=3' : ''}${
          ticketsFilters.categories.length > 0
            ? ticketsFilters.categories
                .map(id => `&ticket_category_ids[]=${id}`)
                .join('')
            : ''
        }`
      );
      setTickets(data);
    } catch (error) {
      toastError(error);
    }
  };

  const loadQustionnaires = async () => {
    try {
      const { data } = await instance.get(
        `admin/dashboard/questionnaires?filter=time&query[]=${startDate}&query[]=${endDate}`
      );
      setQuestionnaires(data);
    } catch (error) {
      toastError(error);
    }
  };

  const loadFeedback = async () => {
    try {
      const { data } = await instance.get(
        `admin/dashboard/feedbacks?filter=time&query[]=${startDate}&query[]=${endDate}`
      );
      setFeedback(data);
    } catch (error) {
      toastError(error);
    }
  };

  const loadTicketCategories = async () => {
    try {
      const { data } = await instance.get(`admin/ticket-categories`);
      setTicketCategories(data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleNextQuestionnaire = () => {
    if (currentQuestionnaire < questionnaires.length - 1) {
      setCurrentQuestionnaire(currentQuestionnaire + 1);
    }
  };

  const handlePrevQuestionnaire = () => {
    if (currentQuestionnaire > 0) {
      setCurrentQuestionnaire(currentQuestionnaire - 1);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('requestFilter')) {
      setRequestFilters(JSON.parse(localStorage.getItem('requestFilter')!));
    }
    if (localStorage.getItem('ticketsFilters')) {
      setTicketsFilters(JSON.parse(localStorage.getItem('ticketsFilters')!));
    }
    loadTicketCategories();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      loadOrders();
      loadTickets();
      loadQustionnaires();
      loadFeedback();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      loadTickets();
    }
  }, [
    ticketsFilters.criticality.high,
    ticketsFilters.criticality.medium,
    ticketsFilters.criticality.low,
    ticketsFilters.categories.length,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      loadOrders();
      localStorage.setItem('requestFilters', JSON.stringify(requestFilters));
    }
  }, [
    requestFilters.categories.breakfast,
    requestFilters.categories.lunch,
    requestFilters.categories.dinner,
  ]);

  return (
    <div className={styles.dashboard}>
      <DashboardBlock
        title={t('applications')}
        actions={
          <StatActions>
            <button
              onClick={() => setIsTicketFilterShown(!isTicketFilterShown)}
              className={styles.ordersButton}
            >
              {t('filters')}
            </button>
            {isTicketFilterShown ? (
              <div ref={ordersRef} className={styles.filters}>
                <Filters
                  ticketCategories={ticketCategories}
                  data={ticketsFilters}
                  onChange={setTicketsFilters}
                />
              </div>
            ) : (
              ''
            )}
            <Link to="/requests" className={styles.ordersButton}>
              {t('show_all')}
            </Link>
          </StatActions>
        }
      >
        <TicketsStats orderStats={tickets} />
      </DashboardBlock>
      <DashboardBlock
        title={t('questionnaires_results')}
        actions={
          <StatActions>
            <Link to="/questionnaires" className={styles.ordersButton}>
              {t('show_all')}
            </Link>
            <div className={styles.arrowButtons}>
              <button
                disabled={currentQuestionnaire === 0}
                className={styles.arrowButton}
                onClick={handlePrevQuestionnaire}
              >
                <ArrowLeft />
              </button>
              <button
                disabled={
                  currentQuestionnaire === questionnaires.length - 1 ||
                  questionnaires.length === 0
                }
                className={styles.arrowButton}
                onClick={handleNextQuestionnaire}
              >
                <ArrowRight />
              </button>
            </div>
          </StatActions>
        }
      >
        <QuestionnaireStats
          questionnaire={questionnaires[currentQuestionnaire]}
        />
      </DashboardBlock>
      <DashboardBlock
        title={t('feedback')}
        actions={
          <StatActions>
            <Link to="/feedback" className={styles.ordersButton}>
              {t('show_all')}
            </Link>
          </StatActions>
        }
      >
        <FeedbackStats feedbacks={feedback} />
      </DashboardBlock>
      <DashboardBlock
        title={t('orders')}
        actions={
          <StatActions>
            <button
              onClick={() => setIsRequestFilterShown(!isRequestFilterShown)}
              className={styles.ordersButton}
            >
              {t('filters')}
            </button>
            {isRequestFilterShown ? (
              <div ref={ordersRef} className={styles.requestFilters}>
                <RequestFilter
                  data={requestFilters}
                  onChange={setRequestFilters}
                />
              </div>
            ) : (
              ''
            )}
            <Link to="/transactions" className={styles.ordersButton}>
              {t('show_all')}
            </Link>
          </StatActions>
        }
      >
        <OrdersStats orderStats={orders} />
      </DashboardBlock>
    </div>
  );
};

export default Dashboard;
