import styles from './QuestionnarieError.module.scss';

const QuestionnarieError = () => {
  return (
    <span className={styles.questionnarieError}>
      Вы уже проходили данную анкету
    </span>
  );
};

export default QuestionnarieError;
