import { renderMeasure, renderPrice } from '../../utils';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton } from '~/ui';
import Add from '~/assets/svg/newSvg/add.svg?react';
import Remove from '~/assets/svg/newSvg/remove.svg?react';
import cn from 'classnames';
import styles from './FullMenuTable.module.scss';
import { useTranslation } from 'react-i18next';

interface MenuItem {
  id: number;
  wares_name: string;
  price_list: { price: string; price_list_name: string };
  parent: string;
  weight: string;
}

interface Props {
  menu: MenuItem[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  selectedWares: number[];
  setSelectedWares: (prevWares: number[]) => void;
}

export const FullMenuTable = ({
  menu,
  sort,
  setSort,
  selectedWares,
  setSelectedWares,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const isWaresSelected = (id: number) => {
    return selectedWares.includes(id);
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="wares_name">{t('name')}</TableCellHead>
        <TableCellHead sortable={false} field="parent">
          {t('category')}
        </TableCellHead>
        <TableCellHead field="price">{t('price')}</TableCellHead>
        <TableCellHead field="weight">{t('measure')}</TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {menu.map(
          ({ id, wares_name, parent, price_list, weight }: MenuItem) => (
            <TableRow key={id}>
              <TableCell>{wares_name}</TableCell>
              <TableCell>{parent}</TableCell>
              <TableCell>{renderPrice(price_list?.price)}</TableCell>
              <TableCell>{renderMeasure(weight)}</TableCell>
              <TableCell>
                <div className={styles.actions}>
                  <IconButton
                    onClick={() => {
                      const newWares = [...selectedWares, id];
                      setSelectedWares(newWares);
                    }}
                    className={cn(styles.btnLink, {
                      [styles.addVisible]: isWaresSelected(id),
                      [styles.addInvisible]: !isWaresSelected(id),
                    })}
                  >
                    <Add />
                  </IconButton>
                  <IconButton
                    className={cn(styles.btnLink, {
                      [styles.removeVisible]: isWaresSelected(id),
                      [styles.removeInvisible]: !isWaresSelected(id),
                    })}
                    onClick={() => {
                      const filteredWares = selectedWares.filter(
                        el => el !== id
                      );
                      setSelectedWares(filteredWares);
                    }}
                  >
                    <Remove />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
