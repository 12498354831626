import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import styles from './OrderTable.module.scss';
import { useTranslation } from 'react-i18next';

interface OrderItem {
  id: number;
  name: string;
  category: string;
  weight: string;
  price: string;
  amount: number;
  sum: number;
}

interface Props {
  order: OrderItem[];
}

export const OrderTable = ({ order }: Props): JSX.Element => {
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const { t } = useTranslation();

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead sortable={false} field="name">
            {t('name')}
          </TableCellHead>
          <TableCellHead sortable={false} field="category">
            {t('category')}
          </TableCellHead>
          <TableCellHead sortable={false} field="weight">
            {t('measure')}
          </TableCellHead>
          <TableCellHead sortable={false} field="price">
            {t('price')}
          </TableCellHead>
          <TableCellHead sortable={false} field="amount">
            {t('quantity_full')}
          </TableCellHead>
          <TableCellHead sortable={false} field="sum">
            {t('total_amount')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {order.map(
            ({ id, name, category, weight, price, amount, sum }: OrderItem) => (
              <TableRow key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>{category}</TableCell>
                <TableCell>{weight}</TableCell>
                <TableCell>{price}&#8381;</TableCell>
                <TableCell>{amount}</TableCell>
                <TableCell>{sum}&#8381;</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
        <TableRow>
          <TableCell>
            <span className={styles.boldText}>{t('in_total')}:</span>
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <span className={styles.boldText}>
              {order.map(el => el.amount).reduce((acc, curr) => acc + curr, 0)}
            </span>
          </TableCell>
          <TableCell>
            <span className={styles.boldText}>
              {order.map(el => el.sum).reduce((acc, curr) => acc + curr, 0)}
              &#8381;
            </span>
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
};
