import { useTimer } from '~/utils/useTimer';
import styles from './Timer.module.scss';

interface TimerProps {
  expiryTime: string;
}

const Timer = ({ expiryTime }: TimerProps) => {
  const { days, hours, minutes, seconds } = useTimer({
    expiryTime: expiryTime,
    enabled: true,
  });

  return (
    <span
      className={styles.timerText}
    >{`${days}:${hours}:${minutes}:${seconds}`}</span>
  );
};

export default Timer;
