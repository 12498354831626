import styles from './StatActions.module.scss';

interface IStatActionsProps {
  children: React.ReactNode;
}

const StatActions = ({ children }: IStatActionsProps) => {
  return <div className={styles.statActions}>{children}</div>;
};

export default StatActions;
