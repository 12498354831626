import { useTranslation } from 'react-i18next';
import QuestionAccordion from '../QuestionAccordion/QuestionAccordion';

import styles from './FaqAuth.module.scss';

const FaqAuth = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles.faqAuth}>
      <QuestionAccordion
        title={t('no_connection')}
        description={t('no_connection_desctiption')}
        isAddedDescriptionShown
      />
      <QuestionAccordion
        title={t('press_error')}
        description={t('no_connection_desctiption')}
        isAddedDescriptionShown
      />
    </ul>
  );
};

export default FaqAuth;
