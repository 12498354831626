import { TableRow } from './TableRow';

import styles from './TableLoader.module.scss';

const TableLoader = ({ loadingRows }: { loadingRows: number }) => {
  return (
    <>
      {Array.from(Array(loadingRows), (_, index) => (
        <TableRow key={index} style={styles.tableLoaderRow}>
          <td colSpan={100} className={styles.tableLoaderRowContent}></td>
        </TableRow>
      ))}
    </>
  );
};

export default TableLoader;
