export function getLocalTimeDate(timeDate: string) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentLanguage = localStorage.getItem('i18nextLng');

  const localDate = new Date(timeDate);
  const formattedDate = localDate
    .toLocaleString(currentLanguage || 'ru', {
      timeZone,
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(' в', '');

  return formattedDate;
}
