import { useRef, useEffect, useState } from 'react';
import { Tooltip } from 'antd';

interface TooltipTextProps {
  text: string;
}

const TooltipText = ({ text }: TooltipTextProps) => {
  const textElementRef = useRef<null | HTMLDivElement>(null);
  const [isTooltipShown, setIsTooltipShown] = useState(true);

  const compareSize = () => {
    if (textElementRef.current === null) return;
    const compare =
      textElementRef?.current?.scrollWidth >
      textElementRef?.current?.clientWidth;
    setIsTooltipShown(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  if (isTooltipShown) {
    return (
      <Tooltip
        title={text}
        overlayInnerStyle={{
          backgroundColor: '#ECECEE',
          color: 'rgba(75, 70, 92, 0.8)',
          boxShadow: 'none',
          fontSize: '13px',
        }}
        showArrow={false}
      >
        <div
          ref={textElementRef}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </div>
      </Tooltip>
    );
  }

  return (
    <div
      ref={textElementRef}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </div>
  );
};

export default TooltipText;
