import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { FoodForm, FoodFormValues } from '~/components/FoodForm';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import * as Tabs from '@radix-ui/react-tabs';
import cn from 'classnames';
import styles from './CreateFood.module.scss';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';

const CreateFood = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isAssetEditable = checkIfUserSuperAdmin(user?.data.role_name);
  const [activeTab, setActiveTab] = useState('tab1');
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  // This is the logic of working with React Query

  const client = useQueryClient();

  const { mutateAsync: createFood } = useMutation(
    async ({
      name,
      description,
      food_accommodation,
      address,
      assets,
      uploadPhotosValue,
      is_active,
      pointObject,
      timezone,
    }: FoodFormValues) => {
      const response = await instance.postForm('food-admin', {
        name,
        description,
        accommodation_ids: food_accommodation.map(
          accommodation => accommodation.id
        ),
        timezone: timezone.id,
        address,
        ...(is_active && { is_active: is_active?.id }),
        asset_id: assets.id,
        image: uploadPhotosValue.files,
        object_type_id: pointObject.id,
      });
      return response.data;
    },
    {
      onSuccess: (data: FoodFormValues) => {
        client.setQueryData(['food'], (oldFood?: FoodFormValues[]) => {
          if (!oldFood) {
            return [data];
          }
          return [...oldFood, data];
        });
        toastSuccess(t('new_food_point_added'));
        navigate(`/food`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page heading={<Heading text={t('creating_food_point')} />}>
      <Tabs.Root
        className={styles.tabsRoot}
        value={activeTab}
        onValueChange={setActiveTab}
      >
        <Tabs.List className={styles.tabsList} aria-label="Manage your account">
          <Tabs.Trigger
            value="tab1"
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]: activeTab === 'tab1',
            })}
          >
            {t('general_information')}
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="tab1"
        >
          <FoodForm
            isAssetEditAvailable={isAssetEditable}
            onSubmit={values => {
              createFood(values);
            }}
            storageKey="create-food"
          />
        </Tabs.Content>
      </Tabs.Root>
    </Page>
  );
};

export default CreateFood;
