export function downloadFile(data: BlobPart, type: string, name: string) {
  const fileUrl = URL.createObjectURL(new Blob([data], { type }));

  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
