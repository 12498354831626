import { useTranslation } from 'react-i18next';
import styles from './TableAmountPage.module.scss';

interface TableAmountPageProps {
  firstRow: number;
  lastRow: number;
  total: number;
}
export const TableAmountPage = ({
  firstRow,
  lastRow,
  total,
}: TableAmountPageProps) => {
  const { t } = useTranslation();
  return (
    <>
      {total > 0 && (
        <p className={styles.table__showing}>
          {`${t('show_with')} ${firstRow} ${t('to')} ${lastRow} ${t(
            'of'
          )} ${total} ${t('entries')}`}
        </p>
      )}
    </>
  );
};
