import i18next from 'i18next';

export const getTimezones = () => {
  const t = i18next.t;
  return [
    { id: 'UTC +8', name: `+8 UTC (${t('Irkutsk')})` },
    { id: 'UTC +12', name: `+12 UTC (${t('Kamchatka')})` },
    { id: 'UTC +7', name: `+7 UTC (${t('Krasnoyarsk')})` },
    { id: 'UTC +11', name: `+11 UTC (${t('Magadan')})` },
    { id: 'UTC +7', name: `+7 UTC (${t('Novosibirsk')})` },
    { id: 'UTC +6', name: `+6 UTC (${t('Omsk')})` },
    { id: 'UTC +11', name: `+11 UTC (${t('Sakhalin')})` },
    { id: 'UTC +10', name: `+10 UTC (${t('Vladivostok')})` },
    { id: 'UTC +9', name: `+9 UTC (${t('Yakutsk')})` },
    { id: 'UTC +5', name: `+5 UTC (${t('Yekaterinburg')})` },
    { id: 'UTC +3', name: `+3 UTC (${t('Moscow')})` },
  ];
};
