import styles from './Table.module.scss';
import cn from 'classnames';

interface TableProps {
  children: React.ReactNode;
  className?: string;
}

export const Table = ({ children, className }: TableProps): JSX.Element => {
  return <table className={cn(styles.table, className)}>{children}</table>;
};
