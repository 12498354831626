import { useState } from 'react';
import DeleteSvg from '~/assets/svg/newSvg/delete.svg?react';
import styles from './uploadPhotoItem.module.scss';
import { Modal } from '~/components/Shared/Modal/Modal';
import { Button } from '~/ui';
import { useTranslation } from 'react-i18next';

interface UploadPhotoItemProps {
  url: string;
  deletePhoto?: boolean;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const UploadPhotoItem = ({
  url,
  deletePhoto,
  width = 100,
  height = 100,
  onClick,
}: UploadPhotoItemProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const handleDeleteClick = () => {
    onClick?.call(null);
    close();
  };

  return (
    <div style={{ width, height }} className={styles.imgWrapper}>
      {deletePhoto ? (
        <button type="button" className={styles.svg} onClick={onClick}>
          <DeleteSvg />
        </button>
      ) : null}

      <div style={{ width, height }} className={styles.imageLoaderWrapper}>
        {isLoading && (
          <div style={{ width, height }} className={styles.loader} />
        )}
        <img
          src={url}
          alt="picture"
          className={styles.img}
          onClick={open}
          role="button"
          onLoad={() => setIsLoading(false)}
          loading="lazy"
        />
      </div>
      <Modal isOpen={isOpen} onClose={close} size="full">
        <img src={url} alt="picture" className={styles.modal__image} />
        <div className={styles.modal__actions}>
          {deletePhoto && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteClick}
            />
          )}
          <Button text="Закрыть" color="gray" onClick={close} />
        </div>
      </Modal>
    </div>
  );
};

export default UploadPhotoItem;
