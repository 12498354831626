import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '~/assets/css/toastify.css';
import { instance } from './api/api';
import { useToastError } from './useToastError';
import AlertIcon from '../assets/svg/newSvg/alert-info.svg?react';
import SuccessIcon from '../assets/svg/newSvg/success-alert.svg?react';
import i18next from 'i18next';

export function toastUpload(id: string | null) {
  const [isFinished, setIsFinished] = useState(false);
  const toastError = useToastError();
  const t = i18next.t;

  const updateProgress = async () => {
    try {
      const { data } = await instance.get(`admin/queue-status?unique_id=${id}`);
      toast.update('upload-toast', {
        render: () => (
          <div
            style={{
              width: '100%',
              textAlign: 'left',
              fontFamily: 'SF Pro Text',
              fontWeight: 500,
              letterSpacing: '-0.5px',
            }}
          >
            {t('users_uploaded')}: {data.process || 0} /{' '}
            {data.total_rows_count || 0}
            <br />
            {t('users_upload_first')}: {data.users_statistic.Created || 0}
            <br />
            {t('users_upload_updated')}: {data.users_statistic.Updated || 0}
            <br />
            {t('users_upload_discarded')}: {data.users_statistic.Canceled || 0}
          </div>
        ),
        progress: data.process / data.total_rows_count,
        progressStyle: { backgroundColor: '#F0A83B' },
        style: {
          backgroundColor: '#f4e4d6',
          color: '#F0A83B',
          fontWeight: 500,
          fontFamily: 'SF Pro Text',
        },
        toastId: 'upload-toast',
        icon: null,
      });

      if (data.process && data.total_rows_count) {
        if (!data.is_active) {
          toast.dismiss();
          toast.success(
            <div
              style={{
                width: '100%',
                textAlign: 'left',
                fontWeight: 500,
                fontFamily: 'SF Pro Text',
                whiteSpace: 'nowrap',
                letterSpacing: '-0.5px',
              }}
            >
              {t('users_uploaded')}: {data.process || 0} /{' '}
              {data.total_rows_count || 0}
              <br />
              {t('users_upload_first')}: {data.users_statistic.Created || 0}
              <br />
              {t('users_upload_updated')}: {data.users_statistic.Updated || 0}
              <br />
              {t('users_upload_discarded')}:{' '}
              {data.users_statistic.Canceled || 0}
            </div>,
            {
              autoClose: false,
              style: {
                backgroundColor: '#d1ebdd',
                color: '#1CBE63',
                fontWeight: 500,
                fontFamily: 'SF Pro Text',
                width: 'fit-content',
              },
              icon: <SuccessIcon />,
              toastId: 'upload-success',
            }
          );
          localStorage.removeItem('uploadId');
          return 'finished';
        }
      }
      return data;
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    if (id) {
      const interval = setInterval(async () => {
        const data = await updateProgress();

        if (data === 'finished') {
          clearInterval(interval);
          setIsFinished(true);
        }
      }, 4000);
    }
  }, [id]);

  if (!id) {
    return null;
  }

  if (isFinished) {
    return 'finished';
  }

  return toast.info(
    <div className="toastContent">
      <span className="toastContentTitle">{t('user_upload_begin')}</span>
    </div>,
    {
      style: {
        whiteSpace: 'nowrap',
        fontFamily: 'SF Pro Text',
      },
      progress: 0,
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      autoClose: false,
      isLoading: false,
      toastId: 'upload-toast',
      progressStyle: { backgroundColor: '#28a745' },
      icon: <AlertIcon />,
    }
  );
}
