import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Tabs from '@radix-ui/react-tabs';
import { TicketStatus } from '../Requests/utils';
import {
  RequestTimerForm,
  PrevRequest,
} from './RequestTimerForm/RequestTimerForm';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { Button, Select } from '~/ui';
import cn from 'classnames';
import styles from './Request.module.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { Modal } from '~/components/Shared/Modal/Modal';
import { CommentList } from '~/components/CommentList/CommentList';
import { RequestCommentForm } from './RequestCommentForm/RequestCommentForm';
import { RequestForm, RequestFormItem } from './RequestForm/RequestForm';

interface ITicketItem {
  id: number;
  ticket_status: number;
}

export interface IStatus {
  id: number;
  name: string;
  type: string;
  created_at: string | null;
  updated_at: string | null;
}

const Request = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [selectStatus, setSelectStatus] = useState<number>();
  const [statuses, setStatuses] = useState<IStatus[] | []>([]);
  const { requestID } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate('/requests');
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const client = useQueryClient();

  const handleOpenImageInModal = (image: string) => {
    setIsModalOpen(true);
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView();
  };

  const { data, isError, isLoading } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/ticket/${requestID}`);
      return response.data;
    },
    queryKey: ['request', requestID],
    onError: error => {
      toastError(error);
    },
  });

  const request = data?.tickets;
  const comments = data?.tickets.ticket_comment;

  const getTicketStatuses = async () => {
    try {
      const { data } = await instance.get(`admin/ticket-statuses`);
      setStatuses(data);
    } catch (error) {
      toastError(error);
    }
  };

  const { mutate: closeRequest } = useMutation(
    async ({ id, ticket_status }: ITicketItem) => {
      const response = await instance.post(
        `admin/ticket/${id}`,
        { ticket_status },
        {}
      );
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['notifications-count']);
        client.invalidateQueries(['request', requestID]);
        toastSuccess(t('request_closed'));
        goBack();
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutate: updateTicket } = useMutation(
    async ({ id, ticket_status }: ITicketItem) => {
      const response = await instance.post(`admin/ticket/${id}`, {
        ticket_status,
      });
      return response.data;
    },
    {
      onSuccess: (newData: RequestFormItem) => {
        client.invalidateQueries(['notifications-count']);
        client.setQueryData(
          ['request', requestID],
          (prevRequest: PrevRequest | undefined) => {
            if (!prevRequest) return;
            const newRequest = {
              ...prevRequest.tickets,
              ticket_status: newData.ticket_status,
            };
            return { tickets: newRequest };
          }
        );
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutate: deleteComment } = useMutation(
    async (id: number) => {
      const response = await instance.delete(`admin/ticket-comment/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['request', requestID]);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data: user } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/get-user-auth');
      return response.data;
    },
    queryKey: ['user-auth'],
    onError: error => {
      toastError(error);
    },
  });

  const handleStatusChange = (value: number) => {
    setSelectStatus(value);
    updateTicket({ id: request.id, ticket_status: value });
  };

  useEffect(() => {
    getTicketStatuses();
  }, []);

  useEffect(() => {
    if (!request) return;
    setSelectStatus(request.ticket_status);
  }, [request]);

  return (
    <Page
      heading={
        <Heading
          text={
            request?.created_at
              ? `${t('request_dated')} ${dayjs(request?.created_at).format(
                  'DD.MM.YYYY'
                )}`
              : ''
          }
          classNameHeading={styles.heading}
          actions={
            <div className={styles.btnRedWarpper}>
              <Button
                disabled={!comments?.length}
                color="red"
                text={t('request_close')}
                onClick={() =>
                  closeRequest({
                    id: Number(requestID || ''),
                    ticket_status: 4,
                  })
                }
                className={styles.btnRed}
              />
              {!comments?.length ? (
                <span className={styles.btnRedSubText}>
                  *{t('request_leave_comment')}.
                </span>
              ) : null}
            </div>
          }
        />
      }
      isError={isError}
      isLoading={isLoading}
    >
      <Tabs.Root
        className={styles.tabsRoot}
        value={activeTab}
        onValueChange={setActiveTab}
      >
        <Tabs.List className={styles.tabsList} aria-label="Manage your account">
          <Tabs.Trigger
            value="tab1"
            className={cn(styles.tabsTrigger, {
              [styles['tabsTriggerActive']]: activeTab === 'tab1',
            })}
          >
            {t('request_info')}
          </Tabs.Trigger>
          <Tabs.Trigger
            className={cn(styles.tabsTrigger, {
              [styles['tabsTriggerActive']]: activeTab === 'tab2',
            })}
            value="tab2"
          >
            {t('comments')}
          </Tabs.Trigger>
          <div className={`${styles.selectBarRequest} selectBarWrapper`}>
            <Select
              value={selectStatus ? selectStatus : null}
              onChange={value => handleStatusChange(value)}
              disabled={
                request?.ticket_status ===
                  statuses.find(status => status.type === TicketStatus.FINISHED)
                    ?.id || false
              }
              options={statuses}
              star={false}
              size="small"
              search={false}
              multiple={false}
            />
          </div>
        </Tabs.List>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="tab1"
        >
          {request && requestID && (
            <RequestTimerForm
              statuses={statuses}
              request={request}
              requestID={requestID}
            />
          )}
          <RequestForm request={request} />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="tab2"
        >
          <CommentList
            isEditShown
            ref={scrollRef}
            comments={comments}
            user={user}
            onClick={deleteComment}
            handleClickImage={handleOpenImageInModal}
            scrollToBottom={scrollToBottom}
          />
          <RequestCommentForm />
        </Tabs.Content>
      </Tabs.Root>
      <Modal
        modalStyles={styles.foodModal}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        isCloseButtonShown
      >
        <img className={styles.requestCommentImage} src={selectedImage} />
      </Modal>
    </Page>
  );
};

export default Request;
