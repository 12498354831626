import AuthIcon from './images/auth.svg?react';
import AppIcon from './images/app.svg?react';
import EditIcon from './images/edit.svg?react';
import styles from './QuestionsList.module.scss';
import Question from './Question/Question';
import { useTranslation } from 'react-i18next';

const QuestionsList = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles.questionsList}>
      <Question link="/faq/auth" icon={<AuthIcon />} name={t('auth_problem')} />
      <Question link="/faq/app" icon={<AppIcon />} name={t('app_work')} />
      <Question link="/faq/add" icon={<EditIcon />} name={t('app_info')} />
    </ul>
  );
};

export default QuestionsList;
