import { Popover } from 'antd';
import { XlsBtn } from '~/ui';
import { ReactNode, useState, createContext } from 'react';

interface XlsUploadFilePopoverProps {
  content: ReactNode;
  btnText: string;
  classNames?: string;
}

export const PopoverContext = createContext<{
  isPopoverVisible: boolean;
  setIsPopoverVisible: (visible: boolean) => void;
}>({
  isPopoverVisible: false,
  setIsPopoverVisible: () => {},
});

export const XlsUploadFilePopover = ({
  content,
  btnText,
  classNames,
}: XlsUploadFilePopoverProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

  const handleTogglePopover = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <PopoverContext.Provider value={{ isPopoverVisible, setIsPopoverVisible }}>
      <Popover
        id="analytics"
        placement="left"
        trigger="click"
        open={isPopoverVisible}
        onOpenChange={handleTogglePopover}
        content={content}
      >
        <XlsBtn
          onClick={handleTogglePopover}
          xlsBtnText={btnText}
          classNames={classNames}
        />
      </Popover>
    </PopoverContext.Provider>
  );
};
