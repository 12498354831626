import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useToastError } from '~/utils/useToastError';
import { Button, InputBar } from '~/ui/index';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { positions } from '~/components/FoodPersonForm/positions';
import { validate } from '~/utils/validatePerson';
import styles from './FoodPersonForm.module.scss';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';

export interface FoodPersonFormValues {
  id: number | null;
  name: string;
  patronymic: string;
  position: {
    id: number;
    name: string;
  };
  email: string;
  phone: string;
  food_id: string;
}

export interface FoodPersonItem {
  id: number | null;
  name: string;
  patronymic: string;
  position: string;
  email: string;
  phone: string;
  food_id: string;
}

interface Props {
  foodPersonItem?: FoodPersonItem;
  onSubmit: (values: FoodPersonFormValues) => void;
  storageKey?: string;
}

export const FoodPersonForm = ({
  storageKey,
  foodPersonItem,
  onSubmit,
}: Props) => {
  const toastError = useToastError();
  const { t } = useTranslation();

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<FoodPersonFormValues>({
      initialValues: {
        id: null,
        name: '',
        patronymic: '',
        position: { id: 0, name: '' },
        email: '',
        phone: '',
        food_id: '',
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: [],
    });

  const formik = useFormik<FoodPersonFormValues>({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async (values: FoodPersonFormValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const setValues = formik.setValues;

  const personPositions = positions();

  useEffect(() => {
    if (!foodPersonItem) return;

    setValues({
      id: foodPersonItem.id,
      name: foodPersonItem.name,
      patronymic: foodPersonItem.patronymic,
      position: personPositions.find(
        position => String(position.id) === String(foodPersonItem.position)
      ) || {
        id: 0,
        name: '',
      },
      email: foodPersonItem.email,
      phone: foodPersonItem.phone,
      food_id: foodPersonItem.food_id,
    });
  }, [foodPersonItem, setValues]);

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formItems}>
          <div className={styles.formName}>
            <InputBar
              name="name"
              value={formik.values.name}
              disabled={false}
              label={t('first_name')}
              star={true}
              placeholder={t('first_name')}
              onChange={formik.handleChange}
              errors={formik.errors.name}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />

            <InputBar
              name="patronymic"
              value={formik.values.patronymic}
              disabled={false}
              label={t('surname')}
              star={true}
              placeholder={t('surname')}
              onChange={formik.handleChange}
              errors={formik.errors.patronymic}
              onBlur={formik.handleBlur}
              error={
                formik.touched.patronymic && Boolean(formik.errors.patronymic)
              }
            />

            {personPositions.length && (
              <CustomSelect
                label={t('job_title')}
                options={personPositions}
                value={formik.values.position}
                placeholder={t('job_title_validate')}
                onChange={value => {
                  formik.setFieldValue('position', value);
                }}
                required
              />
            )}
          </div>
          <div className={styles.formInfo}>
            <InputBar
              name="email"
              value={formik.values.email}
              disabled={false}
              label={t('mailbox')}
              star={true}
              placeholder={t('mailbox')}
              onChange={formik.handleChange}
              errors={formik.errors.email}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />

            <InputBar
              name="phone"
              value={formik.values.phone}
              disabled={false}
              label={t('phone_number')}
              star={true}
              placeholder={t('phone_number')}
              onChange={formik.handleChange}
              errors={formik.errors.phone}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </div>
        </div>
        <Button
          type="submit"
          text={t('save_n_finish')}
          className={styles.formBtn}
          disabled={!((formik.isValid && formik.dirty) || formik.isSubmitting)}
        />
      </form>
    </div>
  );
};
