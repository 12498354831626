import { instance } from '~/utils/api/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import DownloadQr from '~/assets/svg/newSvg/downloadQr.svg?react';
import styles from './QrTable.module.scss';
import { downloadFile } from '~/utils/downloadFile';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

interface QrItem {
  id: number;
  object_name: string;
  object_type: string;
}

interface UsersTableProps {
  qrList: QrItem[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
}

export const QrTable = ({
  qrList,
  sort,
  setSort,
  selectedItems,
  handleToggleAll,
  handleToggle,
}: UsersTableProps) => {
  const client = useQueryClient();
  const { t } = useTranslation();

  const { mutate: deleteQr } = useMutation(
    async (id: string) => {
      return await instance.delete(`admin/qr-generate-object/${id}`);
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['qr']);
      },
    }
  );

  const downloadQr = async (id: string) => {
    const response = await instance.get(`admin/qr-download/${id}`, {
      responseType: 'blob',
    });

    downloadFile(response.data, 'image/png', 'qr.png');
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead
          className={styles.tableCellHead}
          sortable={false}
          field="checkbox"
        >
          <CustomCheckbox
            isChecked={
              selectedItems.length === qrList.length && !!qrList.length
            }
            onChange={handleToggleAll}
          />
        </TableCellHead>
        <TableCellHead field="type">{t('category')}</TableCellHead>
        <TableCellHead field="object_name">{t('object_name')}</TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {qrList.map(({ id, object_name, object_type }: QrItem) => (
          <TableRow key={id}>
            <TableCell>
              <CustomCheckbox
                isChecked={selectedItems.includes(id)}
                onChange={() => handleToggle(id)}
              />
            </TableCell>
            <TableCell>{object_type}</TableCell>
            <TableCell>{object_name}</TableCell>
            <TableCell>
              <div className={styles.qrTableActions}>
                <IconButton
                  className={styles.qrTableBtnDownload}
                  onClick={() => downloadQr(id.toString())}
                >
                  <DownloadQr />
                </IconButton>
                <ModalConfirm
                  title={t('qr_delete_approve')}
                  onConfirm={() => deleteQr(id.toString())}
                >
                  {open => (
                    <IconButton onClick={open}>
                      <Basket />
                    </IconButton>
                  )}
                </ModalConfirm>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
