import { useEffect, useState } from 'react';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { validate } from '~/utils/validateAsset';
import { useFormik } from 'formik';
import styles from './CreateAsset.module.scss';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import CreateAssetForm from './CreateAssetForm/CreateAssetForm';
import { useToastError } from '~/utils/useToastError';
import { instance } from '~/utils/api/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useQueryClient } from '@tanstack/react-query';

export interface IColorScheme {
  id: number;
  name: string;
  color: string;
  text_color: string;
}

export interface IAssetValues {
  id: number;
  name: string;
  description: string;
  inn: string;
  color_id: number;
  logo: File | null;
  address: string;
  delete_image: boolean;
}

const CreateAsset = () => {
  const toastError = useToastError();
  const [colors, setColors] = useState<IColorScheme[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();
  const client = useQueryClient();

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<IAssetValues>({
      initialValues: {
        id: 0,
        name: '',
        description: '',
        inn: '',
        color_id: 1,
        logo: null,
        address: '',
        delete_image: false,
      },
      key: 'create-asset' && `create-asset-v1`,
      exclude: [],
    });

  const formik = useFormik({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async values => {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('inn', values.inn);
      if (values.logo) {
        formData.append('image', values.logo);
      }
      formData.append('color_id', String(values.color_id));
      formData.append('address', values.address);
      formData.append('delete_image', values.logo ? '0' : '1');
      try {
        await instance.post('admin/assets/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toastSuccess(t('asset_created'));
        client.invalidateQueries(['assets']);
        navigate('/assets');
        clear();

        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const loadColors = async () => {
    try {
      const { data } = await instance.get('admin/color-scheme');

      setColors(data);
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    loadColors();
  }, []);

  return (
    <Page
      heading={
        <Heading
          text={t('new_asset_create')}
          classNameText={styles.createUserPageText}
        />
      }
    >
      <CreateAssetForm colorSchemes={colors} formik={formik} />
    </Page>
  );
};

export default CreateAsset;
