import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import styles from './FoodTypeTable.module.scss';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import CheckMarkIcon from '~/assets/svg/newSvg/mark-icon.svg?react';
import { useTranslation } from 'react-i18next';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { IconButton } from '~/ui';
import { DatePicker } from 'antd';
import { FormikValues, useFormik } from 'formik';
import { useToastError } from '~/utils/useToastError';
import dayjs from 'dayjs';
import { instance } from '~/utils/api/api';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

interface FoodTypesItem {
  id: number;
  end_time: string;
  start_time: string;
  repeat: string;
  name: string;
  display_name: string;
  foodTime?: {
    name: string;
    start_time: string;
    end_time: string;
  };
}

interface Props {
  foodTypes: FoodTypesItem[];
}

export const FoodTypeTable = ({ foodTypes }: Props): JSX.Element => {
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const toastError = useToastError();
  const { t } = useTranslation();
  const { foodID } = useParams();
  const client = useQueryClient();

  const initialValues = {
    id: 0,
    display_name: '',
    end_time: '',
    start_time: '',
    repeat: '',
    name: '',
  };

  const onSubmit = async (values: FormikValues) => {
    try {
      await instance.patch('admin/food-type-time', {
        food_id: foodID,
        food_types_time: [
          {
            food_type_id: values.id,
            start_time: dayjs(values.start_time, 'HH:mm').format('HH:mm:ss'),
            end_time: dayjs(values.end_time, 'HH:mm').format('HH:mm:ss'),
          },
        ],
      });
      formik.setValues(initialValues);
      client.invalidateQueries(['food-item', foodID]);
    } catch (error) {
      toastError(error);
    }
  };

  const formik = useFormik<any>({
    initialValues: initialValues,

    onSubmit: async values => {
      try {
        await onSubmit(values);
      } catch (error) {
        toastError(error);
      }
    },
  });

  const sortByTime = (a: FoodTypesItem, b: FoodTypesItem) => {
    if (!a.start_time || !b.start_time) {
      return 0;
    }
    return dayjs(a.start_time, 'HH:mm').isBefore(dayjs(b.start_time, 'HH:mm'))
      ? -1
      : 1;
  };

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead sortable={false} field="title">
            {t('name')}
          </TableCellHead>
          <TableCellHead sortable={false} field="start_time">
            {t('start_time')}
          </TableCellHead>
          <TableCellHead sortable={false} field="end_time">
            {t('end_time')}
          </TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('actions')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {foodTypes
            ?.sort(sortByTime)
            .map(
              ({ id, start_time, end_time, name, foodTime }: FoodTypesItem) => {
                return (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {id === formik.values.id ? (
                        <DatePicker.TimePicker
                          format="HH:mm"
                          value={dayjs(formik.values.start_time, 'HH:mm')}
                          onChange={dateString => {
                            formik.setFieldValue('start_time', dateString);
                          }}
                          showNow={false}
                          allowClear={false}
                        />
                      ) : foodTime?.start_time ? (
                        dayjs(foodTime.start_time, 'HH:mm:ss').format('HH:mm')
                      ) : (
                        dayjs(start_time, 'HH:mm:ss').format('HH:mm')
                      )}
                    </TableCell>
                    <TableCell>
                      {id === formik.values.id ? (
                        <DatePicker.TimePicker
                          format="HH:mm"
                          value={dayjs(formik.values.end_time, 'HH:mm')}
                          onChange={dateString => {
                            formik.setFieldValue('end_time', dateString);
                          }}
                          showNow={false}
                          allowClear={false}
                        />
                      ) : foodTime?.end_time ? (
                        dayjs(foodTime.end_time, 'HH:mm:ss').format('HH:mm')
                      ) : (
                        dayjs(end_time, 'HH:mm:ss').format('HH:mm')
                      )}
                    </TableCell>
                    <TableCell>
                      {id === formik.values.id ? (
                        <div className={styles.actions}>
                          <IconButton
                            onClick={formik.handleSubmit}
                            className={styles.BusinessTripTableBtnLink}
                          >
                            <CheckMarkIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              formik.setValues({
                                id: 0,
                                name: '',
                                start_time: '',
                                end_time: '',
                              });
                            }}
                            className={styles.BusinessTripTableBtnLink}
                          >
                            <Basket />
                          </IconButton>
                        </div>
                      ) : (
                        <IconButton
                          onClick={() => {
                            formik.setValues({
                              id: id,
                              name: name,
                              start_time: foodTime?.start_time || start_time,
                              end_time: foodTime?.end_time || end_time,
                            });
                          }}
                          className={styles.BusinessTripTableBtnLink}
                        >
                          <Edit />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
    </div>
  );
};
