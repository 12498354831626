import { useTranslation } from 'react-i18next';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import StatChip from '../StatChip/StatChip';
import styles from './OrdersStats.module.scss';

export interface IOrderStats {
  all_count: number;
  statuses: {
    created: number;
    canceled: number;
    success: number;
    expired?: number;
    in_progress?: number;
  };
}

interface IOrderStatsProps {
  orderStats: IOrderStats;
}

const OrdersStats = ({ orderStats }: IOrderStatsProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.ordersStats}>
      <div className={styles.chart}>
        <DoughnutChart orderStats={orderStats} />
      </div>
      <div className={styles.stats}>
        <StatChip
          label={t('done')}
          color="#7367F0"
          amount={orderStats?.statuses?.success}
        />
        <StatChip
          label={t('status_closed_2')}
          color="rgba(75, 70, 92, 0.5)"
          amount={orderStats?.statuses?.canceled}
        />
        <StatChip
          label={t('status_created_2')}
          color="rgba(115, 103, 240, 0.4)"
          amount={orderStats?.statuses?.created}
        />
      </div>
    </div>
  );
};

export default OrdersStats;
