import EstimateAnswerStats, {
  IEstimateAnswer,
} from './EstimateAnswerStats/EstimateAnswerStats';
import OpenAnswerStats, {
  IOpenAnswer,
} from './OpenAnswerStats/OpenAnswerStats';
import styles from './AnswerList.module.scss';
import ClosedAnswerStats, {
  ICloseAnswer,
} from './ClosedAnswerStats/ClosedAnswerStats';

interface IAnswerListProps {
  answers: IOpenAnswer[] | IEstimateAnswer[] | ICloseAnswer[];
  isSingleStat?: boolean;
}

const AnswerList = ({ answers, isSingleStat }: IAnswerListProps) => {
  return (
    <ul className={styles.answerList}>
      {answers?.map((answer: IOpenAnswer | IEstimateAnswer | ICloseAnswer) => {
        if (answer?.question_type === 'open') {
          return (
            <OpenAnswerStats
              key={answer.question_id}
              answer={answer as IOpenAnswer}
              isMultiStatHidden={!isSingleStat}
            />
          );
        }
        if (answer?.question_type === 'estimate') {
          return (
            <EstimateAnswerStats
              key={answer.question_id}
              answer={answer as IEstimateAnswer}
              isMultiStatHidden={!isSingleStat}
            />
          );
        }
        if (answer?.question_type === 'close') {
          return (
            <ClosedAnswerStats
              key={answer.question_id}
              answer={answer as ICloseAnswer}
              isMultiStatHidden={!isSingleStat}
            />
          );
        }
      })}
    </ul>
  );
};

export default AnswerList;
