import styles from './StatChip.module.scss';

interface IStatChipProps {
  label: string;
  color: string;
  amount: number;
}

const StatChip = ({ label, color, amount }: IStatChipProps) => {
  return (
    <div className={styles.statChip}>
      <div className={styles.colorBlock} style={{ backgroundColor: color }} />
      <span className={styles.label}>{label}</span>
      <div></div>
      <span className={styles.value}>{amount}</span>
    </div>
  );
};

export default StatChip;
