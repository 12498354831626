import { ConfirmedTransactionsTable } from './ConfirmedTransactionsTable/ConfirmedTransactionsTable';
import styles from './ConfirmedTransactionsBoard.module.scss';
import { ITransaction } from '../TransactionsFoodAdminBoard';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { IsSort } from '~/ui/Table';
import { useTranslation } from 'react-i18next';
import { Button, BUTTON_STYLES } from '~/ui';

interface Props {
  transactions: ITransaction[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  onCloseClick: () => void;
}

const ConfirmedTransactionsBoard = ({
  transactions,
  sort,
  setSort,
  onCloseClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Board classNames={styles.transactionsBoard}>
      <div className={styles.transactionsBoardControls}>
        <span className={styles.title}>{t('confirmed_dishes')}</span>
        <Button
          className={styles.closeButton}
          buttonStyle={BUTTON_STYLES.EMPTY}
          text={t('close')}
          onClick={onCloseClick}
        />
      </div>
      <div className={styles.tableWrapper}>
        <ConfirmedTransactionsTable
          sort={sort}
          setSort={setSort}
          transactions={transactions}
        />
      </div>
    </Board>
  );
};

export default ConfirmedTransactionsBoard;
