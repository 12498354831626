import cn from 'classnames';
import styles from './Button.module.scss';
import React from 'react';
import LoaderSpinner from '~/components/Shared/LoaderSpinner/LoaderSpinner';

interface ButtonProps {
  Icon?: React.ElementType;
  text: string;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  color?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  buttonStyle?: string;
  isLoading?: boolean;
}

export const BUTTON_STYLES = {
  BASE: 'base',
  EMPTY: 'empty',
  FILLED: 'filled',
  DISABLED: 'disabled',
};

export const Button = ({
  Icon,
  text,
  onClick,
  className,
  type = 'button',
  disabled,
  color,
  buttonStyle,
  onMouseEnter,
  onMouseLeave,
  isLoading = false,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={cn(
        styles.button,
        className,
        styles[`button--${color}`],
        buttonStyle === BUTTON_STYLES.EMPTY &&
          styles[`button--${BUTTON_STYLES.EMPTY}`],
        disabled && styles[`button--${BUTTON_STYLES.DISABLED}`]
      )}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading && (
        <div className={styles.buttonLoader}>
          <LoaderSpinner />
        </div>
      )}
      <div style={isLoading ? { opacity: 0 } : {}}>
        {Icon && <Icon style={{ marginRight: '10px' }} />}
        <span>{text}</span>
      </div>
    </button>
  );
};
