import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

import styles from './Filters.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export interface ICategory {
  id: number;
  category: string;
  id_object?: number;
}

export interface IFiltersProps {
  data: {
    isDateEnabled: boolean;
    isAllObjectsInfoEnabled: boolean;
    isFavoriteObjectsEnabled: boolean;
    isCriticalityEnabled: boolean;
    isCategoriesEnabled: boolean;
    categories: number[];
    criticality: {
      high: boolean;
      medium: boolean;
      low: boolean;
    };
  };
  onChange: (data: any) => void;
  ticketCategories: ICategory[];
}

const Filters = ({ data, onChange, ticketCategories }: IFiltersProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem('ticketsFilters', JSON.stringify(data));
  }, [data]);

  return (
    <ul className={styles.filters}>
      <li className={styles.filterItem}>
        <label className={styles.filterItemLabel}>
          <CustomCheckbox
            isChecked={data.isCriticalityEnabled}
            onChange={() =>
              onChange({
                ...data,
                isCriticalityEnabled: !data.isCriticalityEnabled,
                criticality: {
                  high: !data.isCriticalityEnabled,
                  medium: !data.isCriticalityEnabled,
                  low: !data.isCriticalityEnabled,
                },
              })
            }
          />
          {t('criticality')}
        </label>
        <ul className={styles.insideList}>
          <li className={styles.filterItem}>
            <label className={styles.filterItemLabel}>
              <CustomCheckbox
                isChecked={data.criticality.high}
                onChange={() =>
                  onChange({
                    ...data,
                    isCriticalityEnabled:
                      !data.criticality.high &&
                      data.criticality.medium &&
                      data.criticality.low
                        ? true
                        : false,
                    criticality: {
                      ...data.criticality,
                      high: !data.criticality.high,
                    },
                  })
                }
              />
              {t('high_full')}
            </label>
          </li>
          <li className={styles.filterItem}>
            <label className={styles.filterItemLabel}>
              <CustomCheckbox
                isChecked={data.criticality.medium}
                onChange={() =>
                  onChange({
                    ...data,
                    isCriticalityEnabled:
                      data.criticality.high &&
                      !data.criticality.medium &&
                      data.criticality.low
                        ? true
                        : false,
                    criticality: {
                      ...data.criticality,
                      medium: !data.criticality.medium,
                    },
                  })
                }
              />
              {t('medium_full')}
            </label>
          </li>
          <li className={styles.filterItem}>
            <label className={styles.filterItemLabel}>
              <CustomCheckbox
                isChecked={data.criticality.low}
                onChange={() =>
                  onChange({
                    ...data,
                    isCriticalityEnabled:
                      data.criticality.high &&
                      data.criticality.medium &&
                      !data.criticality.low
                        ? true
                        : false,
                    criticality: {
                      ...data.criticality,
                      low: !data.criticality.low,
                    },
                  })
                }
              />
              {t('low_full')}
            </label>
          </li>
        </ul>
      </li>
      <li className={styles.filterItem}>
        <label className={styles.filterItemLabel}>
          <CustomCheckbox
            isChecked={data.categories.length === ticketCategories.length}
            onChange={() =>
              onChange({
                ...data,
                categories:
                  data.categories.length === ticketCategories.length
                    ? []
                    : ticketCategories.map(({ id }) => id),
              })
            }
          />
          {t('category')}
        </label>
        <ul className={styles.insideList}>
          {ticketCategories?.map(category => {
            return (
              <li key={category.id} className={styles.filterItem}>
                <label className={styles.filterItemLabel}>
                  <CustomCheckbox
                    isChecked={data.categories.includes(category.id)}
                    onChange={() =>
                      onChange({
                        ...data,
                        categories: data.categories.includes(category.id)
                          ? data.categories.filter(id => id !== category.id)
                          : [...data.categories, category.id],
                      })
                    }
                  />
                  {category.category}
                </label>
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};

export default Filters;
