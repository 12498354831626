import { useQuery } from '@tanstack/react-query';
import type { BreadcrumbComponentType } from './useBreadcrumbs';
import i18next from 'i18next';

export const Accommodation: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(
    ['accommodation', params.accommodationID],
    { enabled: false }
  );

  return data?.name;
};

export const Food: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['food-item', params.foodID], {
    enabled: false,
  });

  return data?.name;
};

export const Dish: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ data: { wares_name: string } }>(
    ['dish', params.dishID],
    {
      enabled: false,
    }
  );

  return data?.data?.wares_name;
};

export const User: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ full_name: string }>(['user', params.userID], {
    enabled: false,
  });

  return data?.full_name;
};

export const Feedback: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ feedback: { formatted_created_at: string } }>(
    ['feedback', params.feedbackID],
    { enabled: false }
  );

  return `${i18next.t('response')} ${
    data?.feedback?.formatted_created_at || ''
  }`;
};

export const Notification: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(
    ['notification', params.notificationID],
    { enabled: false }
  );

  return data?.name;
};

export const Announcement: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(
    ['announcement', params.announcementID],
    { enabled: false }
  );

  return data?.name;
};

export const Request: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ tickets: { name: string } }>(
    ['request', params.requestID],
    { enabled: false }
  );

  return data?.tickets?.name;
};

export const Asset: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ name: string }>(['asset', params.assetID], {
    enabled: false,
  });

  return data?.name;
};

export const Transaction: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ id: number; created_at: string }>(
    ['transaction', params.transactionID],
    { enabled: false }
  );

  return data && `Заказ #${data.id} от ${data.created_at}`;
};

export const Questionnaire: BreadcrumbComponentType = ({ params }) => {
  const { data } = useQuery<{ questionnaire_name: string }>(
    ['questionnaireStats', params.questionnaireID],
    {
      enabled: true,
    }
  );

  return data?.questionnaire_name;
};
