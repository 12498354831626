import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import QuestionnarieStats from '~/components/QuestionnarieStats/QuestionnarieStats';
import { useState } from 'react';
import DownloadStatsButton from './DownloadStatsButton/DownloadStatsButton';
import { useTranslation } from 'react-i18next';

const QuestionnarieStatsPage = (): JSX.Element => {
  const [selectedUser, setSelectedUser] = useState<number | null>(0);
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('questionnaire_results')}
          actions={!selectedUser ? <DownloadStatsButton /> : ''}
        />
      }
    >
      <QuestionnarieStats
        onUserChange={setSelectedUser}
        selectedUser={selectedUser}
      />
    </Page>
  );
};

export default QuestionnarieStatsPage;
