import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './IconButtonLinks.module.scss';

interface IconButtonLinkProps {
  children: ReactNode;
  onClick?: () => void;
  to: string;
  className: string;
}

export const IconButtonLink = ({
  children,
  onClick,
  to,
  className,
}: IconButtonLinkProps) => {
  return (
    <Link
      to={to}
      className={cn(styles.iconButtonLink, className)}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
