import { ChangeEvent } from 'react';
import Search from '~/assets/svg/newSvg/search.svg?react';
import styles from './SearchInput.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ value, onChange }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div onClick={e => e.stopPropagation()} className={styles.inputWrapper}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.input}
        placeholder={t('search')}
      />
      <Search className={styles.search} />
    </div>
  );
};
