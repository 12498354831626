import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, InputBar } from '~/ui/index';
import styles from './OrderForm.module.scss';
import { OrderTable } from './OrderTable/OrderTable';

export interface OrderItem {
  uniqueId: string;
  orderIdDate: string;
  status: string;
  order: any;
  orderPrice: string;
  food_name: string;
}

interface Props {
  order: OrderItem;
}

export const OrderForm = ({ order }: Props) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const formik = useFormik({
    initialValues: {
      orderIdDate: '',
      status: '',
      order: [],
      orderPrice: '',
      uniqueId: '',
    },

    onSubmit: () => {
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (!order) return;

    formik.setValues({
      orderIdDate: order.orderIdDate,
      status: order.status,
      order: order.order,
      orderPrice: order.orderPrice,
      uniqueId: order.uniqueId,
    });
  }, [order]);

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.infoWrapper}>
          <InputBar
            name="orderIdDate"
            value={formik.values.orderIdDate}
            disabled={true}
            label="Номер и дата заказа"
          />

          <InputBar
            name="user"
            value={formik.values.uniqueId}
            disabled={true}
            label="ID транзакции"
          />

          <InputBar
            name="orderDate"
            value={formik.values.orderPrice}
            disabled={true}
            label="Сумма заказа"
          />
        </div>

        <label className={styles.input__label}>
          <span className={styles.input__text}>Заказ</span>
        </label>
        <OrderTable order={order.order} />

        <Button
          type="submit"
          text="Вернуться к списку"
          className={styles.btn}
          onClick={goBack}
          disabled={false}
        />
      </form>
    </div>
  );
};
