import { renderStatus } from '../../utils';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './FeedbacksTable.module.scss';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useContext } from 'react';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

export interface Feedback {
  id: number;
  grade_name: string;
  user_name: string;
  formatted_created_at: string;
  description: string;
  category_name: string;
  feedback_status: { id: number };
  created_at: string;
  general: number;
}

interface FeedbacksTableProps {
  feedbacks: Feedback[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  handleToggleAll: () => void;
  handleSelect: (id: number) => void;
  selectedItems: number[];
}

export const FeedbacksTable = ({
  feedbacks,
  sort,
  setSort,
  handleToggleAll,
  handleSelect,
  selectedItems,
}: FeedbacksTableProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const isDeleteAvailable = checkIfUserSuperAdmin(user?.data.role_name);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          {isDeleteAvailable ? (
            <TableCellHead
              className={styles.tableCellHead}
              sortable={false}
              field="checkbox"
            >
              <CustomCheckbox
                isChecked={
                  selectedItems.length === feedbacks.length &&
                  !!feedbacks.length
                }
                onChange={handleToggleAll}
              />
            </TableCellHead>
          ) : (
            ''
          )}
          <TableCellHead field="grade_name">{t('type')}</TableCellHead>
          <TableCellHead field="user_name">{t('employee')}</TableCellHead>
          <TableCellHead field="created_at">{t('feedback_date')}</TableCellHead>
          <TableCellHead field="description">{t('comment')}</TableCellHead>
          <TableCellHead field="rating">{t('average_rating')}</TableCellHead>
          <TableCellHead field="category_name">{t('category')}</TableCellHead>
          <TableCellHead field="feedback_status">{t('status')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {feedbacks.map(
            ({
              id,
              grade_name,
              user_name,
              formatted_created_at,
              description,
              category_name,
              feedback_status,
              general,
            }: Feedback) => (
              <TableRow key={id}>
                {isDeleteAvailable ? (
                  <TableCell>
                    <CustomCheckbox
                      isChecked={selectedItems.includes(id)}
                      onChange={() => handleSelect(id)}
                    />
                  </TableCell>
                ) : (
                  ''
                )}
                <TableCell>{grade_name}</TableCell>
                <TableCell>{user_name}</TableCell>
                <TableCell>{formatted_created_at}</TableCell>
                <TableCell className={styles.descriptionCell}>
                  <TooltipText text={description} />
                </TableCell>
                <TableCell>
                  {Number(general) ? Number(general)?.toFixed(2) : ''}{' '}
                  {t('points')}
                </TableCell>
                <TableCell>{category_name}</TableCell>
                <TableCell>{renderStatus(feedback_status.id)}</TableCell>
                <TableCell>
                  <div className={styles.feedbacksTableActions}>
                    <IconButtonLink
                      to={`/feedback/${id}`}
                      className={styles.feedbacksTableBtnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
