import { useFormik } from 'formik';
import { Button, InputBar } from '~/ui';
import { Switch, Thumb } from '@radix-ui/react-switch';
import styles from './RaitingQuestionForm.module.scss';
import { getRatings } from './utils';

export const RaitingQuestionForm = () => {
  const formValues = {
    question: '',
    multiple: true,
    required: true,
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: values => {
      console.log('values', values);
    },
  });
  const ratings = getRatings();

  return (
    <div>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <span className={styles.questionTitle}>Вопрос</span>
        <InputBar
          name="question"
          value={formik.values.question}
          classNameInput={styles.questionInput}
          label=""
          placeholder="Задайте вопрос"
          onChange={formik.handleChange}
        />

        <span className={styles.listTitle}>Оценка</span>
        <div className={styles.answersWrapper}>
          <ul className={styles.answersList}>
            {ratings.map(rating => (
              <li key={rating.id} className={styles.answersItem}>
                <img src={rating.image} className={styles.raitingImg} />
                <p className={styles.raitingVal}>{rating.value}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.chooseWrapper}>
          <ul className={styles.switchList}>
            <li className={styles.switchItem}>
              <label htmlFor="multiple" className={styles.switchLabel}>
                Выбор нескольких ответов
              </label>
              <Switch
                className={styles.switch}
                id="multiple"
                name="multiple"
                checked={formik.values.multiple}
                onCheckedChange={() => {
                  formik.setFieldValue('multiple', !formik.values.multiple);
                }}
              >
                <Thumb className={styles.thumb} />
              </Switch>
            </li>
            <li className={styles.switchItem}>
              <label htmlFor="required" className={styles.switchLabel}>
                Обязательное поле
              </label>
              <Switch
                className={styles.switch}
                id="required"
                name="required"
                checked={formik.values.required}
                onCheckedChange={() => {
                  formik.setFieldValue('required', !formik.values.required);
                }}
              >
                <Thumb className={styles.thumb} />
              </Switch>
            </li>
          </ul>
        </div>
        <Button
          type="submit"
          text="Сохранить и выйти"
          className={styles.formBtn}
          disabled={false}
        />
      </form>
    </div>
  );
};
