import { useEffect, useRef } from 'react';
import cn from 'classnames';
import styles from './UploadMenuFile.module.scss';
import Download from '~/assets/svg/newSvg/upload-icon.svg?react';
import { useTranslation } from 'react-i18next';
import LoaderSpinner from '~/components/Shared/LoaderSpinner/LoaderSpinner';

interface Props {
  onChange: (files: File[]) => void;
  className?: string;
  selectedFile: File[] | null;
  isIconInFront?: boolean;
  isLoading?: boolean;
}

export const UploadMenuFile = ({
  selectedFile,
  onChange,
  className,
  isLoading,
}: Props) => {
  const filePicker = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    if (!newFiles) return;

    const updateFiles = Array.from(newFiles);
    onChange(updateFiles);
  };

  const handlePick = () => {
    if (filePicker.current !== null) {
      filePicker.current.click();
    }
  };

  useEffect(() => {
    if (selectedFile) return;

    if (filePicker.current === null) return;
    filePicker.current.value = '';
  }, [selectedFile]);

  return (
    <>
      <div className={styles.upload}>
        <input
          type="file"
          onChange={handleChange}
          accept=".csv, .xls, .xlsx"
          className={cn(styles.uploadInput, className)}
          ref={filePicker}
          multiple
        />
        {isLoading ? (
          <div className={styles.loaderWrapper}>
            <LoaderSpinner />
          </div>
        ) : null}
        <span
          style={isLoading ? { opacity: 0 } : {}}
          onClick={handlePick}
          className={styles.uploadButton}
        >
          <Download /> {`${t('upload')} XLS`}
        </span>
      </div>
    </>
  );
};
