import { useState, useRef } from 'react';

interface Params<T> {
  initialValues: T;
  key?: string;
  exclude: (keyof T)[];
}

export function useLocalStorage<T extends Record<string, any>>({
  initialValues,
  key,
  exclude,
}: Params<T>) {
  const prevNewValues = useRef<T>();

  const [storedInitialValues] = useState(() => {
    if (!key) return initialValues;

    const storage = localStorage.getItem(key);

    if (!storage) return initialValues;
    let updateStorage: T;

    try {
      updateStorage = JSON.parse(storage);
    } catch {
      return initialValues;
    }

    exclude.forEach(field => {
      updateStorage[field] = initialValues[field];
    });

    return updateStorage;
  });

  const clear = () => {
    if (!key) return;
    localStorage.removeItem(key);
  };

  const create = (newValues: T) => {
    if (!key) return;
    if (newValues === prevNewValues.current) return;
    prevNewValues.current = newValues;

    localStorage.setItem(
      key,
      JSON.stringify(newValues, function (field, value) {
        if (this === newValues && exclude.includes(field)) {
          return undefined;
        }
        return value;
      })
    );
  };

  return { initialValues, storedInitialValues, clear, create };
}
