import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { UserValues } from '~/components/UserForm/UserForm';
import Page from '~/components/Shared/Layout/Page/Page';
import { UserForm } from '~/components/UserForm';
import Heading from '~/components/Shared/Heading/Heading';
import styles from './CreateUser.module.scss';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from '~/utils/getUserRole';

const CreateUser = (): JSX.Element => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const { mutateAsync: createUser } = useMutation(
    async ({
      name,
      uniqueId,
      patronymic,
      phone,
      email,
      password,
      organization,
      business_trips,
      grade,
      role,
      accommodations_id,
      assets,
      company_name,
      food_id,
      timezone,
    }: UserValues) => {
      const { data } = await instance.post('admin/users', {
        name,
        uniqueId,
        patronymic,
        phone,
        email,
        password,
        organization,
        grade_id: grade?.id,
        role_id: role.id,
        accommodations_id,
        utc: timezone.id,
        ...(role.name === USER_ROLES.ASSET_ADMIN
          ? { asset_id: assets.id }
          : role.name === USER_ROLES.FOOD_ADMIN
            ? { food_id }
            : { company_name }),
      });

      const businessTripsToSend = business_trips.map(trip => {
        return {
          accommodation_id: trip.accommodation.id,
          start_date: trip.start_date,
          end_date: trip.end_date,
          user_id: data.user.id,
          room_id: trip.roomId || null,
        };
      });
      await instance.post('admin/business-trip', businessTripsToSend);
      return data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['users']);
        toastSuccess(t('employee_added'));
        navigate('/user');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={t('adding_new_employee')}
          classNameText={styles.createUserPageText}
        />
      }
    >
      <UserForm
        onSubmit={values => createUser(values)}
        storageKey="create-user"
      />
    </Page>
  );
};

export default CreateUser;
