import { phraseValidate } from '../utils/regex';
import i18next from 'i18next';

export const validate = values => {
  const errors = {};

  // comment

  if (!values.comment) {
    errors.comment = i18next.t('comment_validate');
  }

  // Time and date
  if (values.condition === '1') {
    if (!values.time) {
      errors.time = i18next.t('time_validate');
    }

    if (!values.date) {
      errors.date = i18next.t('date_validate');
    }

    if (!values.date) {
      errors.date = i18next.t('date_validate');
    }

    if (!values.time_zone) {
      errors.time_zone = i18next.t('timezone_validate');
    }
  }

  if (values.group_send === '2') {
    if (!values.user_id.length) {
      errors.user_id = i18next.t('user_notification_validate');
    }
  }

  return errors;
};
