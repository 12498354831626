import { useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useSelect } from 'downshift';
import { SearchInput } from '..';
import cn from 'classnames';
import styles from './Select.module.scss';
import { SelectedItems } from './SelectedItems';
import ErrorField from '../ErrorField';
import { useTranslation } from 'react-i18next';

interface Option {
  id: number;
  name: React.ReactNode;
  full_name?: string;
  section?: string | null;
  patronymic?: string;
  wares_name?: string;
  code?: string;
}

type Props = {
  name?: string;
  options: Option[];
  searchValue?: string;
  onSearchValueChange?: (searchValue: string) => void;
  onEndReached?: () => void;
  label?: string;
  star: boolean;
  size?: string;
  disabled?: boolean;
  search?: boolean;
  className?: string;
  labelStyle?: string;
  textStyle?: string;
  placeholder?: string;
  error?: string;
  style?: string;
  getValueText?: (id: number) => React.ReactNode;
  selectedStyle?: string;
} & (
  | {
      value: number | null;
      onChange: (selectedValue: number) => void;
      multiple?: false;
    }
  | {
      value: number[] | null;
      onChange: (selectedValue: number[]) => void;
      multiple: true;
    }
);

export const Select = ({
  name,
  options,
  searchValue,
  onSearchValueChange,
  onEndReached,
  label,
  star,
  size = 'large',
  disabled = false,
  search = true,
  getValueText,
  className,
  labelStyle,
  textStyle,
  selectedStyle,
  placeholder,
  error,
  ...props
}: Props) => {
  const [isErrorShown, setIsErrorShown] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const items = options?.filter(item =>
    props.multiple ? !props.value?.includes(item.id) : props.value !== item.id
  );
  const { t } = useTranslation();

  function defaultGetValueText(id: number) {
    const item = options?.find(item => item?.id === id);
    return item ? item.name : '';
  }

  const valueText = getValueText ? getValueText : defaultGetValueText;

  const rowVirtualizer = useVirtualizer({
    count: items?.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 4,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem: null,
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: props.multiple ? true : false,
            highlightedIndex: 0,
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          if (newSelectedItem) {
            props.multiple
              ? props.onChange([...(props.value || []), newSelectedItem.id])
              : props.onChange(newSelectedItem.id);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= items?.length - 1) {
      if (!onEndReached) return;
      onEndReached();
    }
  }, [items?.length, rowVirtualizer.getVirtualItems(), onEndReached]);

  return (
    <div
      className={cn(styles.select, disabled && styles.isDisabled, props.style)}
    >
      {label ? (
        <label className={styles.selectLabel}>
          <span className={cn(styles.selectText, labelStyle)}>
            {label}
            {star ? <span className={styles.selectStar}>*</span> : null}
          </span>
        </label>
      ) : null}
      <button
        {...getToggleButtonProps()}
        className={cn(styles.selectBtn, className)}
        disabled={disabled}
        type="button"
        onBlur={() => {
          setIsErrorShown(true);
        }}
      >
        <SelectedItems
          placeholder={placeholder}
          textStyle={textStyle}
          selectedStyle={selectedStyle}
          getValueText={valueText}
          isOpen={isOpen}
          disabled={disabled}
          {...props}
        />
      </button>
      {isErrorShown ? <span className={styles.error}>{error}</span> : ''}
      <div
        className={cn(
          styles.selectListWrapper,
          isOpen ? styles.opened : styles.closed
        )}
        style={{
          top: label ? 93 : 68,
        }}
        ref={parentRef}
        onClick={e => e.stopPropagation()}
      >
        <ul
          className={styles.selectList}
          style={{
            height: `${
              rowVirtualizer.getTotalSize() + (size === 'large' ? 40 : 0)
            }px`,
          }}
          {...getMenuProps()}
        >
          {search && onSearchValueChange && (
            <SearchInput
              value={searchValue || ''}
              onChange={e => {
                onSearchValueChange(e.target.value);
              }}
            />
          )}

          {isOpen &&
            rowVirtualizer.getVirtualItems().map(virtualRow => {
              const el = items[virtualRow.index];

              return (
                <li
                  {...getItemProps({
                    key: el?.id,
                    index: virtualRow.index,
                    item: el,
                  })}
                  className={cn(
                    styles.selectItem,
                    highlightedIndex === virtualRow.index &&
                      styles.selectItemHighlight
                  )}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                    top: size === 'large' ? 49 : 8,
                  }}
                >
                  <span
                    className={cn(
                      styles.selectItemText,
                      highlightedIndex === virtualRow.index &&
                        styles.selectItemTextHighlight
                    )}
                  >
                    {el?.full_name ||
                      el?.name ||
                      el?.wares_name + ' / ' + el?.code}
                    &nbsp;
                    {el?.patronymic ? el?.patronymic : null}
                  </span>
                </li>
              );
            })}
        </ul>
        {!options?.length ||
          (options.length === 1 &&
            !!options.find(item => item.id === props.value) && (
              <p className={styles.emptyOption}>{t('nothing_found')}</p>
            ))}
      </div>
      {name && <ErrorField name={name} />}
    </div>
  );
};
