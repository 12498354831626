import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { FeedbacksBoard } from './FeedbacksBoard/FeedbacksBoard';
import { XlsUploadFilePopover } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import XlsReportFeedbacksPopoverContent from './XlsReportFeedbacksPopoverContent/XlsReportFeedbacksPopoverContent';
import { useTranslation } from 'react-i18next';

const Feedbacks = () => {
  const { t } = useTranslation();
  return (
    <Page
      heading={
        <Heading
          text={t('feedback')}
          actions={
            <>
              <XlsUploadFilePopover
                btnText={t('feedback_analytics')}
                content={<XlsReportFeedbacksPopoverContent />}
              />
            </>
          }
        />
      }
    >
      <FeedbacksBoard />
    </Page>
  );
};

export default Feedbacks;
