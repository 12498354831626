import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useFormik } from 'formik';
import PlayIcon from '~/assets/svg/newSvg/play.svg?react';
import { IconButton } from '~/ui';
import styles from './RequestTimerForm.module.scss';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { IStatus } from '../Request';
import { TicketStatus } from '~/pages/Requests/utils';
import Timer from '../Timer';
import { CriticismSelect } from './CriticismSelect/CriticismSelect';

export interface Request {
  id: number;
  ticket_status: number;
  ticket_criticism_id: null | number;
  time_ticket: number | null;
  ticket_processing: null | string;
  ticket_deadline: string;
  time_passed: string;
}

export interface PrevRequest {
  tickets: Request;
}

interface SendData {
  id: number;
  time_ticket: number;
  ticket_criticism_id: number | null;
  ticket_status: number;
}

interface Props {
  request: Request;
  requestID: string;
  statuses: IStatus[] | [];
}

export const RequestTimerForm = ({ request, requestID, statuses }: Props) => {
  const toastError = useToastError();
  const { t } = useTranslation();

  const enabled =
    statuses.find(status => status.id === request.ticket_status)?.type ===
      TicketStatus.IN_PROGRESS ||
    statuses.find(status => status.id === request.ticket_status)?.type ===
      TicketStatus.CREATED;

  const disabled =
    statuses.find(status => status.id === request.ticket_status)?.type ===
      TicketStatus.FINISHED ||
    statuses.find(status => status.id === request.ticket_status)?.type ===
      TicketStatus.EXPIRED;

  const client = useQueryClient();

  const { data: criticism } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/ticket-criticisms');
      return response.data;
    },
    queryKey: ['criticisms'],
    onError: error => {
      toastError(error);
    },
  });

  const { mutate: closeRequest } = useMutation(
    async ({
      id,
      time_ticket,
      ticket_criticism_id,
      ticket_status,
    }: SendData) => {
      const response = await instance.post(`admin/ticket/${id}`, {
        time_ticket,
        ticket_criticism_id,
        ticket_status,
      });
      return response.data;
    },
    {
      onSuccess: (newData: Request) => {
        client.invalidateQueries(['notifications-count']);
        client.setQueryData(
          ['request', requestID],
          (prevRequest: PrevRequest | undefined) => {
            if (!prevRequest) return;
            const newRequest = {
              ...prevRequest.tickets,
              ticket_status: newData.ticket_status,
              ticket_processing: newData.ticket_processing,
              ticket_deadline: newData.ticket_deadline,
              time_ticket: newData.time_ticket,
            };

            return { tickets: newRequest };
          }
        );
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const convertStandardTime = (timeString: string) => {
    if (typeof timeString === 'string') {
      const timeArray = timeString.split(':');
      return (
        Number(timeArray[0]) * 3600 * 24 +
        Number(timeArray[1]) * 3600 +
        Number(timeArray[2]) * 60
      );
    }
    const hours = dayjs(timeString).get('hour');
    const minutes = dayjs(timeString).get('minute');
    const seconds = dayjs(timeString).get('second');
    return (
      Number(hours) * 3600 * 24 + Number(minutes) * 3600 + Number(seconds) * 60
    );
  };

  const formik = useFormik({
    initialValues: {
      criticism: null,
      time: '',
      time_ticket: 0,
    },

    onSubmit: values => {
      closeRequest({
        id: request.id,
        time_ticket: convertStandardTime(values.time),
        ticket_criticism_id: values.criticism,
        ticket_status:
          statuses.find(status => status.type === TicketStatus.IN_PROGRESS)
            ?.id || 0,
      });
    },
  });

  const disabledBtn =
    formik.values.criticism === null || formik.values.time === '' || disabled;

  const setFieldValue = formik.setFieldValue;

  useEffect(() => {
    if (!request) return;
    const convertSecondsToTime = (seconds: number) => {
      const days = Math.floor(seconds / (3600 * 24));
      const hours = Math.floor((seconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      const padZero = (num: number) => (num < 10 ? `0${num}` : num);

      return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}`;
    };

    setFieldValue(
      'time',
      request.time_ticket ? convertSecondsToTime(request.time_ticket) : ''
    );
    setFieldValue('criticism', request.ticket_criticism_id);
  }, [request, setFieldValue, request.ticket_status]);

  const getDisabledHours = (maxHour: number) => {
    const disabledHours = [];
    for (let i = maxHour + 1; i < 24; i++) {
      disabledHours.push(i);
    }
    return disabledHours;
  };

  const getDisabledMinutes = () => {
    const disabledMinutes = [];
    for (let i = 23 + 1; i < 60; i++) {
      disabledMinutes.push(i);
    }
    return disabledMinutes;
  };

  return (
    <div className={styles.wrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={values => formik.handleSubmit(values)}
      >
        <div className={styles.form__list}>
          <div className={styles.form__item}>
            <div className={`${styles.selectBarRequest} selectBarWrapper`}>
              {criticism && (
                <CriticismSelect
                  label={t('criticality')}
                  value={formik.values.criticism}
                  onChange={value => {
                    formik.setFieldValue('criticism', value);
                  }}
                  disabled={disabled}
                  options={criticism}
                  star={true}
                />
              )}
            </div>
          </div>
          <div className={styles.form__item}>
            <div className={styles.form__itemTimePicker}>
              <span className={styles.labelText}>
                {t('standard_time')}
                <span className={styles.labelStar}>*</span>
              </span>
              <DatePicker.TimePicker
                name="time"
                className={styles.date}
                format="HH:mm:ss"
                showNow={false}
                disabledTime={() => ({
                  disabledHours: () => getDisabledHours(5),
                  disabledMinutes: () => getDisabledMinutes(),
                })}
                placeholder={t('time_select')}
                onChange={dateString => {
                  setFieldValue('time', dateString);
                }}
                value={
                  formik.values.time
                    ? dayjs(formik.values.time, 'HH:mm:ss')
                    : null
                }
                disabled={disabled}
                hideDisabledOptions
              />
            </div>
            <IconButton
              type="submit"
              className={styles.form__button}
              isDisabled={disabledBtn}
            >
              <PlayIcon />
            </IconButton>
          </div>
          <div className={styles.form__item}>
            <Timer expiryTime={request.ticket_deadline} enabled={enabled} />
          </div>
        </div>
      </form>
    </div>
  );
};
