import styles from './FinishQuestionnarie.module.scss';

const FinishQuestionnarie = () => {
  return (
    <span className={styles.finishQuestionnarie}>
      Спасибо за прохождение анкеты!
    </span>
  );
};

export default FinishQuestionnarie;
