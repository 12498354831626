import { DatePicker } from 'antd';
import React, { useEffect } from 'react';
import { TableCell, TableRow } from '~/ui/Table';
import DatePickerIcon from '~/assets/svg/datePicker/datePickerIcon.svg?react';
import styles from './EditRow.module.scss';
import { IconButton } from '~/ui';
import dayjs, { Dayjs } from 'dayjs';
import { ParamsProps } from '~/utils/api/queries';
import { AccommodationItem } from '~/components/AccommodationForm';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import CheckIcon from '~/assets/svg/newSvg/mark-icon.svg?react';
import { useTranslation } from 'react-i18next';
import { IRoom } from '~/components/UserForm/BusinessTripForm';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { IAccommodation } from '../BusinessTripTable';

interface IEditRowProps {
  id: number;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  startDateToEdit: string;
  endDateToEdit: string;
  accommodationToEdit: AccommodationItem;
  accommodation: IAccommodation;
  params: ParamsProps;
  room: IRoom;
  setRoom: (value: IRoom) => void;
  setAccomodation: (accommodation: IAccommodation) => void;
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  updateTrip: (id: number) => void;
  clickStopEditing: () => void;
}

const EditRow: React.FC<IEditRowProps> = ({
  id,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startDateToEdit,
  endDateToEdit,
  accommodationToEdit,
  accommodation,
  room,
  setRoom,
  setAccomodation,
  params,
  updateTrip,
  clickStopEditing,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    setStartDate(dayjs(startDateToEdit));
    setEndDate(dayjs(endDateToEdit));
    setAccomodation(accommodationToEdit);
  }, []);

  return (
    <TableRow key={id}>
      <TableCell>
        <DatePicker
          className={styles.BusinessTripDatePicker}
          allowClear={false}
          suffixIcon={<DatePickerIcon />}
          disabledDate={d => !d || d.isAfter(endDate)}
          format="DD.MM.YYYY"
          placeholder={t('date')}
          value={startDate}
          onChange={date => {
            setStartDate(date);
          }}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.BusinessTripDatePicker}
          allowClear={false}
          suffixIcon={<DatePickerIcon />}
          disabledDate={d => !d || d.isBefore(startDate)}
          format="DD.MM.YYYY"
          placeholder={t('date')}
          value={endDate}
          onChange={date => {
            setEndDate(date);
          }}
        />
      </TableCell>
      <TableCell>
        <CustomQuerySelect
          label=""
          query="admin/accommodations"
          queryKey={['accommodations', params]}
          placeholder={t('accommodations_validate')}
          params={params}
          value={accommodation}
          onChange={value => {
            if (!Array.isArray(value) && value?.name) {
              setAccomodation(value);
              setRoom({
                id: 0,
                name: '',
              });
            }
          }}
          required
          isSearchEnabled
        />
      </TableCell>
      <TableCell className={styles.roomCell}>
        <CustomQuerySelect
          onChange={value => {
            if (!Array.isArray(value) && value?.name) {
              setRoom(value);
            }
          }}
          value={room}
          query={'admin/rooms'}
          queryKey={[
            'rooms',
            {
              sort: '',
              perPage: 100,
              field: '',
              page: 1,
              accommodation_id: accommodation.id,
            },
          ]}
          params={{
            sort: '',
            perPage: 100,
            field: '',
            page: 1,
            accommodation_id: accommodation.id,
          }}
          placeholder={t('validate_room')}
          required
        />
      </TableCell>
      <TableCell>
        <div className={styles.BusinessTripTableActions}>
          <IconButton
            onClick={() => updateTrip(id)}
            className={styles.BusinessTripTableBtnLink}
          >
            <CheckIcon />
          </IconButton>
          <IconButton onClick={clickStopEditing}>
            <Basket />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EditRow;
