import { useTranslation } from 'react-i18next';
import styles from '../Answer.module.scss';

interface IAnswerOpenProps {
  name: string;
  required: boolean;
  value: string | number;
  onChange: (value: any) => void;
}

const AnswerOpen = ({ name, required, value, onChange }: IAnswerOpenProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.answerClosedContainer}>
      <span className={styles.answerTitle}>
        {name}
        {required && <span className={styles.requiredStar}>*</span>}
      </span>
      <input
        className={styles.openInput}
        type="text"
        placeholder={t('enter_answer')}
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};

export default AnswerOpen;
