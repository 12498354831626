import { getGrades } from './grades';
import s from './Grade.module.scss';

function Grade(props: any) {
  const { value } = props;

  const grades = getGrades();

  // @ts-expect-error
  const { icon, title } = grades[value];

  return (
    <div className={s.container}>
      <div className={s.icon}>
        <img src={icon} alt={title} />
      </div>
      <div className={s.title}>{title}</div>
    </div>
  );
}

export default Grade;
