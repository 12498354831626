import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import styles from './Settings.module.scss';
import { useToastError } from '~/utils/useToastError';
import { useEffect } from 'react';
import { instance } from '~/utils/api/api';
import { ButtonWithChildren } from '~/ui/ButtonWithChildren/ButtonWithChildren';
import SpinnerCircle from '~/ui/SpinnerCircle/SpinnerCircle';
import { useToastSuccess } from '~/utils/useToastSuccess';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';

const Settings = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const languageData: {
    id: number;
    name: string;
    type: string;
  }[] = [
    { id: 1, name: t('russian'), type: 'ru' },
    { id: 2, name: t('english'), type: 'en' },
  ];

  const getSettings = async () => {
    try {
      await instance.get('admin/settings');
    } catch (error) {
      toastError(error);
    }
  };

  const getCurrentLanguageId = () => {
    return languageData.find(item => item.type === i18n.language);
  };

  const initialValues = {
    language: getCurrentLanguageId(),
  };

  const formik = useFormik({
    initialValues,

    onSubmit: async values => {
      try {
        await instance.post('admin/settings', {
          language_id: values.language?.id,
        });
        i18n.changeLanguage(values.language?.type);
        formik.resetForm({
          values: { language: getCurrentLanguageId() },
        });
        toastSuccess(t('settings_updated'));
      } catch (error) {
        toastError(error);
      }
    },
  });

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Page heading={<Heading text={t('settings')} />}>
      <div className={styles.formContainer}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <CustomSelect
            label={t('language')}
            value={languageData.find(
              language => language.id === formik.values.language?.id
            )}
            onChange={value => {
              formik.setFieldValue('language', value);
            }}
            placeholder={t('validate_language')}
            options={languageData}
          />
          <ButtonWithChildren type="submit" className={styles.formBtn}>
            {formik.isSubmitting ? <SpinnerCircle /> : t('save_n_exit')}
          </ButtonWithChildren>
        </form>
      </div>
    </Page>
  );
};

export default Settings;
