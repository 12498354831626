import DoughnutChart from './DoughnutChart/DoughnutChart';
import StatChip from '../StatChip/StatChip';
import styles from './TicketsStats.module.scss';
import { useTranslation } from 'react-i18next';

export interface ITicketStats {
  all_count: number;
  statuses: {
    created: number;
    closed: number;
    completed: number;
    expired: number;
    in_progress: number;
  };
}

interface ITicketsStatsProps {
  orderStats: ITicketStats;
}

const TicketsStats = ({ orderStats }: ITicketsStatsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.ordersStats}>
      <div className={styles.chart}>
        <DoughnutChart orderStats={orderStats} />
      </div>
      <div className={styles.stats}>
        <StatChip
          label={t('done')}
          color="#7367F0"
          amount={orderStats?.statuses?.completed}
        />
        <StatChip
          label={t('status_closed_2')}
          color="rgba(244,243,254,255)"
          amount={orderStats?.statuses?.closed}
        />
        <StatChip
          label={t('status_created_2')}
          color="rgba(115, 103, 240, 0.4)"
          amount={orderStats?.statuses?.created}
        />
        <StatChip
          label={t('status_in_progress')}
          color="rgba(244,243,254,255)"
          amount={orderStats?.statuses?.in_progress}
        />
        <StatChip
          label={t('status_expired_2')}
          color="rgba(199,194,249,255)"
          amount={orderStats?.statuses?.expired}
        />
      </div>
    </div>
  );
};

export default TicketsStats;
