import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import Heading from '~/components/Shared/Heading/Heading';
import { UserForm, UserItem, UserValues } from '~/components/UserForm';
import Page from '~/components/Shared/Layout/Page/Page';
import { Button } from '~/ui';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useTranslation } from 'react-i18next';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import styles from './EditUser.module.scss';
import { USER_ROLES } from '~/utils/getUserRole';

const EditUser = (): JSX.Element => {
  const { userID } = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const client = useQueryClient();
  const { t } = useTranslation();

  const {
    data: user,
    isLoading,
    isFetching,
  } = useQuery<UserItem>({
    queryFn: async () => {
      const response = await instance.get(`admin/users/${userID}`);
      return response.data.users.data;
    },
    queryKey: ['user', userID],
    onError: error => {
      toastError(error);
    },
  });

  const { mutateAsync: deleteUser } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`admin/users/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        toastSuccess(t('employee_deleted'));
        client.invalidateQueries(['users']);

        navigate('/user');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: updateUser } = useMutation(
    async ({
      name,
      uniqueId,
      patronymic,
      phone,
      email,
      password,
      business_trips,
      company_name,
      grade,
      role,
      accommodations_id,
      assets,
      timezone,
      food_id,
    }: UserValues) => {
      const requestBody: Partial<UserValues> = {
        name,
        uniqueId,
        patronymic,
        phone,
        email,
        business_trips,
        company_name,
        grade_id: grade?.id,
        role_id: role.id,
        accommodations_id,
        utc: timezone.id,
        ...(role.name === USER_ROLES.ASSET_ADMIN
          ? { asset_id: assets.id }
          : role.name === USER_ROLES.FOOD_ADMIN
            ? { food_id }
            : { company_name }),
      };

      if (password !== user?.password) {
        requestBody.password = password;
      }

      const response = await instance.patch(
        `admin/users/${userID}`,
        requestBody
      );

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['users']);
        toastSuccess(t('employee_edited'));
        navigate('/user');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={user?.full_name}
          badge={
            user?.block_list.length ? (
              <Tooltip
                className={styles.tooltip}
                title={`${t('blocked_in')} ${user?.block_list
                  .map(blockItem => blockItem.categories.display_name)
                  .join(', ')}`}
              >
                <span className={styles.blockedBadge}>{t('blocked')}</span>
              </Tooltip>
            ) : (
              <Tooltip className={styles.tooltip} title={t('not_blocked')}>
                <span className={styles.activeBadge}>{t('is_active')}</span>
              </Tooltip>
            )
          }
          actions={
            <Button
              color="red"
              disabled={false}
              text={t('delete_employee')}
              onClick={() => deleteUser(userID as string)}
            />
          }
        />
      }
    >
      <UserForm
        isLoading={isFetching || isLoading}
        user={user}
        isEditing={true}
        onSubmit={values => updateUser(values)}
      />
    </Page>
  );
};

export default EditUser;
