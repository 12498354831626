import { useEffect } from 'react';
import { Spinner } from '~/ui';
import getOS from '~/utils/getOS';
import s from './MobileApplicationRedirect.module.scss';

const IOS_LINK = 'https://apps.apple.com/us/app/oazis-pro/id6464465514';

const ANDROID_LINK =
  'https://play.google.com/store/apps/details?id=com.db.oazis.android';

const MobileApplicationRedirect = () => {
  useEffect(() => {
    const os = getOS();
    switch (os) {
      case 'Android':
      case 'unknown':
        window.location.href = ANDROID_LINK;
        break;
      case 'iOS':
        window.location.href = IOS_LINK;
        break;
    }
  }, []);

  return (
    <div className={s.container}>
      <Spinner />
    </div>
  );
};

export default MobileApplicationRedirect;
