import { Link, Outlet, useLocation } from 'react-router-dom';
import BackIcon from './images/back-icon.svg?react';
import { useTranslation } from 'react-i18next';

import styles from './FaqMobile.module.scss';

const locationsNames: any = {
  '/faq': 'help',
  '/faq/auth': 'auth_problem',
  '/faq/app': 'app_work',
  '/faq/add': 'app_info',
};

const FaqMobile = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className={styles.faqMobile}>
      <header className={styles.header}>
        {location.pathname === '/faq' ? (
          <h2 className={styles.title}>
            {t(locationsNames[location.pathname])}
          </h2>
        ) : (
          <Link className={styles.backLink} to="/faq">
            <BackIcon />
            <h2 className={styles.title}>
              {t(locationsNames[location.pathname])}
            </h2>
            <div />
          </Link>
        )}
      </header>
      <div className={styles.questionsListWrapper}>
        <Outlet />
      </div>
    </div>
  );
};

export default FaqMobile;
