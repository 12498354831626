import { phraseValidate, numberValidate } from '../utils/regex';
import i18next from 'i18next';

export const validate = values => {
  const errors = {};

  // name

  if (!values.name) {
    errors.name = i18next.t('name_validate_secondary');
  } else if (values.name.length < 2) {
    errors.name = i18next.t('at_least_two_characters');
  } else if (values.name.length >= 32) {
    errors.name = i18next.t('name_length_max_validate');
  }

  //   asset

  if (!values.assets) {
    errors.assets = i18next.t('validate_asset');
  }

  //   address

  if (!values.address) {
    errors.address = i18next.t('address_validate');
  } else if (values.address.length < 2) {
    errors.address = i18next.t('at_least_two_characters');
  } else if (values.address.length > 255) {
    errors.address = i18next.t('no_more_than_255');
  }

  // floors

  if (values.floors ? !numberValidate(values.floors) : false) {
    errors.floors = i18next.t('numbers_validate');
  }

  // rooms
  if (values.rooms ? !numberValidate(values.rooms) : false) {
    errors.rooms = i18next.t('numbers_validate');
  }

  // beds
  if (values.beds ? !numberValidate(values.beds) : false) {
    errors.beds = i18next.t('numbers_validate');
  }

  return errors;
};
