import { useTimer } from '~/utils/useTimer';
import cn from 'classnames';
import styles from './Timer.module.scss';

interface TimerProps {
  expiryTime: string;
  enabled: boolean;
  colorTime: string;
}

const Timer = ({ expiryTime, enabled, colorTime }: TimerProps) => {
  const { days, hours, minutes, seconds, duration } = useTimer({
    expiryTime: expiryTime,
    enabled: enabled,
  });

  return (
    <>
      <span
        className={cn(styles.timerText, {
          [styles[`timerText--${colorTime}`]]: !duration,
        })}
      >{`${days}:${hours}:${minutes}:${seconds}`}</span>
    </>
  );
};

export default Timer;
