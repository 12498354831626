import { useFormik } from 'formik';
import { Button, InputBar } from '~/ui';
import { Switch, Thumb } from '@radix-ui/react-switch';
import styles from './OpenQuestionForm.module.scss';

interface QuestionValue {
  question: string;
  required: boolean;
}

export const OpenQuestionForm = () => {
  const formValues = {
    question: '',
    required: true,
  };

  const formik = useFormik<QuestionValue>({
    initialValues: formValues,
    onSubmit: values => {
      console.log('values', values);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <span className={styles.questionTitle}>Вопрос</span>
        <InputBar
          name="question"
          value={formik.values.question}
          classNameInputWrapper={styles.questionInputWrapper}
          classNameInput={styles.questionInput}
          label=""
          placeholder="Задайте вопрос"
          onChange={formik.handleChange}
        />
        <div className={styles.chooseWrapper}>
          <label htmlFor="required" className={styles.switchLabel}>
            Обязательное поле
          </label>
          <Switch
            className={styles.switch}
            id="required"
            name="required"
            checked={formik.values.required}
            onCheckedChange={() => {
              formik.setFieldValue('required', !formik.values.required);
            }}
          >
            <Thumb className={styles.thumb} />
          </Switch>
        </div>
        <Button
          type="submit"
          text="Сохранить и выйти"
          className={styles.formBtn}
          disabled={!((formik.isValid && formik.dirty) || formik.isSubmitting)}
        />
      </form>
    </div>
  );
};
