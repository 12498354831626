import styles from './PhotoPicker.module.scss';
import { IPhoto } from '../PhotoPickerContainer';
import PhotoPickerItem from './PhotoPickerItem/PhotoPickerItem';

interface IPhotoPickerProps {
  photos: IPhoto[];
  selectedImages: IPhoto[];
  onImageClick: (image: IPhoto) => void;
  isFetching: boolean;
}

const PhotoPicker = ({
  photos,
  selectedImages,
  onImageClick,
  isFetching,
}: IPhotoPickerProps) => {
  return (
    <ul className={styles.photoPicker}>
      {photos?.map(photo => (
        <PhotoPickerItem
          id={photo.id}
          isSelected={
            !!selectedImages?.find(
              selectedImage => selectedImage.id === photo.id
            )
          }
          image_url={photo.full_photo_url}
          onImageClick={() => onImageClick(photo)}
          isFetching={isFetching}
        />
      ))}
    </ul>
  );
};

export default PhotoPicker;
