import { instance } from '~/utils/api/api';
import { PaginatedResponse } from '../types/common';
import { AccommodationItem } from '~/components/AccommodationForm';
import { UserItem } from '~/components/UserForm';
import { FoodPersonItem } from '~/components/FoodPersonForm/FoodPersonForm';
import { FoodItem } from '~/components/FoodForm';

export interface ParamsProps {
  sort: string;
  perPage: number;
  field: string;
  query?: string;
  page?: number;
  food_id?: string;
  asset_id?: number | null;
  questionnaire_id?: number;
}

export interface ModulesProps {
  [key: string]: string;
}

export const getAccommodations = async (
  params: ParamsProps
): Promise<PaginatedResponse<AccommodationItem>> => {
  const response = await instance.get('admin/accommodations', { params });

  return response.data;
};

export const getFoodPoints = async (
  params: ParamsProps
): Promise<PaginatedResponse<FoodItem>> => {
  const response = await instance.get('food-admin', { params });

  return response.data;
};

export const getRooms = async (id: number) => {
  const { data } = await instance.get(`admin/rooms?accommodation_id=${id}`);

  return data;
};

export const getAccommodationtById = async (
  accommodationID: number
): Promise<AccommodationItem> => {
  const response = await instance.get(
    `admin/accommodations/${accommodationID}`
  );
  return response.data;
};

export const getUsers = async (
  params: ParamsProps
): Promise<PaginatedResponse<UserItem>> => {
  const response = await instance.get('admin/users', { params });

  return response.data.users;
};

export const getOrganizations = async (
  params: ParamsProps
): Promise<PaginatedResponse<UserItem>> => {
  const response = await instance.get('admin/organization', { params });
  return response.data;
};

export const getFoodWares = async (
  params: ParamsProps
): Promise<PaginatedResponse<any>> => {
  const response = await instance.get('admin/ifcm-wares-all', { params });
  return response?.data || [];
};

export const getPersons = async (
  params: ParamsProps
): Promise<FoodPersonItem> => {
  const response = await instance.get('admin/food-person', {
    params: {
      food_id: params.food_id,
    },
  });
  return response.data;
};

export const getModules = async (): Promise<ModulesProps> => {
  const response = await instance.get('notification-modules');
  return response.data;
};
