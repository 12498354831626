import { useState } from 'react';
import styles from './PhotoPickerItem.module.scss';
import SelectedIcon from './images/selected-icon.svg?react';
import classNames from 'classnames';

interface IPhotoPickerItemProps {
  id: number;
  isSelected: boolean;
  image_url: string;
  onImageClick: () => void;
  isFetching: boolean;
}

const PhotoPickerItem = ({
  id,
  isSelected,
  image_url,
  onImageClick,
  isFetching,
}: IPhotoPickerItemProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <li key={id} className={styles.photoPickerItem}>
      <button
        type="button"
        onClick={onImageClick}
        className={classNames(
          styles.photoSelect,
          isSelected ? styles.photoSelectSelected : ''
        )}
      >
        {isLoading || isFetching ? <div className={styles.photoLoader} /> : ''}
        <img
          className={styles.photo}
          src={image_url}
          alt="Photoservice Photo"
          draggable={false}
          onLoad={() => setIsLoading(false)}
        />
        {isSelected ? (
          <SelectedIcon className={styles.photoSelectedIcon} />
        ) : null}
      </button>
    </li>
  );
};

export default PhotoPickerItem;
