import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { ButtonLink } from '~/ui';
import { useTranslation } from 'react-i18next';
import QuestionnairesBoard from './QuestionnairesBoard/QuestionnairesBoard';

const Questionnaires = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('questionnaires')}
          actions={
            <ButtonLink
              text={t('add_new_questionnaire')}
              to="/questionnaires/create"
            />
          }
        />
      }
    >
      <QuestionnairesBoard />
    </Page>
  );
};

export default Questionnaires;
