import { useTranslation } from 'react-i18next';
import {
  nameValidate,
  personTelValidate,
  emailValidate,
  phraseValidate,
} from './regex';
import i18next from 'i18next';

export const validate = values => {
  const errors = {};

  // patronymic

  if (!values.patronymic) {
    errors.patronymic = i18next.t('enter_last_name');
  } else if (values.patronymic.length < 2) {
    errors.patronymic = i18next.t('at_least_two_characters');
  } else if (values.patronymic.length >= 32) {
    errors.patronymic = i18next.t('no_more_thirty_characters');
  }

  // name

  if (!values.name) {
    errors.name = i18next.t('enter_first_name');
  } else if (values.name.length < 2) {
    errors.name = i18next.t('at_least_two_characters');
  } else if (values.name.length >= 32) {
    errors.name = i18next.t('no_more_thirty_characters');
  }

  // phone

  if (!values.phone || !personTelValidate(values.phone)) {
    errors.phone = i18next.t('fill_field_format_phone');
  }

  // Email

  if (!values.email) {
    errors.email = i18next.t('fill_field');
  } else if (!emailValidate(values.email)) {
    errors.email = i18next.t('incorrect_email');
  }

  return errors;
};
