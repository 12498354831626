import { useState } from 'react';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import ArrowLightRightSvg from '~/assets/svg/newSvg/arrow-light-right.svg?react';
import ArrowLightLeftSvg from '~/assets/svg/newSvg/arrow-light-left.svg?react';
import { useQuery } from '@tanstack/react-query';
import { IconButton } from '~/ui';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { AccommodationCalendarWeek } from './AccommodationCalendarWeek';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import cn from 'classnames';
import styles from './AccommodationCalendarBoard.module.scss';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@uidotdev/usehooks';

interface Props {
  accommodationID?: string;
}

dayjs.extend(weekOfYear);
dayjs.locale('ru');

export const AccommodationCalendarBoard = ({
  accommodationID,
}: Props): JSX.Element => {
  const [currentWeek, setCurrentWeek] = useState(Number(dayjs().week()));
  const currentYear = Number(dayjs().year());
  const toastError = useToastError();
  const { t } = useTranslation();

  const params = {
    year: currentYear,
    week: currentWeek,
  };
  const debouncedParams = useDebounce(params, 500);

  const startDate = dayjs()
    .week(currentWeek)
    .startOf('week')
    .format('DD/MM/YYYY');

  const endDate = dayjs().week(currentWeek).endOf('week').format('DD/MM/YYYY');
  const dateRange = ` ${startDate} - ${endDate}`;

  const { data } = useQuery({
    queryFn: async () => {
      const response = await instance.get(
        `admin/accommodation-calendar/${accommodationID}`,
        { params }
      );

      return response.data;
    },
    queryKey: ['accommodationsCalendar', debouncedParams],
    onError: error => {
      toastError(error);
    },
  });

  const accommodationCalendar = data?.accommodationCalendar;
  const total = data?.totalCount;

  return (
    <Board>
      <div className={styles.calendarTop}>
        <div className={styles.calendarLeft}>
          <h3 className={styles.calendarTitle}>{t('employment_schedule')}</h3>
          <div className={styles.calendarLegend}>
            <span
              className={cn(
                styles.calendarLegendTotal,
                styles.calendarLegendItem
              )}
            >
              {t('busy')}
            </span>
            <span
              className={cn(
                styles.calendarLegendBusy,
                styles.calendarLegendItem
              )}
            >
              {t('total')}
            </span>
          </div>
        </div>
        <div className={styles.calendarRight}>
          <span className={styles.calendarRightDate}>{dateRange}</span>
          <div className={styles.calendarRightActions}>
            <IconButton
              className={styles.calendarBtn}
              onClick={() => setCurrentWeek(prevWeek => prevWeek - 1)}
            >
              <ArrowLightLeftSvg />
            </IconButton>
            <IconButton
              className={styles.calendarBtn}
              onClick={() => setCurrentWeek(prevWeek => prevWeek + 1)}
            >
              <ArrowLightRightSvg />
            </IconButton>
          </div>
        </div>
      </div>
      <AccommodationCalendarWeek
        week={currentWeek}
        calendarData={accommodationCalendar}
        total={total}
      />
    </Board>
  );
};
