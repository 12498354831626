import cn from 'classnames';
import styles from './Toggle.module.scss';

interface IToggleProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

const Toggle = ({ isChecked, onChange }: IToggleProps) => {
  return (
    <label
      className={cn(styles.toggle, isChecked ? styles.toggleChecked : '')}
      tabIndex={0}
      onKeyUp={event => {
        if (event.key === 'Enter' || event.key === ' ') {
          onChange(!isChecked);
        }
      }}
    >
      <input
        type="checkbox"
        className={styles.input}
        onChange={() => onChange(!isChecked)}
      />
      <div className={cn(styles.thumb, isChecked ? styles.thumbActive : '')} />
    </label>
  );
};

export default Toggle;
