import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import cn from 'classnames';
import { InputBar, Select } from '~/ui';
import form from '~/theme/ui/form.module.scss';
import button from '~/theme/ui/button.module.scss';
import SwitchInput from '~/ui/SwitchInput';
import AddIcon from '~/assets/svg/newSvg/actions/add-icon-empty.svg?react';
import styles from './EditQuestionForm.module.scss';
import FieldGroup from '~/ui/FieldGroup';
import Grade from '~/ui/Grade';
import { getTypeOptions, types } from '../QuestionForm/typeOptions';
import { QuestionItem } from '../QuestionForm';
import ClosedAnswersForm from './ClosedAnswersForm/ClosedAnswersForm';
import { IVariant } from '../QuestionnaireForm';
import { useTranslation } from 'react-i18next';

const QuestionSchema = yup.object().shape({
  id: yup.number().optional(),
  name: yup.string().required('question_name_validate'),
  type: yup.number().required(),
  answer: yup.string(),
  required: yup.boolean(),
  multi_answer: yup.boolean(),
  variants: yup.array().optional(),
});

export type QuestionValues = yup.InferType<typeof QuestionSchema>;

export interface IFormikValues {
  id: number;
  name: string;
  type: number;
  answer: string;
  required: boolean;
  multi_answer: boolean;
  variants: IVariant[] | [];
  questions_closed_variants?: IVariant[] | [];
}

interface IIndexable {
  [key: string]: number;
}

interface QuestionFormProps {
  question: QuestionItem;
  handleCloseCreate: () => void;
  clickUpdateQuestion: (
    values: any,
    formik: FormikValues,
    initialValues?: QuestionValues
  ) => void;
}
const EditQuestionForm = ({
  question,
  handleCloseCreate,
  clickUpdateQuestion,
}: QuestionFormProps) => {
  const initialValues: IFormikValues = {
    id: question.id,
    name: question.name,
    type: (types as IIndexable)[question.type],
    answer: '',
    required: question?.question_data?.required || false,
    multi_answer: question?.question_data?.multi_answer || false,
    variants: question?.question_data?.question_close_variants || [],
  };
  const { t } = useTranslation();
  const typeOptions = getTypeOptions();

  const handleCloseClick = async (formik: FormikValues) => {
    handleCloseCreate();
    formik.resetForm({ values: initialValues });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={QuestionSchema}
      enableReinitialize
      onSubmit={(values: QuestionValues, formik: FormikValues) =>
        clickUpdateQuestion(values, formik)
      }
    >
      {formik => {
        return (
          <form className={form.container} onSubmit={formik.handleSubmit}>
            <div className={form.group}>
              <InputBar
                name="question"
                label={t('question_title')}
                placeholder={t('question_write')}
                value={formik.values.name}
                disabled={false}
                star={true}
                onChange={event =>
                  formik.setFieldValue('name', event.target.value)
                }
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                errors={t(formik.errors.name || '')}
                classNameInput={styles.input}
              />
              <Select
                name="type"
                label={t('question_type')}
                value={Number(formik.values.type)}
                onChange={value => formik.setFieldValue('type', value)}
                options={typeOptions}
                star={true}
                size="small"
                className={styles.input}
              />
            </div>

            {formik.values.type === types.close && (
              <div className={styles.answersContainer}>
                <ClosedAnswersForm
                  questionId={formik.values.id}
                  variants={formik.values.variants}
                  formik={formik}
                />
              </div>
            )}

            {formik.values.type === types.estimate && (
              <div className={styles.answersContainer}>
                <FieldGroup label={t('estimation')}>
                  {(['bad', 'normal', 'good', 'super'] as const).map(grade => (
                    <FieldGroup.Item key={grade}>
                      <Grade value={grade} />
                    </FieldGroup.Item>
                  ))}
                </FieldGroup>
              </div>
            )}

            <div className={styles.footer}>
              {formik.values.type === types.close && (
                <SwitchInput
                  name="multi_answer"
                  label={t('multiple_answers')}
                />
              )}

              <SwitchInput name="required" label={t('required_question')} />
            </div>

            <div className={cn(form.actions, styles.actions)}>
              <button
                className={button.primary}
                onClick={() => handleCloseClick(formik)}
              >
                {t('cancel')}
              </button>

              <button
                disabled={!formik.isValid}
                type="submit"
                className={button.success}
              >
                <AddIcon className={button.icon} />
                {t('save_n_exit')}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditQuestionForm;
