import { useNavigate, useParams } from 'react-router-dom';
import { useToastError } from '~/utils/useToastError';
import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { InputBar, Button } from '~/ui';
import styles from './EditQuestionnarieForm.module.scss';
import cn from 'classnames';
import QuestionsList from '~/components/QuestionnaireForm/QuestionsList';
import { instance } from '~/utils/api/api';
import { useEffect, useState } from 'react';
import { Questionnaire } from '~/pages/Questionnaires/QuestionnairesBoard/QuestionnairesTable/QuestionnairesTable';
import QuestionForm, { QuestionItem } from '~/components/QuestionForm';
import { Modal } from '~/components/Shared/Modal/Modal';
import { getQuestionPayload } from './utils';
import EditQuestionForm, {
  QuestionValues,
} from '~/components/EditQuestionForm/EditQuestionForm';
import StatIcon from '~/assets/svg/questionnaries/stat-icon.svg?react';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { useQueryClient } from '@tanstack/react-query';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';

interface ITypeOption {
  id: number;
  name: string;
}

const QuestionnaireSchema = yup.object().shape({
  name: yup.string().required('questionnaire_name_validate'),
  type: yup
    .object()
    .shape({
      id: yup.number().required('questionnaire_type_validate'),
      name: yup.string().required('questionnaire_type_validate'),
    })
    .required(),
  publication_date: yup.string().required('date_start_validate'),
  removal_date: yup.string().required('date_end_validate'),
  questionnaire_accommodations: yup
    .array()
    .of(yup.object().required())
    .min(1, 'accommodations_validate'),
  licensor: yup
    .string()
    .max(50, 'licensor_length_validate')
    .required('licensor_validate'),
});

export interface QuestionnairePayload {
  name: string;
  licensor: string;
  questionnaire_accommodations: { id: number; name: string }[];
  questionnaire_organizations: { id: number; name: string }[];
  publication_date: string;
  removal_date: string;
  type: {
    id: number;
    name: string;
  };
}

interface IEditQuestionnarieFormProps {
  questionnaire: Questionnaire;
}

const EditQuestionnarieForm = ({
  questionnaire,
}: IEditQuestionnarieFormProps) => {
  const [typeOptions, setTypeOptions] = useState<ITypeOption[] | []>([]);
  const navigate = useNavigate();
  const { questionnaireID } = useParams();
  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
  const [isEditQuestionModalOpen, setIsEditQuestionModalOpen] = useState(false);
  const [questions, setQuestions] = useState<any[]>(
    questionnaire?.questions || []
  );
  const [editingQuestion, setEditingQuestion] = useState<QuestionItem | null>(
    null
  );
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const client = useQueryClient();
  const { t } = useTranslation();

  const handleOpenAddQuestionModal = () => {
    setIsAddQuestionModalOpen(true);
  };

  const handleCloseAddQuestionModal = () => {
    setIsAddQuestionModalOpen(false);
  };

  const handleOpenEditQuestionModal = (id: number) => {
    setEditingQuestion(questions.find(question => question.id === id));
    setIsEditQuestionModalOpen(true);
  };

  const handleCloseEditQuestionModal = () => {
    setIsEditQuestionModalOpen(false);
  };

  const initialValues = {
    name: questionnaire?.name,
    type: questionnaire?.questionnaire_type,
    publication_date: questionnaire?.publication_date,
    removal_date: questionnaire?.removal_date,
    questionnaire_accommodations: questionnaire?.questionnaire_accommodations,
    questionnaire_organizations: questionnaire?.questionnaire_organizations,
    licensor: questionnaire?.licensor,
  };

  const loadTypeOptions = async () => {
    try {
      const response = await instance.get('admin/questionnaires-type');
      setTypeOptions(response.data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmit = async (
    values: QuestionnairePayload,
    formik: FormikValues
  ) => {
    try {
      const {
        name,
        publication_date,
        removal_date,
        type,
        licensor,
        questionnaire_accommodations,
        questionnaire_organizations,
      } = values;

      const payload = {
        name,
        type_id: type.id,
        accommodation_ids: questionnaire_accommodations.map(
          accomodation => accomodation.id
        ),
        publication_date:
          dayjs(publication_date).format('YYYY-MM-DD') + ' 00:00:00',
        removal_date: dayjs(removal_date).format('YYYY-MM-DD') + ' 00:00:00',
        licensor: licensor,
        organization_ids: questionnaire_organizations.map(
          organization => organization.id
        ),
      };
      await instance.put(`admin/questionnaires/${questionnaireID}`, payload);
      client.invalidateQueries(['questionnaires']);
      formik.resetForm({ values: initialValues });
      navigate('/questionnaires');
      toastSuccess(t('questionnaire_edited'));
    } catch (error) {
      toastError(error);
    }
  };

  const handleCreateQuestion = async (
    values: QuestionValues,
    formik: FormikValues,
    initialValues: QuestionValues
  ) => {
    const payload = getQuestionPayload(values, Number(questionnaireID), true);
    try {
      const response = await instance.post('admin/questions', [payload]);
      formik.resetForm({ values: initialValues });
      setQuestions([...questions, response.data[0]]);
      handleCloseAddQuestionModal();
    } catch (error) {
      toastError(error);
    }
  };

  const handleUpdateQuestion = async (values: QuestionValues) => {
    try {
      const payload = getQuestionPayload(values, Number(questionnaireID));
      await instance.put(`admin/questions/${values?.id}`, payload);
      setQuestions(
        questions.map(question => {
          if (question.id === values?.id) {
            return payload;
          }
          return question;
        })
      );
      handleCloseEditQuestionModal();
    } catch (error) {
      toastError(error);
    }
  };

  const handleDeleteQuestion = async (id: number) => {
    try {
      await instance.delete(`admin/questions/${id}`);
      setQuestions(questions.filter(question => question.id !== id));
      handleCloseAddQuestionModal();
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    loadTypeOptions();
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={QuestionnaireSchema}
      onSubmit={(values, formik) => handleSubmit(values, formik)}
    >
      {formik => {
        return (
          <form className={styles.container} onSubmit={formik.handleSubmit}>
            <div className={styles.formInputs}>
              <div className={styles.group}>
                <InputBar
                  name="name"
                  label={t('anquette_name')}
                  placeholder={t('enter_anquette_name')}
                  value={formik.values.name}
                  disabled={false}
                  star={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  errors={t(formik.errors.name || '')}
                />

                <InputBar
                  name="licensor"
                  label={t('licensor_name')}
                  placeholder={t('enter_licensor_name')}
                  value={formik.values.licensor}
                  disabled={false}
                  star={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.licensor && Boolean(formik.errors.licensor)
                  }
                  errors={t(formik.errors.licensor || '')}
                />
              </div>
              <div className={styles.group}>
                <div className={styles.group__item}>
                  <CustomSelect
                    label={t('questionnaire_subject')}
                    placeholder={t('validate_subject')}
                    value={formik.values.type}
                    onChange={value => formik.setFieldValue('type', value)}
                    options={typeOptions}
                    classNameLabel={styles.typeSelect}
                    required
                    error={t(formik.errors.type?.name || '')}
                  />
                </div>

                <div className={cn(styles.group__item, styles.field)}>
                  <div className={styles.field__label}>
                    {t('start_date_anquette')}{' '}
                    <span className={styles.required}>*</span>
                  </div>
                  <DatePicker
                    name="publication_date"
                    className="datepicker"
                    format="DD.MM.YYYY"
                    value={
                      formik.values.publication_date
                        ? dayjs(formik.values.publication_date)
                        : null
                    }
                    onChange={date => {
                      formik.setFieldValue(
                        'publication_date',
                        date ? date : null
                      );
                      formik.setFieldValue('removal_date', null);
                    }}
                    placeholder={t('date')}
                    disabledDate={(current: Dayjs) =>
                      current && current < dayjs().startOf('day')
                    }
                    onBlur={() =>
                      formik.setFieldTouched('publication_date', true)
                    }
                  />
                  <span className={styles.errorText}>
                    {formik.touched.publication_date &&
                      Boolean(formik.errors.publication_date) &&
                      t('date_start_validate')}
                  </span>
                </div>

                <div className={cn(styles.group__item, styles.field)}>
                  <div className={styles.field__label}>
                    {t('end_date_anquette')}{' '}
                    <span className={styles.required}>*</span>
                  </div>
                  <DatePicker
                    name="removal_date"
                    className="datepicker"
                    format="DD.MM.YYYY"
                    value={
                      formik.values.removal_date
                        ? dayjs(formik.values.removal_date)
                        : null
                    }
                    onChange={date => {
                      formik.setFieldValue('removal_date', date ? date : null);
                    }}
                    placeholder={t('date')}
                    disabledDate={(current: Dayjs) =>
                      current &&
                      current <
                        dayjs(formik.values.publication_date).startOf('day')
                    }
                    onBlur={() => formik.setFieldTouched('removal_date', true)}
                  />
                  <span className={styles.errorText}>
                    {formik.touched.publication_date &&
                      Boolean(formik.errors.publication_date) &&
                      t('date_end_validate')}
                  </span>
                </div>
              </div>
              <CustomQuerySelect
                label={t('accommodation_facility')}
                onChange={value => {
                  formik.setFieldValue('questionnaire_accommodations', value);
                }}
                value={formik.values.questionnaire_accommodations}
                query="admin/accommodations"
                queryKey={[
                  'accommodations',
                  {
                    sort: '',
                    perPage: 100,
                    field: '',
                    page: 1,
                  },
                ]}
                params={{
                  sort: '',
                  perPage: 100,
                  field: '',
                  page: 1,
                }}
                required
                placeholder={t('to_choose')}
                multiselect
                isSearchEnabled
                error={t(formik.errors.questionnaire_accommodations as string)}
              />
              <CustomQuerySelect
                label={t('organizations')}
                query="admin/organization"
                onChange={value => {
                  formik.setFieldValue('questionnaire_organizations', value);
                }}
                placeholder={t('validate_organizations')}
                value={formik.values.questionnaire_organizations}
                isSearchEnabled
                queryKey={[
                  'organizations',
                  {
                    sort: '',
                    perPage: 100,
                    field: '',
                    page: 1,
                  },
                ]}
                params={{
                  sort: '',
                  perPage: 100,
                  field: '',
                  page: 1,
                }}
                required
                multiselect
              />
            </div>

            <div className={styles.questionsTableContainer}>
              <QuestionsList
                questions={questions}
                clickAddQuestion={handleOpenAddQuestionModal}
                clickDeleteQuestion={handleDeleteQuestion}
                clickEditButton={handleOpenEditQuestionModal}
              />
            </div>

            <div className={styles.formControl}>
              <div className={styles.submitContainer}>
                <Button
                  type="submit"
                  text={t('save_n_exit')}
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className={styles.submitContainer}>
                <Button
                  onClick={() =>
                    navigate(`/questionnaires/${questionnaireID}/results`)
                  }
                  type="button"
                  text={t('questionnaire_results')}
                  disabled={formik.isSubmitting}
                  className={styles.statsButton}
                  Icon={StatIcon}
                />
              </div>
            </div>
            <Modal
              isOpen={isAddQuestionModalOpen}
              onClose={handleCloseAddQuestionModal}
              modalStyles={styles.questionModal}
            >
              <QuestionForm
                handleCloseCreate={handleCloseAddQuestionModal}
                clickAddQuestion={handleCreateQuestion}
              />
            </Modal>

            <Modal
              isOpen={isEditQuestionModalOpen}
              onClose={handleCloseEditQuestionModal}
              modalStyles={styles.questionModal}
            >
              {editingQuestion && (
                <EditQuestionForm
                  question={editingQuestion}
                  handleCloseCreate={handleCloseEditQuestionModal}
                  clickUpdateQuestion={handleUpdateQuestion}
                />
              )}
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditQuestionnarieForm;
