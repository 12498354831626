import styles from './StatBar.module.scss';

interface IStatBarProps {
  percent: number;
  label: string;
  explanation?: string;
}

const STATUS_COLORS = {
  HIGH: '#e3e1fc',
  HIGH_TEXT: 'rgba(115, 103, 240, 1)',
  MEDIUM: '#d4f4e2',
  MEDIUM_TEXT: '#71d09b',
  LOW: '#fbdddd',
  LOW_TEXT: '#f29191',
};

const StatBar = ({ percent, label, explanation }: IStatBarProps) => {
  return (
    <div className={styles.statBar}>
      <div className={styles.topLine}>
        <span className={styles.label}>{label}</span>
        {explanation && (
          <span className={styles.explanation}>{explanation}</span>
        )}
      </div>
      <div className={styles.bar}>
        <div
          className={styles.filledBar}
          style={{
            backgroundColor:
              percent > 65
                ? STATUS_COLORS.HIGH
                : percent > 30
                  ? STATUS_COLORS.MEDIUM
                  : STATUS_COLORS.LOW,
            width: `${percent}%`,
          }}
        >
          <span
            style={{
              right: percent < 5 ? '-20px' : percent < 20 ? '-45px' : '0',
              color:
                percent > 65
                  ? STATUS_COLORS.HIGH_TEXT
                  : percent > 30
                    ? STATUS_COLORS.MEDIUM_TEXT
                    : STATUS_COLORS.LOW_TEXT,
            }}
            className={styles.percent}
          >
            {Math.round(percent)} %
          </span>
        </div>
      </div>
    </div>
  );
};

export default StatBar;
