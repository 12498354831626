import { Scanner } from '@yudiel/react-qr-scanner';
import styles from './QrScanner.module.scss';

interface IQrScannerProps {
  handleSuccessScan: (data: { format: string; rawValue: string }[]) => void;
  handleErrorScan: (error: unknown) => void;
}

const QrScanner = ({ handleErrorScan, handleSuccessScan }: IQrScannerProps) => {
  const handleError = (error: unknown) => {
    handleErrorScan(error);
  };

  const handleScan = (data: { format: string; rawValue: string }[]) => {
    handleSuccessScan(data);
  };

  return (
    <div className={styles.scannerWrapper}>
      <Scanner
        components={{ audio: false }}
        formats={['qr_code']}
        onError={handleError}
        onScan={handleScan}
        classNames={{
          container: styles.scannerContainer,
        }}
      />
    </div>
  );
};

export default QrScanner;
