import styles from './OpenAnswerStats.module.scss';
import TextIcon from '~/assets/svg/newSvg/options/text.svg?react';
import { useState } from 'react';
import { pluralize } from '~/utils/helpers';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export interface IOpenAnswer {
  question_id: number;
  question_name: string;
  question_type: string;
  count: number;
  answers: string[];
}

interface IOpenAnswerStats {
  answer: IOpenAnswer;
  isMultiStatHidden?: boolean;
}

const pluralizeVariant = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('answer_plural'),
    i18next.t('answer_plural_2'),
    i18next.t('answer_plural_3'),
  ]);

const OpenAnswerStats = ({ answer, isMultiStatHidden }: IOpenAnswerStats) => {
  const [maxAnswers, setMaxAnswers] = useState(4);
  const { t } = useTranslation();

  return (
    <li className={styles.openAnswerStats}>
      <div className={styles.topLine}>
        <TextIcon />
        <span className={styles.typeName}>{t('open_question')}</span>
      </div>
      <span className={styles.name}>{answer.question_name}</span>
      {isMultiStatHidden && (
        <span className={styles.amount}>{pluralizeVariant(answer.count)}</span>
      )}
      <ul className={styles.answerList}>
        {answer.answers.slice(0, maxAnswers).map(item => {
          return (
            <li key={item} className={styles.answer}>
              {item}
            </li>
          );
        })}
        {answer.count > maxAnswers && (
          <button
            className={styles.showMore}
            type="button"
            onClick={() => setMaxAnswers(maxAnswers + 4)}
          >
            Показать ещё...
          </button>
        )}
      </ul>
    </li>
  );
};

export default OpenAnswerStats;
