import cn from 'classnames';
import s from './FieldGroup.module.scss';

interface FieldGroupProps {
  label: string;
  children: React.ReactNode;
  description?: React.ReactNode;
  className?: string;
}

function FieldGroup(props: FieldGroupProps) {
  const { label, children, description, className } = props;
  return (
    <div className={s.container}>
      <div className={s.label}>{label}</div>
      <div className={cn(s.content, className)}>{children}</div>
      {description && <div className={s.description}>{description}</div>}
    </div>
  );
}

FieldGroup.Item = function ({ children }: React.PropsWithChildren) {
  return <div className={s.item}>{children}</div>;
};

export default FieldGroup;
