import { useEffect, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useSelect } from 'downshift';
import Arrow from '~/assets/svg/newSvg/arrow-select.svg?react';
import cn from 'classnames';
import styles from './CriticismSelect.module.scss';
import { useTranslation } from 'react-i18next';
import { renderCriticismIcon } from '~/pages/Requests/utils';

interface Option {
  id: number;
  name: string;
}
interface Props {
  options: Option[];
  value: number | null;
  onChange: (selectedValue: number) => void;
  label?: string;
  star: boolean;
  disabled?: boolean;
}

export const CriticismSelect = ({
  options,
  value,
  onChange,
  label,
  star,
  disabled = false,
}: Props) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const rowVirtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38,
    overscan: 4,
  });

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;
      onChange(selectedItem.id);
    },
    selectedItem: options.find(option => option.id === value),
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }
  }, [options.length, rowVirtualizer.getVirtualItems()]);

  return (
    <div className={cn(styles.select, isOpen && styles.selectOpen)}>
      <label className={styles.selectLabel}>
        <span className={styles.selectText}>
          {label}
          {star ? <span className={styles.selectStar}>*</span> : null}
        </span>
      </label>
      <button
        {...getToggleButtonProps()}
        className={styles.selectBtn}
        disabled={disabled}
        type="button"
      >
        <div className={styles.selectBtnContent}>
          <span className={styles.selectBtnText}>
            {selectedItem?.name || value || t('to_choose')}
            {renderCriticismIcon(selectedItem?.id || null)}
          </span>
          <Arrow
            className={cn(styles.selectBtnArrow, isOpen && styles.openedArrow)}
          />
        </div>
      </button>
      <div
        className={cn(
          styles.selectListWrapper,
          isOpen ? styles.opened : styles.closed
        )}
        style={{ height: 'auto' }}
        ref={parentRef}
        onClick={e => e.stopPropagation()}
      >
        <ul
          className={styles.selectList}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
          {...getMenuProps()}
        >
          {isOpen &&
            rowVirtualizer.getVirtualItems().map(virtualRow => {
              const el = options[virtualRow.index];

              return (
                <li
                  {...getItemProps({
                    key: el?.id,
                    index: virtualRow.index,
                    item: el,
                  })}
                  className={cn(
                    styles.selectItem,
                    highlightedIndex === virtualRow.index &&
                      styles.selectItemHighlight
                  )}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                    top: 8,
                  }}
                >
                  <span
                    className={cn(
                      styles.selectItemText,
                      highlightedIndex === virtualRow.index &&
                        styles.selectItemTextHighlight
                    )}
                  >
                    {el.name}
                    {renderCriticismIcon(el.id)}
                  </span>
                </li>
              );
            })}
        </ul>
        {!options.length && (
          <p className={styles.emptyOption}>{t('nothing_found')}</p>
        )}
      </div>
    </div>
  );
};
