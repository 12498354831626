export const orders = [
  {
    id: 0,
    uniqueId: '8977620021',
    orderId: '#78',
    orderDate: '24.01.2024',
    orderIdDate: '#78 24.01.2024',
    status: 'Выполнено',
    orderList: 'Брусничный морс, Салат оливье, Суп куриный с лапшой',
    order: [
      {
        id: 0,
        name: 'Брусничный морс',
        category: 'Напиток',
        weight: '200 мл.',
        price: '30',
        amount: 1,
        sum: 30,
      },
      {
        id: 1,
        name: 'Салат оливье',
        category: 'Салат',
        weight: '130 г.',
        price: '90',
        amount: 2,
        sum: 180,
      },
      {
        id: 2,
        name: 'Суп куриный с лапшой',
        category: 'Супы',
        weight: '350 мл.',
        price: '70',
        amount: 1,
        sum: 70,
      },
    ],
    orderPrice: '280',
    food_name: 'Столовая №12',
  },
  {
    id: 1,
    uniqueId: '86789876721',
    orderId: '#21',
    orderDate: '04.02.2024',
    orderIdDate: '#21 04.02.2024',
    status: 'Готовится',
    orderList: 'Сок томатный, Салат цезарь, Суп куриный с лапшой',
    order: [
      {
        id: 0,
        name: 'Сок томатный',
        category: 'Напиток',
        weight: '200 мл.',
        price: '35',
        amount: 1,
        sum: 35,
      },
      {
        id: 1,
        name: 'Салат цезарь',
        category: 'Салат',
        weight: '130 г.',
        price: '120',
        amount: 2,
        sum: 240,
      },
      {
        id: 2,
        name: 'Суп куриный с лапшой',
        category: 'Супы',
        weight: '350 мл.',
        price: '70',
        amount: 1,
        sum: 70,
      },
    ],
    orderPrice: '345',
    food_name: 'Столовая №32',
  },
];
