import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { ButtonLink } from '~/ui';
import { useTranslation } from 'react-i18next';
import { NotificationsBoard } from './NotificationsBoard/NotificationsBoard';

const Notifications = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('notifications')}
          actions={
            <ButtonLink
              text={t('create_new_notification')}
              to="/notifications/create"
            />
          }
        />
      }
    >
      <NotificationsBoard />
    </Page>
  );
};

export default Notifications;
