import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { Button, BUTTON_STYLES, InputBar, Pagination, Spinner } from '~/ui';
import '~/assets/css/select-table.css';
import styles from './UsersBoard.module.scss';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';
import UserBoardActions from '../UserBoardActions/UserBoardActions';
import { UsersTable } from './UsersTable/UsersTable';
import { Modal } from '~/components/Shared/Modal/Modal';
import ChangeUsersStatusModalContent from './ChangeUsersStatusModalContent/ChangeUsersStatusModalContent';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface SelectedPage {
  selected: number;
}

interface Points {
  page: number;
}

export interface IBlockCategory {
  id: number;
  name: string;
  type: string;
  display_name: string;
}

interface Props {
  userTripStatus: string;
}

export const UsersBoard = ({ userTripStatus }: Props) => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<Points>({
      initialValues: { page: 15 },
      key: 'users-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<{
    block: boolean;
    unblock: boolean;
  }>({
    block: false,
    unblock: false,
  });
  const [isStatusUsersModalOpen, setIsStatusUsersModalOpen] = useState(false);
  const client = useQueryClient();
  const [selectedSections, setSelectedSections] = useState<IBlockCategory[]>(
    []
  );
  const { t } = useTranslation();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const handleToggleSelectedSections = (section: IBlockCategory) => {
    if (
      selectedSections.find(
        selectedSection => selectedSection.id === section.id
      )
    ) {
      const filteredSections = selectedSections.filter(
        selectedSection => selectedSection.id !== section.id
      );
      setSelectedSections(filteredSections);
    } else {
      setSelectedSections([...selectedSections, section]);
    }
  };

  const handleBlockUsers = async () => {
    setIsLoading({ ...isLoading, block: true });
    try {
      await instance.post('admin/block-list/block', {
        user_ids: selectedUsers,
        category_ids: selectedSections.map(section => section.id),
      });
      setIsStatusUsersModalOpen(false);
      setSelectedUsers([]);
      client.invalidateQueries(['users']);
      toastSuccess(t('employees_blocked'));
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading({ ...isLoading, block: false });
    }
  };

  const handleUnblockUsers = async () => {
    setIsLoading({ ...isLoading, unblock: true });
    try {
      await instance.post('admin/block-list/unblock', {
        user_ids: selectedUsers,
        category_ids: selectedSections.map(section => section.id),
      });
      setIsStatusUsersModalOpen(false);
      setSelectedUsers([]);
      client.invalidateQueries(['users']);
      toastSuccess(t('employees_blocked'));
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading({ ...isLoading, unblock: false });
    }
  };

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  useEffect(() => {
    setPage(1);
  }, [userTripStatus]);

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    business_trip: userTripStatus,
  };

  const { data, isError, error } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/users', {
        params,
      });
      return response.data;
    },
    queryKey: ['users', params],
    keepPreviousData: true,
  });

  const users = data?.users?.data;

  let content: React.ReactNode;

  const handleSelect = (id: number) => {
    if (selectedUsers.includes(id)) {
      const filteredUsers = selectedUsers.filter(
        selectedId => selectedId !== id
      );
      setSelectedUsers(filteredUsers);
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedUsers.length === data?.users?.data.length) {
      setSelectedUsers([]);
    } else {
      const userIdArray = data?.users?.data.map((user: any) => user.id);
      setSelectedUsers(userIdArray);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await instance.delete('admin/users-many', {
        data: { user_ids: selectedUsers },
      });
      setSelectedUsers([]);
      toastSuccess(t('users_deleted'));
      client.invalidateQueries(['users']);
    } catch (error) {
      toastError(error);
    }
  };

  if (users) {
    content = (
      <UsersTable
        selectedItems={selectedUsers}
        handleToggle={handleSelect}
        handleToggleAll={handleToggleAll}
        users={users}
        sort={sort}
        setSort={setSort}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.usersTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedUsers.length && (
            <Button
              text={t('change_status')}
              buttonStyle={BUTTON_STYLES.EMPTY}
              onClick={() => setIsStatusUsersModalOpen(true)}
              className={styles.deleteButton}
            />
          )}
          {!!selectedUsers.length && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteSelected}
              className={styles.deleteButton}
            />
          )}
          <InputBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            value={searchTerm}
            placeholder={t('search_by_code_n_np')}
            classNameInput={styles.usersTableInput}
            label={`${t('search')}:`}
            star={false}
            classNameInputWrapper={styles.usersTableInputWrapper}
          />
          <UserBoardActions />
        </div>
      </div>
      {content}
      <div className={styles.usersTablePagination}>
        <TableAmountPage
          firstRow={data?.meta?.from}
          lastRow={data?.meta?.to}
          total={data?.meta?.total}
        />
        <Pagination
          forcePage={page || 1}
          pageCount={data?.meta?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
      <Modal
        isOpen={isStatusUsersModalOpen}
        onClose={() => setIsStatusUsersModalOpen(false)}
        modalStyles={styles.blockModal}
      >
        <ChangeUsersStatusModalContent
          isLoading={isLoading}
          selectedSections={selectedSections}
          onCancelPress={() => setIsStatusUsersModalOpen(false)}
          handleSubmitUnblock={handleUnblockUsers}
          handleToggleCheckbox={handleToggleSelectedSections}
          handleSubmit={handleBlockUsers}
        />
      </Modal>
    </Board>
  );
};
