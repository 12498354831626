import UserButton from '~/components/UserMenu/UserButton';
import styles from './userMenu.module.scss';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '~/ui';
import { useContext } from 'react';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';

export interface User {
  id: number;
  name: string;
  patronymic: string;
  full_name: string;
}

const UserMenu = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/user/${user?.data.id}`);
  };

  return (
    <IconButton onClick={goToProfile} className={styles.userMenu}>
      <UserButton user={user?.data} />
    </IconButton>
  );
};

export default UserMenu;
