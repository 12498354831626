import { useNavigate } from 'react-router-dom';
import { Button } from '~/ui';
import s from './NotFound.module.scss';

const Content = () => {
  const navigate = useNavigate();
  return (
    <div className={s.container}>
      <div className={s.code}>404</div>
      <div className={s.title}>Чего-то не хватает</div>
      <div className={s.description}>
        Страницы, которую вы ищите, похоже, не существует.
      </div>
      <Button
        onClick={() => navigate('/')}
        text="На главную"
        disabled={false}
      />
    </div>
  );
};

export default Content;
