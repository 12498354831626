import styles from './DashboardBlock.module.scss';

interface IDashboardBlockProps {
  title: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const DashboardBlock = ({ title, actions, children }: IDashboardBlockProps) => {
  return (
    <div className={styles.dashboardBlock}>
      <div className={styles.dashboardBlockHead}>
        <span className={styles.dashboardBlockTitle}>{title}</span>
        {actions}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default DashboardBlock;
