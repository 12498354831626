import { limitString } from '~/utils/limitString';
import { Popover } from 'antd';
import cn from 'classnames';
import styles from './FeedbacksBoard/FeedbacksTable/FeedbacksTable.module.scss';
import '~/assets/css/popoverFeedback.css';
import i18next from 'i18next';

function renderComment(description: string) {
  return (
    <Popover
      content={<div>{description}</div>}
      placement="top"
      trigger="hover"
      className="popover"
    >
      <div className={styles.description}>{limitString(description, 16)}</div>
    </Popover>
  );
}

function renderStatus(status: number) {
  const t = i18next.t;

  return (
    <div>
      {status === 1 ? (
        <span className={cn(styles.status, styles.statusPurple)}>
          {t('status_created')}
        </span>
      ) : null}
      {status === 2 ? (
        <span className={cn(styles.status, styles.statusOrange)}>
          {t('status_in_progress')}
        </span>
      ) : null}
      {status === 3 ? (
        <span className={cn(styles.status, styles.statusGreen)}>
          {t('status_done')}
        </span>
      ) : null}
      {status === 4 ? (
        <span className={cn(styles.status, styles.statusGray)}>
          {t('status_closed')}
        </span>
      ) : null}
    </div>
  );
}

export { renderComment, renderStatus };
