import ArrowIcon from '~/assets/svg/newSvg/arrow-select.svg?react';
import CloseIcon from '~/assets/svg/newSvg/close-purple.svg?react';
import { useState } from 'react';
import cn from 'classnames';
import Option from './Option/Option';
import { SearchInput } from './SearchInput/SearchInput';

import styles from './CustomQuerySelect.module.scss';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';

export interface IOption {
  id: number;
  name: string;
  full_name?: string;
}

interface IMultiSelectProps {
  placeholder: string;
  value: IOption[];
  disabled: boolean | undefined;
  onDeleteClick: (option: IOption) => void;
}

const MultiSelect = ({
  value,
  placeholder,
  disabled,
  onDeleteClick,
}: IMultiSelectProps) => {
  if (value?.length) {
    return (
      <ul className={styles.multiSelect}>
        {value.map(value => {
          return (
            <li key={value.id} className={styles.selectedOption}>
              {value.full_name || value.name}
              <button
                onClick={
                  disabled
                    ? undefined
                    : event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onDeleteClick(value);
                      }
                }
                type="button"
                className={styles.selectedOptionDelete}
              >
                <CloseIcon />
              </button>
            </li>
          );
        })}
      </ul>
    );
  }
  return <span className={styles.placeholder}>{placeholder}</span>;
};

interface ISelectProps {
  placeholder: string;
  value: IOption | IOption[] | undefined;
}

const SingleSelect = ({ placeholder, value }: ISelectProps) => {
  if (!Array.isArray(value) && value?.name) {
    return (
      <span className={styles.value}>{value?.full_name || value?.name}</span>
    );
  }
  return <span className={styles.placeholder}>{placeholder}</span>;
};

interface ICustomQuerySelectProps {
  label?: string;
  placeholder?: string;
  value?: IOption | IOption[] | undefined;
  disabled?: boolean;
  multiselect?: boolean;
  isSearchEnabled?: boolean;
  required?: boolean;
  error?: string;
  query: string;
  queryKey: [string, any];
  params: any;
  onChange: (option: IOption | IOption[]) => void;
  onTouch?: () => void;
}

const CustomQuerySelect = ({
  label,
  placeholder = 'Select option',
  value,
  disabled,
  multiselect,
  isSearchEnabled,
  required,
  error,
  params,
  query,
  queryKey,
  onTouch,
  onChange,
}: ICustomQuerySelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const { t } = useTranslation();

  const handleToggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const { data } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(query, {
        params: { ...params, query: debouncedSearchTerm },
      });
      return data.data || data;
    },
    queryKey: [...queryKey, debouncedSearchTerm],
  });

  const handleSelectOption = (option: IOption) => {
    if (multiselect && Array.isArray(value)) {
      const updatedValues = value.find((item: IOption) => item.id === option.id)
        ? value.filter((item: IOption) => {
            return item.id !== option.id;
          })
        : [...value, option];
      onChange(updatedValues);
    } else {
      onChange(option);
      setIsOpen(false);
    }
  };

  const handleCloseDropdown = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={cn(
        styles.customSelect,
        multiselect ? styles.customSelectMultiselect : undefined,
        isOpen ? styles.customSelectOpen : undefined,
        disabled ? styles.customSelectDisabled : undefined
      )}
      onBlur={event => {
        handleCloseDropdown(event);
        if (onTouch) {
          onTouch();
        }
      }}
    >
      {label ? (
        <span className={styles.label}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </span>
      ) : (
        ''
      )}
      <button
        type="button"
        onClick={disabled ? undefined : handleToggleSelect}
        className={cn(
          styles.customSelectLabel,
          isOpen ? styles.customSelectLabelOpen : undefined
        )}
      >
        {multiselect && Array.isArray(value) ? (
          <MultiSelect
            value={value}
            disabled={disabled}
            onDeleteClick={handleSelectOption}
            placeholder={placeholder}
          />
        ) : (
          <SingleSelect value={value} placeholder={placeholder} />
        )}
        <ArrowIcon
          className={cn(
            styles.arrowIcon,
            isOpen ? styles.arrowIconOpen : undefined
          )}
        />
      </button>
      {isOpen ? (
        <ul className={styles.options}>
          {isSearchEnabled && (
            <div className={styles.searchWrapper}>
              <SearchInput
                value={searchValue}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
                onClearPress={() => {
                  setSearchValue('');
                }}
              />
            </div>
          )}

          {data?.length === 0 ? (
            <span className={styles.noResultText}>{t('no_results')}</span>
          ) : (
            <>
              {data?.map(
                (option: { id: number; name: string; full_name?: string }) => (
                  <Option
                    key={option.id}
                    onClick={() => handleSelectOption(option)}
                    name={option.full_name || option.name}
                    isSelected={
                      Array.isArray(value)
                        ? !!value.find((item: IOption) => item.id === option.id)
                        : value?.id === option.id
                    }
                  />
                )
              )}
            </>
          )}
        </ul>
      ) : (
        ''
      )}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default CustomQuerySelect;
