import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, IconButtonLink } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './SocialTable.module.scss';

interface Props {
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
}

export const SocialTable = ({ sort, setSort }: Props): JSX.Element => {
  const approve = false;

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="name">Тип опроса</TableCellHead>
        <TableCellHead field="start_date">Название опроса</TableCellHead>
        <TableCellHead sortable={false} field="actions">
          Действие
        </TableCellHead>
      </TableHead>
      <TableBody>
        {approve && (
          <TableRow>
            <TableCell>{''}</TableCell>
            <TableCell>{''}</TableCell>
            <TableCell>
              <div className={styles.announcementsTableActions}>
                <IconButton onClick={() => {}}>
                  <Basket />
                </IconButton>
                <IconButtonLink
                  to={'/social/create/question'}
                  className={styles.announcementsTableBtnLink}
                >
                  <Edit />
                </IconButtonLink>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
