import cn from 'classnames';
import styles from './InputBarColor.module.scss';

interface InputBarProps {
  label: string;
  star?: boolean;
  classNameInputWrapper?: string;
  errors?: string;
  error?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

export const InputBarColor = (props: InputBarProps) => {
  const { errors, error, label, star, classNameInputWrapper, children } = props;

  return (
    <div className={cn(styles.input__wrapper, classNameInputWrapper)}>
      <div className={styles.input__label}>
        <span className={styles.input__text}>
          {label}
          {star ? <span className={styles.input__star}>*</span> : null}
        </span>
        {children}
        {error ? <div className={styles.errors__text}>{errors}</div> : null}
      </div>
    </div>
  );
};
