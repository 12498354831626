import { createContext, useLayoutEffect, useRef } from 'react';
import styles from './Table.module.scss';

export interface IsSort {
  field: string;
  direction: string;
}

interface TableHeadProps {
  children: React.ReactNode;
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
}

export const TableContext = createContext<{
  sort: {
    field: string;
    direction: string;
  };
  onSortChange: (field: string, direction: string) => void;
}>({
  sort: { field: '', direction: '' },
  onSortChange: () => {},
});

export const TableHead = ({
  children,
  sort,
  setSort,
}: TableHeadProps): JSX.Element => {
  const onSortChange = (field: string, direction: string) => {
    setSort({ field, direction });
  };
  const ref = useRef<HTMLTableSectionElement>(null!);

  useLayoutEffect(() => {
    const cells = ref.current.querySelectorAll('th');
    const rootWidth = ref.current.offsetWidth;
    for (let cell of cells) {
      cell.style.width = `${(cell.offsetWidth / rootWidth) * 100}%`;
    }
  }, []);

  return (
    <TableContext.Provider value={{ sort, onSortChange }}>
      <thead className={styles.thead} ref={ref}>
        <tr>{children}</tr>
      </thead>
    </TableContext.Provider>
  );
};
