import { headerBarList } from '~/constants/headerBarList';
import { Link } from 'react-router-dom';
import styles from './headerBar.module.scss';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useContext } from 'react';
import { checkIfFoodAdmin } from '~/utils/getUserRole';

const HeaderBar = (): JSX.Element => {
  const links = headerBarList();
  const { user } = useContext(AuthenticationContext);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);

  const filteredLinks = links.filter(item => {
    if (isFoodAdminAccess) {
      return item.path === '/transactions';
    }
    return isFoodAdminAccess || item.path !== '/transactions';
  });

  return (
    <nav className={styles.navHeader}>
      <ul className={styles.navHeader__list}>
        {filteredLinks?.map(({ id, path, text, Icon }) => {
          return (
            <li key={id} className={styles.navHeader__item}>
              <Link to={path} className={styles.navHeader__link}>
                <Icon />
                <span className={styles.navHeader__itemText}>{text}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default HeaderBar;
